import { Component, OnInit, Input } from '@angular/core';
import { Area } from 'src/app/shared/area.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AreaService } from 'src/app/services/area/area.service';
import { StateEnume } from 'src/app/shared/enum/state-enum';
import { NgForm } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { map } from 'rxjs/operators';
import { Podmiot } from 'src/app/shared/podmiot.model';
import { SelectService } from 'src/app/services/select.service';
import { Connection } from 'src/app/shared/connection.model';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from 'src/app/services/settings.service';
import { AlertService } from 'src/app/shared/_alert';

@Component({
  selector: 'app-area',
  templateUrl: './area.component.html',
  styles: []
})
export class AreaComponent implements OnInit {
  @Input()
  set item(val: Area) {
    this._item = val;

    this.http.get<Connection[]>(`rms/Connections/${this.item.Id}`).subscribe(p => {
      this._item.Connections = p.sort((a, b) => (a.Order < b.Order ? -1 : 1));
      //this.genreDetails = p;

      for (let index = this._item.Connections.length; index < 2; index++) {
        let con1: Connection = new Connection();

        con1.SsId = "";
        con1.Code = "";
        con1.IdArea = val.Id;
        if (this._item.Connections.length === 1 && this._item.Connections[0].Order === 2) {
          con1.Order = 1;
          this._item.Connections.unshift(con1);
        } else {
          con1.Order = index + 1;
          this._item.Connections.push(con1);
        }

      }
    });
  }
  get item() {
    return this._item;
  }
  _item: Area;

  // @Input() item: Area;
 // @Input() state: StateEnume;
  //@Input() clients: Podmiot[];
  // areas:Area[];
  public isTester: BehaviorSubject<boolean | null> = new BehaviorSubject(null);
  public clients: Podmiot[];
  // public Connections: Connection[];

  constructor(private http: HttpClient, protected dd: SelectService, private service: AreaService, private route: ActivatedRoute,
    private router: Router,
    auth: AuthorizeService,  private alert: AlertService, public settings: SettingsService) {
    auth.getUser().pipe(map(u => u.role)).subscribe(roles => {
      if (roles) {
        // this.isAdminRot.next(roles.includes("AdminRot"));
        // this.isZmp.next(roles.includes("ZMP"));
        this.isTester.next(roles.includes("Tester"));
      } else {
        // this.isAdminRot.next(false);
        // this.isZmp.next(false);
        this.isTester.next(false);
      }
    });

  }

  ngOnInit() {
    this.settings.selectedArea.subscribe(area => {
      if (area)
        this.item = area;
    });

    this.dd.active<Podmiot>('client', true)
      .subscribe(
        result => {
          this.clients = result;
          console.log(result);
        });
    // if (!this.state) {
    //   this.state = StateEnume.Details;
    //   let id: number = +this.route.snapshot.paramMap.get('id');
    //   if (id && id != 0) {
    //     this.service.get<Area>(id)
    //       .subscribe(
    //         result => {
    //           this.item = result;
    //           this.state = StateEnume.Edit;
    //         });
    //   }
    // }
  }

  get isDetails() {
    return this.settings.state == StateEnume.Details;
  }

  submit(f: NgForm) {
    if (this.settings.state === StateEnume.New) {
      this.service.add<Area>(this.item)
        .subscribe(
          result => {
            console.log(result);
            //this.config = result.json();
            this.settings.state = StateEnume.Details;
            this.item = result;
            this.settings.dataChange.next(true);
            this.alert.success("New record saved.");
          });
    }
    if (this.settings.state === StateEnume.Edit || this.settings.state === StateEnume.Details) {
      this.service.update<Area>(this.item, this.item.Id)
        .subscribe(
          result => {
            console.log(result);
            //this.config = result.json();
            this.settings.state = StateEnume.Details;
            this.item = result;
            this.settings.dataChange.next(true);
            this.alert.success("Edited record saved.");
          });
    }
  }

}
