import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { Area } from "../shared/area.model";
import { Podmiot } from "../shared/podmiot.model";
import { PeriodEnum } from "../shared/enum/period-enume";
import { Device } from "../shared/device.model";
import { StateEnume } from "../shared/enum/state-enum";
import { KeyValue } from "@angular/common";
import { MeasuringPoint } from "../shared/measuring-point.model";
import { AxisEnume } from "../shared/enum/axis-enum";
import { NewsEnum } from "../shared/enum/news-enum";
import { PagesPaths } from "../app.constants";
import { HttpClient } from '@angular/common/http';
import { RmsData } from "../shared/rms-data.model";
import { formatDate } from '@angular/common';
import { GongService } from "./gong.service";
import { ControlState } from "../shared/interface/control-state";

@Injectable({
  providedIn: "root",
})
export class AppService {

  private idArea: number;
  private idPodmiot: number;

  constructor(private http: HttpClient, private gong: GongService) {
    this.selectedAreaId.subscribe(idArea => {
      if (idArea && this.selectedPodmiotId.value)
        this.http.get<Area>(`rms/Areas/${idArea}/${this.selectedPodmiotId.value}`).subscribe(area => {
          this.selectedArea.next(area);
        });

    });
    this.selectedArea.subscribe(area => {
      if (area) {
        this.http.get<Device[]>("rms/Device/" + area.Id).subscribe(devices => this.devices.next(devices));
      }
    });
    this.ActivePage.subscribe(page => {
      switch (page) {
        case PagesPaths.Alert:
          if (this.state.value === StateEnume.Warning) {
            this.saveActivity(PagesPaths.Warning);
          }
          this.state.next(StateEnume.Alert);
          this.title.next("<i class='far fa-bell'></i>  Alerts");
          if (this.dangerDt.value) {
            this.titleSubDt.next(` (${formatDate(this.dangerDt.value, "yyyy-MM-dd HH:mm", "en-US")})`);
            this.danger.next(null);
            this.dangerDt.next(null);
          }
          break;
        case PagesPaths.Warning:
          if (this.state.value === StateEnume.Alert) {
            this.saveActivity(PagesPaths.Alert);
          }
          this.state.next(StateEnume.Warning);
          this.title.next("<i class='far fa-bell'></i>  Warnings");
          if (this.warningDt.value) {
            this.titleSubDt.next(` (${formatDate(this.warningDt.value, "yyyy-MM-dd HH:mm", "en-US")})`);
            this.warning.next(null);
            this.warningDt.next(null);
          }
          break;
        case PagesPaths.Supervision:
          if (this.state.value === StateEnume.Alert) {
            this.saveActivity(PagesPaths.Alert);
          }
          if (this.state.value === StateEnume.Warning) {
            this.saveActivity(PagesPaths.Warning);
          }
          this.state.next(StateEnume.Test);
          this.title.next("Supervision mode");
          this.titleSubDt.next(null);
          break;
        default:
          if (this.state.value === StateEnume.Alert) {
            this.saveActivity(PagesPaths.Alert);
          }
          if (this.state.value === StateEnume.Warning) {
            this.saveActivity(PagesPaths.Warning);
          }
          this.state.next(StateEnume.Details);
          this.title.next("");
          this.titleSubDt.next(null);
          break;
      }
    });

    this.period.subscribe(period => {
      switch (period) {
        case PeriodEnum.Last:
          this.titleSub.next("Since last visit");
          break;
        case PeriodEnum.Day:
          this.titleSub.next("Daily statistics");
          break;
        case PeriodEnum.Month:
          this.titleSub.next("Monthly statistics");
          break;
        case PeriodEnum.Year:
          this.titleSub.next("Annual statistics");
          break;
        default:
          this.titleSub.next("");
          break;
      }
    });
    // połączyć z "ActivePage" i usnąć "state" 
    //this.state.subscribe(st => {
    //  switch (st) {
    //    case StateEnume.Details:
    //      break;
    //    case StateEnume.Test:
    //      break;
    //    case StateEnume.Warning:
    //      break;
    //    case StateEnume.Alert:
    //      break;
    //    default:
    //  }
    //});
  }

  saveActivity(pre) {
    let url = `api/gong/${this.selectedAreaId.value}/${pre}`;
    if (this.saveActiveLastUrl !== url) {
      this.saveActiveLastUrl = url;
      this.http.get<Date>(url).subscribe(msg => {
        if (pre === PagesPaths.Warning) {
          this.warningDt.next(msg);
          this.warning.next(0);
        }
        if (pre === PagesPaths.Alert) {
          this.dangerDt.next(msg);
          this.danger.next(0);
        }
      });
    }
  }

  //I poziom
  selectedAreaId: BehaviorSubject<number | null> = new BehaviorSubject(null);
  selectedPodmiotId: BehaviorSubject<number | null> = new BehaviorSubject(null);
  //II poziom
  state = new BehaviorSubject(StateEnume.Details); //do usunięcia po połączeniu z ActivePage
  ActivePage: BehaviorSubject<string> = new BehaviorSubject(PagesPaths.Client);
  saveActiveLastUrl: string;
  periodT; deviceT; stateT; unitT;
  //III poziom
  period = new BehaviorSubject(PeriodEnum.Last);
  selectedYear: BehaviorSubject<number | null> = new BehaviorSubject(null);
  selectedMonth: BehaviorSubject<number | null> = new BehaviorSubject(null);
  selectedDay: BehaviorSubject<number | null> = new BehaviorSubject(null);




  isAuthenticated: Observable<boolean>;
  isAdminRot: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isZmp: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isTester: BehaviorSubject<boolean> = new BehaviorSubject(false);

  userName: Observable<string>;
  userRoles: Observable<string[]>;

  phone: Observable<string>;
  email: Observable<string>;



  podmiots: BehaviorSubject<Podmiot[] | null> = new BehaviorSubject(null);
  selectedArea: BehaviorSubject<Area> = new BehaviorSubject(null);








  /*
   * value:
   * -area - obszary
   * -preferences/area - preferencje
   */
  currentPage: BehaviorSubject<string> = new BehaviorSubject("area");

  devices: BehaviorSubject<Device[] | null> = new BehaviorSubject(null);
  points: BehaviorSubject<KeyValue<Device, MeasuringPoint[]>[]> = new BehaviorSubject<KeyValue<Device, MeasuringPoint[]>[]>(new Array());

  title: BehaviorSubject<string | null> = new BehaviorSubject(null);
  titleSub: BehaviorSubject<string | null> = new BehaviorSubject(null);
  titleSubDt: BehaviorSubject<string | null> = new BehaviorSubject(null);


  danger: BehaviorSubject<number | null> = new BehaviorSubject(null);
  warning: BehaviorSubject<number | null> = new BehaviorSubject(null);
  msg: BehaviorSubject<number | null> = new BehaviorSubject(null);
  dangerDt: BehaviorSubject<Date | null> = new BehaviorSubject(null);
  warningDt: BehaviorSubject<Date | null> = new BehaviorSubject(null);
  msgDt: BehaviorSubject<number | null> = new BehaviorSubject(null);

  //chart control only!!!
  selectedTab: BehaviorSubject<string> = new BehaviorSubject("86");
  selectedChartData: RmsData;

  lastDate: BehaviorSubject<Date | null > = new BehaviorSubject(null);



  get isHighRiskState() {
    //return this.state.pipe(map((u) => u === StateEnume.Alert || u === StateEnume.Warning));
    return this.state &&
      (this.state.value === StateEnume.Alert ||
        this.state.value === StateEnume.Warning);
  }
  get isNormalState() {
    //return this.state.pipe(map((u) => u !== StateEnume.Alert && u !== StateEnume.Warning));
    return this.state && this.state.value < 5;
  }


  isAlert(point: MeasuringPoint, axis: AxisEnume) {
    let current = new Date(Date.now());
    return point.UserNotifies && point.UserNotifies.find(p => p.Axis === axis && p.Genre === NewsEnum.Alert && current > new Date(p.Start) && new Date(p.End) > current);
  }
  isWarning(point: MeasuringPoint, axis: AxisEnume) {
    let current = new Date(Date.now());
    return point.UserNotifies && point.UserNotifies.find(p => p.Axis === axis && p.Genre === NewsEnum.Warning && current > new Date(p.Start) && new Date(p.End) > current);
  }
  isNotifies(point: MeasuringPoint, axis: AxisEnume) {
    let current = new Date(Date.now());
    return point.UserNotifies && point.UserNotifies.find(p => p.Axis === axis && current > new Date(p.Start) && new Date(p.End) > current);
  }
  getNotifies(point: MeasuringPoint, axis: AxisEnume, genre: NewsEnum) {
    let current = new Date(Date.now());
    if (point.UserNotifies)
      return point.UserNotifies.find(p => p.Axis === axis && p.Genre === NewsEnum.Warning && current > new Date(p.Start) && new Date(p.End) > current);
    return null;
  }



  get isActiveClient() {
    return (
      this.ActivePage.value === PagesPaths.Client ||
      this.ActivePage.value === PagesPaths.Supervision
    );
  }
  get isActiveSupervision() {
    return this.ActivePage.value === PagesPaths.Supervision;
  }
  get isActiveWarning() {
    return this.ActivePage.value === PagesPaths.Warning;
  }
  get isActiveAlert() {
    return this.ActivePage.value === PagesPaths.Alert;
  }


}
