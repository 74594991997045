import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { GongService } from '../services/gong.service';
import { ChartNews } from '../shared/chart-news.model';
import { NewsEnum } from '../shared/enum/news-enum';
import { PeriodEnum } from '../shared/enum/period-enume';
import { AppService } from "../services/app.service";

@Component({
  selector: '[app-nav-right]',
  templateUrl: './nav-right.component.html',
  styles: [
  ]
})
export class NavRightComponent implements OnInit {
  public userRoles: Observable<string[]>;
  public isAdminRot: BehaviorSubject<boolean | null> = new BehaviorSubject(null);
  public isZmp: BehaviorSubject<boolean | null> = new BehaviorSubject(null);

  id: Observable<number>;
  url: Observable<string>;

  display: boolean;

  danger: BehaviorSubject<number | null> = new BehaviorSubject(null);
  warning: BehaviorSubject<number | null> = new BehaviorSubject(null);
  msg: BehaviorSubject<number | null> = new BehaviorSubject(null);

  constructor(private http: HttpClient,
    authorizeService: AuthorizeService,
    private route: ActivatedRoute, private gong: GongService, public  app: AppService) {
    this.userRoles = authorizeService.getUser().pipe(filter(u => u !== null), map(u => u.role));
    this.userRoles.subscribe(roles => {
      if (roles) {
        this.isAdminRot.next(roles.includes("AdminRot"));
        this.isZmp.next(roles.includes("ZMP"));
      }
    });

    this.id = this.route.params.pipe(map(p => +p.id));
    this.url = this.route.url.pipe(map(segments => segments.join('')));
    this.url.subscribe(url => {
      this.display = url.indexOf("client") > 0;
    });

    // this.gong.get( this.id., PeriodEnum.Last).subscribe(news => {
    //   this.danger.next(news.filter(p => p.Genre === NewsEnum.Alert).length);
    //   this.warning.next(news.filter(p => p.Genre === NewsEnum.Warning).length);
    //   this.msg.next(news.filter(p => p.Genre === NewsEnum.Msg).length);
    // });

  }

  ngOnInit(): void {
    //
  }

}
