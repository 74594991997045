import { Component, OnInit } from "@angular/core";
import { BehaviorSubject} from "rxjs";
import { AuthorizeService } from "../api-authorization/authorize.service";
import { filter, map } from "rxjs/operators";
import { AppService } from "./services/app.service";
import { Podmiot } from "./shared/podmiot.model";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { SignalRService } from "./services/signal-r.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  title = "app";

  year: BehaviorSubject<number | null> = new BehaviorSubject(null);

  constructor(private http: HttpClient, private authorizeService: AuthorizeService, public app: AppService, private router: Router, public signalRService: SignalRService) {
    app.selectedArea.next(null);
    // view.getAll();
    this.app.currentPage.next("area");

    this.http.get<Podmiot[]>('rms/podmiots').subscribe(elements => {
      app.podmiots.next(elements);
    }, errorPodmiot => console.error(errorPodmiot));

    app.userRoles = authorizeService.getUser().pipe(
      filter((u) => u !== null),
      map((u) => u.role)
    );
    app.userRoles.subscribe((roles) => {
          if (roles) {
            app.isAdminRot.next(roles.includes("AdminRot"));
            app.isZmp.next(roles.includes("ZMP"));
            app.isTester.next(roles.includes("Tester"));
          } else {
            app.isAdminRot.next(false);
            app.isZmp.next(false);
            app.isTester.next(false);
          }
    });
    var today =  Date.now();
    this.year.next(today);
  }
  navClick(id, wId){
    this.router.navigate([this.app.currentPage.value, id, wId]);
  }

  ngOnInit() {
    this.signalRService.startConnection();
    this.signalRService.addRefreshListener();
    this.startHttpRequest();

    this.app.isAuthenticated = this.authorizeService.isAuthenticated();

    this.app.userName = this.authorizeService
      .getUser()
      .pipe(map((u) => u && u.name));
  }

  private startHttpRequest = ()=>{
    this.http.get('rms/rms').subscribe(res=>{
      console.log(res);

    });
  }
}
