import { Component, Input, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Area } from 'src/app/shared/area.model';
import { AxisEnumeDisplay } from 'src/app/shared/enum/axis-enum';
import { NewsEnum } from 'src/app/shared/enum/news-enum';
import { AlertService } from 'src/app/shared/_alert';
import { DeviceViewService } from '../device/device-view.service';
import { Notify } from '../shared/notify.model';

@Component({
  selector: 'app-report-emission',
  templateUrl: './report-emission.component.html',
  styles: [
  ]
})
export class ReportEmissionComponent implements OnInit {


  $area: Area;
  @Input()
  set area(val: Area) {
    if (val && this.$area !== val) {
      this.$area = val;
      this.view.get(this.area.Id).subscribe(n => {
        if (n) {
          this.$alertChecked = true;
        } else {
          this.$alertChecked = false;
        }
      });
    }
  }
  get area(): Area {
    return this.$area;
  }


  $alertChecked: boolean;
  //alertChecked: boolean;
  set alertChecked(val: boolean) {
    if (this.$alertChecked !== val) {
      this.$alertChecked = val;
      this.save();
    }
  }
  get alertChecked(): boolean {
    return this.$alertChecked;
  }

  color: ThemePalette = "primary";

  constructor(private alert: AlertService,
    public view: DeviceViewService) { }

  ngOnInit(): void {
  }

  save() {
    let notify = new Notify();
    notify.Genre = NewsEnum.Report;
    let model = { Key: this.area.Id, Value: notify };
    this.view.add(model).subscribe(points => {


      if (model.Value.Genre === NewsEnum.Report)
        this.alert.info(`Reports enabled for ${this.area.Name} `);

    });
  }

}
