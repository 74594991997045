import { Component, OnInit, Input } from '@angular/core';
import { TypeDevice } from "../../shared/type-device.model";
import { Device } from "../../../shared/device.model";
import { MeasuringPoint } from "../../../shared/measuring-point.model";
import { Row } from "../row/row.model";
import { AxisEnume } from "../../../shared/enum/axis-enum";
import { RmsData } from "../../../shared/rms-data.model";
import { NewsEnum } from "../../../shared/enum/news-enum";
import { AppService } from "../../../services/app.service";
import { GongService } from "../../../services/gong.service";
import { BehaviorSubject } from "rxjs";
import { ChartNews } from "../../../shared/chart-news.model";

@Component({
  selector: 'app-cell-alert',
  templateUrl: './cell-alert.component.html',
  styles: [
  ]
})
export class CellAlertComponent implements OnInit {
  @Input()
  type: TypeDevice;

  @Input()
  device: Device;

  @Input()
  point: MeasuringPoint | Row;

  @Input()
  unit: string;

  @Input()
  axis: AxisEnume;


  private current: RmsData;

  public currentValue: BehaviorSubject<string> = new BehaviorSubject("NULL");

  public cssClass: BehaviorSubject<string> = new BehaviorSubject(null);
  news: ChartNews[];

  constructor(public app: AppService, private gong: GongService) {
    //this.cssClass.next(this.getCssClass());
  }

  ngOnInit(): void {
    if (this.type === TypeDevice.AlertView) {
      if (this.unit === "86")
        this.getNews(NewsEnum.Alert);
    } else if (this.type === TypeDevice.WarningView) {
      if (this.unit === "86")
        this.getNews(NewsEnum.Warning);
    }
    this.cssClass.next(this.getCssClass());
  }

  getNews(news: NewsEnum) {
    if (this.app.isHighRiskState) {
      let temp = new Date(this.app.selectedYear.value, this.app.selectedMonth.value, this.app.selectedDay.value);
      this.gong.getDetails(this.app.selectedAreaId.value, this.app.period.value, this.point.Id, news, this.unit, this.axis, temp).subscribe(result => {
        if (result) {
          this.currentValue.next(result.length.toFixed(0));
         // this.news.next(result);
          //this.saveActivity();
         // console.log(result);

        }
        this.news = result;
        this.cssClass.next(this.getCssClass());
      });
    }
  }

  getCssClass() {

    const classes = ["btn", "btn-block"];


    const alertTypeClassOutline = {
      [TypeDevice.WarningView]: "btn-outline-warning",
      [TypeDevice.AlertView]: "btn-outline-danger",
    };
    const alertTypeClass = {
      [TypeDevice.WarningView]: "btn-warning",
      [TypeDevice.AlertView]: "btn-danger",
    };
    if (this.news && this.news.length > 0) {
      classes.push(alertTypeClass[this.type]);
    } else {
      classes.push(alertTypeClassOutline[this.type]);
    }

    return classes.join(" ");
  }
}
