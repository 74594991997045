<form #f="ngForm" (ngSubmit)="submit(f)">
  <h1>Template-driven forms</h1>
  <div ngModelGroup="contact" #contact="ngModelGroup">
    <div class="form-group">
      <label for="firstName">
        First Name
      </label>
      <input required
             minlength="3"
             maxlength="10"
             pattern="banana"
             ngModel
             name="firstName"
             #firstName="ngModel"
             (change)="log(firstName)"
             id="firstName"
             type="text"
             class="form-control" />
      <div class="alert alert-danger"
           *ngIf="firstName.touched && !firstName.valid">
        <div *ngIf="firstName.errors.required">
          First Name is required.
        </div>
        <div *ngIf="firstName.errors.minlength">
          First Name should be minimum {{ firstName.errors.minlength.requiredLength }} characters.
        </div>
        <div *ngIf="firstName.errors.pattern">
          First Name doesn't match the pattern.
        </div>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label for="comment">
      Comment
    </label>
    <textarea ngModel
              name="comment"
              id="comment"
              class="form-control"
              cols="30"
              rows="10">

    </textarea>
  </div>
  <div class="checkbox">
    <label>
      <input type="checkbox" ngModel name="isSubscribed" /> Subscribe to mailing list
    </label>
  </div>
  <div class="form-group">
    <label for="contactMethod">Contact Method</label>
    <select ngModel name="contactMethod" id="contactMethod" class="form-control">
      <option *ngFor="let method of contactMethods" [ngValue]="method">{{ method.name }}</option>
    </select>
  </div>
  <div *ngFor="let method of contactMethods" class="radio">
    <label>
      <input ngModel type="radio" name="contactMethod" [value]="method.id" /> {{ method.name }}
    </label>
  </div>
  <p>
    {{
 f.value | json
    }}


  </p>
  <button class="btn btn-primary" [disabled]="!f.valid">Submit</button>
</form>

