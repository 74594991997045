import { Component } from '@angular/core';
import { ConfigService } from '../../services/config/config.service';
import { Config } from '../../shared/config.model';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseItemsComponent } from '../../shared/base-items/base-items.component';
import { AuthorizeService } from 'src/api-authorization/authorize.service';

@Component({
  selector: 'app-configs',
  templateUrl: './configs.component.html',
  styles: []
})
export class ConfigsComponent extends BaseItemsComponent<Config> {

  constructor(service: ConfigService, route: ActivatedRoute, router: Router,
    auth: AuthorizeService) {
    super(Config, service, route, router,auth);
    this.property = [
      { PropertyName: "Name", Display: "Name" },
      { PropertyName: "From", Display: "From" },
      { PropertyName: "To", Display: "To" }
    ];
  }
}
