<div class="card">
  <div class="card-header">
    Sensor
  </div>
  <div class="card-body">
    <form #f="ngForm"
          *ngIf="item"
          (ngSubmit)="submit(f)">
      <input type="hidden"
             [(ngModel)]="item.Id"
             #Id="ngModel"
             name="Id"
             id="Id" />
      <div class="row">
        <div class="col-md-12 col-lg-6 col-xl-3">
          <div class="form-group">
            <label for="IdPodmiot">Podmiot</label>
            <input class="form-control"
                   [(ngModel)]="item.IdPodmiot"
                   #IdPodmiot="ngModel"
                   name="IdPodmiot"
                   id="IdPodmiot"
                   type="number"
                   aria-describedby="IdPodmiotHelp">
            <!-- <small id="IdPodmiotHelp" class="form-text text-muted">Numer konfiguracji - przekazywany
              do
              czujnika</small> -->

          </div>
        </div>
        <div class="col-md-12 col-lg-6 col-xl-3">
          <div class="form-group">
            <label for="Tag">Tag</label>
            <input class="form-control"
                   [(ngModel)]="item.Tag"
                   #Tag="ngModel"
                   name="Tag"
                   id="Tag"
                   aria-describedby="TagHelp">
            <!-- <small id="SensorNumerHelp" class="form-text text-muted">Numer konfiguracji - przekazywany
              do
              czujnika</small> -->

          </div>
        </div>
        <div class="col-md-12 col-lg-6 col-xl-4">
          <div class="form-group">
            <label for="Number">Sensor No.</label>

            <div class="input-group">
              <input class="form-control"
                     [(ngModel)]="item.Number"
                     #Number="ngModel"
                     name="Number"
                     id="Number"
                     placeholder="No.">
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-6 col-xl-4">
          <div class="form-group">
            <mat-slide-toggle [color]="color"
                              [(ngModel)]="item.Archival"
                              #Archival="ngModel"
                              name="Archival"
                              id="Archival">Archival</mat-slide-toggle>
          </div>
        </div>
      </div>
      <div *ngIf="this.settings.points"
           class="form-group">
        <label *ngFor="let point of getReleted()"> #{{point.Id}} - {{ point.Name }} -> #dev {{point.IdDevice}}</label>
      </div>
      <div *ngIf="isTester | async"
           class="form-group">
        <label for="detail">Json item (preview)</label>
        <textarea class="form-control"
                  disabled
                  rows="10">
                          {{ f.value | json }}
                      </textarea>
      </div>
      <button type="submit"
              class="btn btn-success float-right"
              [disabled]="!f.valid"
              *ngIf="f.touched">
        <i class="far fa-save"></i> Zapisz
      </button>
    </form>
  </div>
</div>
