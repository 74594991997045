<div class="card">
    <div class="card-header">
        Device criticality
    </div>
    <div class="card-body">
        <form #f="ngForm"
              (ngSubmit)="submit(f)">
            <input type="hidden"
                   [ngModel]="item.Id"
                   #Id="ngModel"
                   name="Id"
                   id="Id"
                   name="Id" />
            <input type="hidden"
                   [ngModel]="item.IdGroup"
                   #IdGroup="ngModel"
                   name="IdGroup"
                   id="IdGroup"
                   name="IdGroup" />

            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label for="Description">Name</label>
                        <input type="text"
                               class="form-control"
                               [(ngModel)]="item.Description"
                               #Description="ngModel"
                               name="Description"
                               id="Description">

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label for="From">From:</label>
                        <input type="datetime"
                               class="form-control"
                               [ngModel]="item.From | date:'yyyy-MM-dd / HH:mm'"
                               #From="ngModel"
                               name="From"
                               id="From"
                               disabled
                               aria-describedby="FromHelp">
                        <!-- <small id="FromHelp" class="form-text text-muted">....</small> -->
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="To">To:</label>
                        <input type="datetime"
                               class="form-control"
                               [ngModel]="item.To | date:'yyyy-MM-dd / HH:mm'"
                               #To="ngModel"
                               name="To"
                               id="To"
                               disabled
                               aria-describedby="ToHelp">
                        <!-- <small id="ToHelp" class="form-text text-muted">....</small> -->
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <div class="form-group">
                        <label for="Criticality">Criticality</label>
                        <input class="form-control"
                               [(ngModel)]="item.Criticality"
                               #Criticality="ngModel"
                               name="Criticality"
                               id="Criticality"
                               placeholder=""
                               aria-describedby="CriticalityHelp">
                        <!-- <small id="CriticalityHelp" class="form-text text-muted"> [ms]?</small> -->
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="Interval">Interval</label>
                        <input type="number"
                               class="form-control"
                               [(ngModel)]="item.Interval"
                               #Interval="ngModel"
                               name="Interval"
                               id="Interval"
                               placeholder=""
                               aria-describedby="IntervalHelp">
                        <!-- <small id="IntervalHelp" class="form-text text-muted">In [ms]?</small> -->
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="Number">Mes Amount</label>
                        <input type="number"
                               class="form-control"
                               [(ngModel)]="item.Number"
                               #Number="ngModel"
                               name="Number"
                               id="Number"
                               placeholder=""
                               aria-describedby="NumberHelp">
                        <!-- <small id="NumberHelp" class="form-text text-muted">In [ms]?</small> -->
                    </div>
                </div>
            </div>

            <div *ngIf="isTester | async"
                 class="form-group">
                <label for="detail">Json item (preview)</label>
                <textarea class="form-control"
                          disabled
                          rows="10">
                        {{ f.value | json }}
                    </textarea>
            </div>
            <!-- <button type="submit"
                    class="btn btn-success float-right"
                    [disabled]="!f.valid"
                    *ngIf="!isDetails"><i class="far fa-save"></i> Zapisz</button> -->

            <button type="submit"
                    class="btn btn-success float-right"
                    [disabled]="!f.valid"
                    *ngIf="f.touched">
                <i class="far fa-save"></i> Zapisz
            </button>
        </form>
    </div>
</div>