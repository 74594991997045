import { Component, OnInit } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Device } from "../shared/device.model";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { PeriodEnum } from "../shared/enum/period-enume";
import { AppService } from "../services/app.service";

@Component({
  selector: "app-client",
  templateUrl: "./client.component.html",
  styleUrls: ["./client.component.css"],
})
export class ClientComponent implements OnInit {
  userRoles: Observable<string[]>;
  isAdminRot: BehaviorSubject<boolean | null> = new BehaviorSubject(null);
  isZmp: BehaviorSubject<boolean | null> = new BehaviorSubject(null);

  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true,
  };

  //podmiots: Observable<Podmiot[]>;
  //selectedPodmiotId: number;
  //selectedAreaId: number;
  //podmiot: Observable<Podmiot>;
  //area: Observable<Area>;
  //devices: Observable<Device[]>;

  chartShow = false;
  pill = true;

  //success = 0;
  //warning = 0;
  //danger = 0;

  showStatus = false;

  dangerLength: BehaviorSubject<number | null> = new BehaviorSubject(null);
  warningLength: BehaviorSubject<number | null> = new BehaviorSubject(null);
  msgLength: BehaviorSubject<number | null> = new BehaviorSubject(null);

  constructor(public app: AppService, private http: HttpClient, private route: ActivatedRoute) {

  }

  onChangeDevice(controlState) {
    //this.danger += controlState.danger;
    //this.warning += controlState.warning;
    //this.success += controlState.success;

    this.showStatus = true;
  }

  ngOnInit() {}
}
