import { Component, OnInit, Input } from '@angular/core';
import { Connection } from 'src/app/shared/connection.model';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ConnectionService } from 'src/app/services/connection/connection.service';
import { SelectService } from 'src/app/services/select.service';
import { BehaviorSubject } from 'rxjs';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-connection',
  templateUrl: './connection.component.html',
  styles: []
})
export class ConnectionComponent implements OnInit {
  @Input() item: Connection;

  public isTester: BehaviorSubject<boolean | null> = new BehaviorSubject(null);

  constructor(protected dd: SelectService, private service: ConnectionService, private route: ActivatedRoute,
    private router: Router,
    auth: AuthorizeService) {
    auth.getUser().pipe(map(u => u.role)).subscribe(roles => {
      if (roles) {
        // this.isAdminRot.next(roles.includes("AdminRot"));
        // this.isZmp.next(roles.includes("ZMP"));
        this.isTester.next(roles.includes("Tester"));
      } else {
        // this.isAdminRot.next(false);
        // this.isZmp.next(false);
        this.isTester.next(false);
      }
    });
  }

  ngOnInit() {

    // if (!this.state) {
    //   this.state = StateEnume.Details;
    //   let id: number = +this.route.snapshot.paramMap.get('id');
    //   if (id && id != 0) {
    //     this.service.get<Connection>(id)
    //       .subscribe(
    //         result => {
    //           this.item = result;
    //           this.state = StateEnume.Edit;
    //         });
    //   }
    // }
  }

  // get isDetails() {
  //   return this.state == StateEnume.Details;
  // }

  submit(f: NgForm) {
    if (!this.item.Id) {

      this.service.add<Connection>(this.item)
        .subscribe(
          result => {
            // console.log(result);
            //this.config = result.json();
            // this.state = StateEnume.Details;
            this.item = result;
          });
    } else {
      this.service.delete<Connection>(this.item.Id).subscribe(result => {

      });
    }
  }
}
