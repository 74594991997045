import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { TypeDevice } from '../shared/type-device.model';

@Component({
  selector: 'app-custom-alert',
  templateUrl: './custom-alert.component.html',
  styles: [
  ]
})
export class CustomAlertComponent implements OnInit {

  type: TypeDevice = TypeDevice.CustomAlert;
  constructor(private http: HttpClient,
    public app: AppService) { }

  ngOnInit(): void {
  }

}
