<div class="row  mb-3 mt-2">
  <!-- <div class="col-12">
    <small *ngIf="isTempVisible()" class="small float-right">last: <strong>{{ (app.lastDate | async) | date: 'yyyy-MM-dd / HH:mm' }}</strong></small>
  </div> -->
</div>
<!--<div class="row mb-2">
  <div class="col-2 col-md-1">
  </div>
  <div class="col-10 col-md-11">
    <div class="row">
      <div class="col-3 pl-1  pr-0 pr-sm-2">
        <div class="btn btn-dark btn-block"
             data-toggle="tooltip"
             data-placement="top"
             title="[mm/s]">
          <div>H <small class="small d-block d-sm-inline">[mm/s]</small></div>
        </div>
      </div>
      <div class="col-3 pl-1  pr-0 pr-sm-2">
        <div class="btn btn-dark btn-block"
             data-toggle="tooltip"
             data-placement="top"
             title="[mm/s]">
          <div>V <small class="small d-block d-sm-inline">[mm/s]</small></div>
        </div>
      </div>
      <div class="col-3 pl-1  pr-0 pr-sm-2">
        <div class="btn btn-dark btn-block"
             data-toggle="tooltip"
             data-placement="top"
             title="[mm/s]">
          <div>A <small class="small d-block d-sm-inline">[mm/s]</small></div>
        </div>
      </div>
      <div *ngIf="isTempVisible()" class="col-3">
        <div class="btn btn-dark btn-block"
             data-toggle="tooltip"
             data-placement="top"
             title="[&#8451;]">
          <div>T <small class="small d-block d-sm-inline">[&#8451;]</small></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mb-1"
     *ngFor="let element  of pointItems | async">
  <div class="col-2 col-md-1">
    <span class="small"><span *ngIf="app.isTester | async">#{{ element.Id }} - </span>{{element.Name}}</span>
    <app-sensor-log [point]="element"></app-sensor-log>
  </div>
  <div class="col-10 col-md-11">
    <div class="row">
      <div class="col-3 pl-1 pr-0 pr-sm-2" style="display: inline-block"
           *ngFor="let current of element.Data; let i = index">
        <button class="btn  btn-block"
                [class.btn-outline-success]="isSuccess(current)"
                [class.btn-outline-warning]="isWarning(current)"
                [class.btn-outline-danger]="isDanger(current)"
                [class.btn-outline-secondary]="isSecondary(current)"
                [class.active]="isActive(current)"
                [class.btn-danger]="ifAlertState(current)"
                [class.btn-warning]="ifWarningState(current)"
                [class.btn-light]="ifDefaultState(current)"
                (click)="setSelected(current, element)">
          <span>{{ getValue(current) }}</span>
        </button>
        <sup *ngIf="app.isTester | async" style="position: absolute; right: 0; top: -1px; "><span class="badge badge-danger">{{ getAlertCount(current) }}</span></sup>
        <sub *ngIf="app.isTester | async" style="position: absolute; right: 0; bottom: -1px; "><span class="badge badge-warning">{{ getWarningCount(current) }}</span></sub>
      </div>
      <div *ngIf="isTempVisible()" class="col-3">
        <button [ngClass]="{'btn btn-outline-secondary btn-block' : true}" style="display: inline-block;">
          <span *ngIf="element.Temp">{{ element.Temp | number: '1.0' }}</span>
          <span *ngIf="!element.Temp">NULL</span>
        </button>
      </div>
    </div>
  </div>
</div>-->

<dev-heading [type]="type"></dev-heading>
<dev-row *ngFor="let point  of rows | async" [type]="type" [unit]="unit"
         [row]="point" [device]="device"
         (selected)="selectedChange($event)"
         (change)="onChange($event)"></dev-row>
