<div class="card">
    <div class="card-header">
        Config
    </div>
    <div class="card-body">
        <form #f="ngForm"
              (ngSubmit)="submit(f)">

            <input type="hidden"
                   [ngModel]="item.Id"
                   #Id="ngModel"
                   name="Id"
                   id="Id"
                   name="Id" />
            <input type="hidden"
                   [ngModel]="item.IdGroup"
                   #IdGroup="ngModel"
                   name="IdGroup"
                   id="IdGroup"
                   name="IdGroup" />
            <div class="row">
              <div class="col-12 col-md-2">
                <div class="form-group">
                  <label for="Name">Name</label>
                  <input class="form-control"
                         [(ngModel)]="item.Name"
                         #Name="ngModel"
                         name="Name"
                         id="Name" />

                </div>
              </div>
              <div class="col-12 col-md-2">
                <div class="form-group">
                  <label for="ConfigNumber">Config No.</label>
                  <input type="number"
                         class="form-control"
                         [(ngModel)]="item.ConfigNumber"
                         #ConfigNumber="ngModel"
                         name="ConfigNumber"
                         id="ConfigNumber"
                         placeholder="No."
                         disabled
                         aria-describedby="ConfigNumberHelp">
                  <!-- <small id="ConfigNumberHelp" class="form-text text-muted">Numer konfiguracji - przekazywany
      do
      czujnika</small> -->

                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label for="From">From:</label>
                  <input type="datetime"
                         class="form-control"
                         [ngModel]="item.From | date:'yyyy-MM-dd / HH:mm'"
                         #From="ngModel"
                         name="From"
                         id="From"
                         disabled
                         aria-describedby="FromHelp">
                  <!-- <small id="FromHelp" class="form-text text-muted">....</small> -->
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label for="To">To:</label>
                  <input type="datetime"
                         class="form-control"
                         [ngModel]="item.To | date:'yyyy-MM-dd / HH:mm'"
                         #To="ngModel"
                         name="To"
                         id="To"
                         disabled
                         aria-describedby="ToHelp">
                  <!-- <small id="ToHelp" class="form-text text-muted">....</small> -->
                </div>
              </div>

              <div class="col-12 col-md-3 pb-1">
                <div class="form-group">
                  <label for="TimeoutWifi">Timeout Wi-fi</label>
                  <input type="number"
                         class="form-control"
                         [(ngModel)]="item.TimeoutWifi"
                         #TimeoutWifi="ngModel"
                         name="TimeoutWifi"
                         id="TimeoutWifi"
                         placeholder="[ms]"
                         aria-describedby="TimeoutWifiHelp">
                  <!-- <small id="TimeoutWifiHelp" class="form-text text-muted"> [ms]?</small> -->
                </div>
              </div>
              <div class="col-12 col-md-3 pb-1">
                <div class="form-group">
                  <label for="TimeoutServer">Timeout server</label>
                  <input type="number"
                         class="form-control"
                         [(ngModel)]="item.TimeoutServer"
                         #TimeoutServer="ngModel"
                         name="TimeoutServer"
                         id="TimeoutServer"
                         placeholder="[ms]"
                         aria-describedby="TimeoutServerHelp">
                  <!-- <small id="TimeoutServerHelp" class="form-text text-muted">In [ms]?</small> -->
                </div>
              </div>
              <div class="col-12 col-md-3 pb-1">
                <div class="form-group">
                  <label for="ConnectionDelay">Connection delay</label>
                  <input type="number"
                         class="form-control"
                         [(ngModel)]="item.ConnectionDelay"
                         #ConnectionDelay="ngModel"
                         name="ConnectionDelay"
                         id="ConnectionDelay"
                         placeholder="[ms]"
                         aria-describedby="ConnectionDelayHelp">
                  <!-- <small id="ConnectionDelayHelp" class="form-text text-muted">In [ms]?</small> -->
                </div>
              </div>
              <div class="col-12 col-md-3 pb-1">
                <div class="form-group">
                  <label for="ConnectionLimit">Connection limit</label>
                  <input type="number"
                         class="form-control"
                         [(ngModel)]="item.ConnectionLimit"
                         #ConnectionLimit="ngModel"
                         name="ConnectionLimit"
                         id="ConnectionLimit"
                         placeholder="?"
                         aria-describedby="ConnectionLimitHelp">
                  <!-- <small id="ConnectionLimitHelp" class="form-text text-muted">In ?</small> -->
                </div>
              </div>
              <div class="col-12 col-md-3 pb-1">
                <div class="form-group">
                  <label for="AvarageNumber">Avarage number</label>
                  <!-- <select class="form-control"
              [(ngModel)]="item.IdDevice"
              #IdDevice="ngModel"
              name="IdDevice"
              id="IdDevice">
          <option [ngValue]="0"> - Select - </option>
          <option [ngValue]="current.Id"></option>
      </select> -->
                  <input type="number"
                         class="form-control"
                         [(ngModel)]="item.AvarageNumber"
                         #AvarageNumber="ngModel"
                         name="AvarageNumber"
                         id="AvarageNumber"
                         placeholder="?"
                         aria-describedby="AvarageNumberHelp">
                  <!-- <small id="AvarageNumberHelp" class="form-text text-muted">In ?</small> -->
                </div>
              </div>
              <div class="col-12 col-md-3 pb-1">
                <div class="form-group">
                  <label for="CorRms">Cor Rms</label>
                  <input type="number"
                         class="form-control"
                         [(ngModel)]="item.CorRms"
                         #CorRms="ngModel"
                         name="CorRms"
                         id="CorRms"
                         placeholder="?"
                         aria-describedby="CorRmsHelp">
                  <!-- <small id="CorRmsHelp" class="form-text text-muted">In ?</small> -->
                </div>
              </div>
              <div class="col-12 col-md-3 pb-1">
                <div class="form-group">
                  <label for="OffRms">Off Rms</label>
                  <input type="number"
                         class="form-control"
                         [(ngModel)]="item.OffRms"
                         #OffRms="ngModel"
                         name="OffRms"
                         id="OffRms"
                         placeholder="?"
                         aria-describedby="OffRmsHelp">
                  <!-- <small id="OffRmsHelp" class="form-text text-muted">In ?</small> -->
                </div>
              </div>
              <div class="col-12 col-md-3 pb-1">
                <div class="form-group">
                  <label for="CorAmp">Cor Amp</label>
                  <input type="number"
                         class="form-control"
                         [(ngModel)]="item.CorAmp"
                         #CorAmp="ngModel"
                         name="CorAmp"
                         id="CorAmp"
                         placeholder="?"
                         aria-describedby="CorAmpHelp">
                  <!-- <small id="CorAmpHelp" class="form-text text-muted">In ?</small> -->
                </div>
              </div>
              <!--     <div class="col-12 col-md-3 pb-1">
        <div class="form-group">
            <label for="UserTresEnable">User tres enable</label>
            <select class="form-control"
                    [(ngModel)]="item.UserTresEnable"
                    #UserTresEnable="ngModel"
                    name="UserTresEnable"
                    id="UserTresEnable">
                <option [ngValue]="0">0</option>
                <option [ngValue]="1">1</option>
            </select>
        </div>
    </div>
     <div class="col-12 col-md-3 pb-1">
        <div class="form-group">
            <label for="StatTresEnable">Stat tres enable</label>

            <select class="form-control"
                    [(ngModel)]="item.StatTresEnable"
                    #StatTresEnable="ngModel"
                    name="StatTresEnable"
                    id="StatTresEnable">
                <option [ngValue]="0">0</option>
                <option [ngValue]="1">1</option>
            </select>
        </div>
    </div>
  <div class="col-12 col-md-3 pb-1">
        <div class="form-group">
            <label for="TresStatW">Tres Stat W</label>
            <input type="number"
                   class="form-control"
                   [(ngModel)]="item.TresStatW"
                   #TresStatW="ngModel"
                   name="TresStatW"
                   id="TresStatW"
                   placeholder="?"
                   aria-describedby="TresStatWHelp">
        </div>
    </div>
    <div class="col-12 col-md-3 pb-1">
        <div class="form-group">
            <label for="TresStatA">Tres Stat A</label>
            <input type="number"
                   class="form-control"
                   [(ngModel)]="item.TresStatA"
                   #TresStatA="ngModel"
                   name="TresStatA"
                   id="TresStatA"
                   placeholder="?"
                   aria-describedby="TresStatAHelp">
        </div>
    </div> -->




              <div class="col-12 col-md-3 pb-1">
                <div class="form-group">
                  <label for="SamplingFrequency">Sampling frequency</label>

                  <select class="form-control"
                          [(ngModel)]="item.SamplingFrequency"
                          #SamplingFrequency="ngModel"
                          name="SamplingFrequency"
                          id="SamplingFrequency">
                    <option [ngValue]="1600">1600</option>
                    <option [ngValue]="3200">3200</option>
                    <option [ngValue]="6400">6400</option>
                    <option [ngValue]="12800">12800</option>
                  </select>
                  <!-- <input type="number"
      class="form-control"
      [(ngModel)]="item.SamplingFrequency"
      #SamplingFrequency="ngModel"
      name="SamplingFrequency"
      id="SamplingFrequency"> -->
                  <!-- <small id="UserTresEnableHelp" class="form-text text-muted">In ?</small> -->
                </div>
              </div>
              <div class="col-12 col-md-3 pb-1">
                <div class="form-group">
                  <label for="Kxodr">Kxodr</label>
                  <select class="form-control"
                          [(ngModel)]="item.Kxodr"
                          #Kxodr="ngModel"
                          name="Kxodr"
                          id="Kxodr">
                    <option [ngValue]="3200">3200</option>
                    <option [ngValue]="6400">6400</option>
                    <option [ngValue]="12800">12800</option>
                    <option [ngValue]="25600">25600</option>
                  </select>
                  <!-- <input type="number"
      class="form-control"
      [(ngModel)]="item.Kxodr"
      #Kxodr="ngModel"
      name="Kxodr"
      id="Kxodr"> -->
                  <!-- <small id="StatTresEnableHelp" class="form-text text-muted">In ?</small> -->
                </div>
              </div>

              <div class="col-12 col-md-3 pb-1">
                <div class="form-group">
                  <label for="SampleSize">Sample size</label>
                  <select class="form-control"
                          [(ngModel)]="item.SampleSize"
                          #SampleSize="ngModel"
                          name="SampleSize"
                          id="SampleSize">
                    <option [ngValue]="256">256</option>
                    <option [ngValue]="512">512</option>
                    <option [ngValue]="1024">1024</option>
                    <option [ngValue]="2048">2048</option>
                    <option [ngValue]="4096">4096</option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-md-3 pb-1">
                <div class="form-group">
                  <label for="Kxav">Kxav</label>
                  <select class="form-control"
                          [(ngModel)]="item.Kxav"
                          #Kxav="ngModel"
                          name="Kxav"
                          id="Kxav">
                    <option [ngValue]="0">0</option>
                    <option [ngValue]="2">2</option>
                    <option [ngValue]="4">4</option>
                    <option [ngValue]="8">8</option>
                    <option [ngValue]="16">16</option>
                    <option [ngValue]="32">32</option>
                    <option [ngValue]="64">64</option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-md-3 pb-1">
                <div class="form-group">
                  <label for="SampleSizeRms">Sample size RMS</label>
                  <select class="form-control"
                          [(ngModel)]="item.SampleSizeRms"
                          #SampleSizeRms="ngModel"
                          name="SampleSizeRms"
                          id="SampleSizeRms">
                    <option [ngValue]="256">256</option>
                    <option [ngValue]="512">512</option>
                    <option [ngValue]="1024">1024</option>
                    <option [ngValue]="2048">2048</option>
                    <option [ngValue]="4096">4096</option>
                  </select>
                </div>
              </div>

              <div class="col-12 col-md-3 pb-1">
                <div class="form-group">
                  <label for="SpiEn">SpiEn</label>
                  <button [className]="item.SpiEn ? 'btn btn-block btn-success' : 'btn btn-block btn-warning'"
                          [(ngModel)]="item.SpiEn"
                          #TwfEn="ngModel"
                          name="SpiEn"
                          (click)="onSpiEn()"
                          type="button"
                          id="SpiEn">
                    <span *ngIf="item.SpiEn">Enabled</span>
                    <span *ngIf="!item.SpiEn">Disabled</span>
                  </button>
                </div>
              </div>

              <div class="col-12 col-md-3 pb-1">
                <div class="form-group">
                  <label for="FftCutEn">FftCutEn</label>
                  <button [className]="item.FftCutEn ? 'btn btn-block btn-success' : 'btn btn-block btn-warning'"
                          [(ngModel)]="item.FftCutEn"
                          #TwfEn="ngModel"
                          name="FftCutEn"
                          (click)="onFftCutEn()"
                          type="button"
                          id="FftCutEn">
                    <span *ngIf="item.FftCutEn">Enabled</span>
                    <span *ngIf="!item.FftCutEn">Disabled</span>
                  </button>
                </div>
              </div>

              <div class="col-12 col-md-3 pb-1">
                <div class="form-group">
                  <label for="Kxadp">Kxadp</label>
                  <button [className]="item.Kxadp ? 'btn btn-block btn-success' : 'btn btn-block btn-warning'"
                          [(ngModel)]="item.Kxadp"
                          #TwfEn="ngModel"
                          name="Kxadp"
                          (click)="onKxadp()"
                          type="button"
                          id="Kxadp">
                    <span *ngIf="item.Kxadp">Enabled</span>
                    <span *ngIf="!item.Kxadp">Disabled</span>
                  </button>
                </div>
              </div>
              <div class="col-12 col-md-3 pb-1">
                <div class="form-group">
                  <label for="VelEn">Vel En</label>
                  <button [className]="item.VelEn ? 'btn btn-block btn-success' : 'btn btn-block btn-warning'"
                          [(ngModel)]="item.VelEn"
                          #TwfEn="ngModel"
                          name="VelEn"
                          (click)="onVelEn()"
                          type="button"
                          id="VelEn">
                    <span *ngIf="item.VelEn">Enabled</span>
                    <span *ngIf="!item.VelEn">Disabled</span>
                  </button>
                </div>
              </div>
              <div class="col-12 col-md-3 pb-1">

                <div class="form-group">
                  <label for="VelEn"> Twf En </label>
                  <button [className]="item.TwfEn ? 'btn btn-block btn-success' : 'btn btn-block btn-warning'"
                          [(ngModel)]="item.TwfEn"
                          #TwfEn="ngModel"
                          name="TwfEn"
                          (click)="onTwfEn()"
                          type="button"
                          id="TwfEn">
                    <span *ngIf="item.TwfEn">Enabled</span>
                    <span *ngIf="!item.TwfEn">Disabled</span>
                  </button>
                </div>
            </div>

            <div class="col-12 col-md-3 pb-1">
              <div class="form-group">
                <label for="FftEn">FFT En</label>
                <button [className]="item.FftEn ? 'btn btn-block btn-success' : 'btn btn-block btn-warning'"
                        [(ngModel)]="item.FftEn"
                        #TwfEn="ngModel"
                        name="FftEn"
                        (click)="onFftEn()"
                        type="button"
                        id="FftEn">
                  <span *ngIf="item.FftEn">Enabled</span>
                  <span *ngIf="!item.FftEn">Disabled</span>
                </button>
              </div>
            </div>

            <div class="col-md-12 col-lg-6 col-xl-2">
              <div class="form-group">
                <label for="MesAxis">Axis</label>
                <select class="form-control"
                        [(ngModel)]="item.MesAxis"
                        #Axial="ngModel"
                        name="MesAxis"
                        id="MesAxis">
                  <option [ngValue]="0"> - All - </option>
                  <option [ngValue]="1">X</option>
                  <option [ngValue]="2">Y</option>
                  <option [ngValue]="3">Z</option>
                </select>
              </div>
            </div>
              <div class="col-12 pb-1">
                <div class="form-group">
                  <label for="Server">Endpoint</label>
                  <input type="url"
                         class="form-control"
                         [(ngModel)]="item.Server"
                         #Server="ngModel"
                         name="Server"
                         id="Server"
                         placeholder="http://www.api.eurokiln.com/endpoint ...">

                </div>
              </div>
            </div>

            <div *ngIf="isTester | async"
                 class="form-group">
                <label for="detail">Json item (preview)</label>
                <textarea class="form-control"
                          disabled
                          rows="10">
                        {{ f.value | json }}
                    </textarea>
            </div>
            <button type="submit"
                    class="btn btn-success float-right"
                    [disabled]="!f.valid"
                    *ngIf="f.touched"><i class="far fa-save"></i> Zapisz</button>
        </form>
    </div>
</div>
