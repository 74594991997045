import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PagesPaths } from 'src/app/app.constants';
import { Area } from 'src/app/shared/area.model';
import { PeriodEnum } from 'src/app/shared/enum/period-enume';
import { ControlState } from 'src/app/shared/interface/control-state';
import { ActivatedRoute } from "@angular/router";
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-condition',
  templateUrl: './condition.component.html',
  styles: [
  ]
})
export class ConditionComponent implements OnInit {
  controlState: BehaviorSubject<ControlState | null> = new BehaviorSubject(new ControlState());
$isActive: boolean;
@Input()
set isActive(val: boolean){
  if(val && this.$isActive !== val){
    this.$isActive = val;
  }
}
get isActive(){
  return this.$isActive;
}


  clientPath = PagesPaths.Client;
  $area: Area;
  @Input()
  set area(val: Area) {

    if (val && this.$area !== val) {
      this.$area = val;

      if (val) {
        this.http.get<ControlState[]>(`rms/state/${PeriodEnum.Currently}/${val.Id}/${formatDate(new Date(), 'yyyy-MM-dd', 'en-US')}`).subscribe(states => {
          const current = new ControlState();
          states.forEach(element => {
            current.Success += element.Success;
            current.Warning += element.Warning;
            current.Danger += element.Danger;
          });
          this.controlState.next(current);
        });
      }
    }
  }
  get area(): Area {
    return this.$area;
  }


  pill = true;


  constructor(private http: HttpClient,  private route: ActivatedRoute) {

    //this.route.url.subscribe(url => {
    //  if (url[0].path === PagesPaths.Supervision) {
    //    this.clientPath = PagesPaths.Supervision;
    //  } else {
    //    this.clientPath = PagesPaths.Client;
    //  }
    //});
  }

  ngOnInit(): void {
  }

}
