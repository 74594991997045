  <!-- Messages Dropdown Menu -->
<!-- <li class="nav-item dropdown">
      <a class="nav-link"
         data-toggle="dropdown"
         href="#">
        <i class="far fa-comments"></i>
        <span class="badge badge-danger navbar-badge">3</span>
      </a>
      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <a href="#"
           class="dropdown-item">
          <div class="media">
            <img src="assets/img/user.png"
                 alt="User Avatar"
                 class="img-size-50 mr-3 img-circle">
            <div class="media-body">
              <h3 class="dropdown-item-title">
                Brad Diesel
                <span class="float-right text-sm text-danger"><i class="fas fa-star"></i></span>
              </h3>
              <p class="text-sm">Call me whenever you can...</p>
              <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
            </div>
          </div>
        </a>
        <div class="dropdown-divider"></div>
        <a href="#"
           class="dropdown-item">
          <div class="media">
            <img src="assets/img/user.png"
                 alt="User Avatar"
                 class="img-size-50 img-circle mr-3">
            <div class="media-body">
              <h3 class="dropdown-item-title">
                John Pierce
                <span class="float-right text-sm text-muted"><i class="fas fa-star"></i></span>
              </h3>
              <p class="text-sm">I got your message bro</p>
              <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
            </div>
          </div>
        </a>
        <div class="dropdown-divider"></div>
        <a href="#"
           class="dropdown-item">
          <div class="media">
            <img src="assets/img/user.png"
                 alt="User Avatar"
                 class="img-size-50 img-circle mr-3">
            <div class="media-body">
              <h3 class="dropdown-item-title">
                Nora Silvester
                <span class="float-right text-sm text-warning"><i class="fas fa-star"></i></span>
              </h3>
              <p class="text-sm">The subject goes here</p>
              <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
            </div>
          </div>
        </a>
        <div class="dropdown-divider"></div>
        <a href="#"
           class="dropdown-item dropdown-footer">See All Messages</a>
      </div>
    </li> -->
<!-- Notifications Dropdown Menu -->
<!-- <li class="nav-item dropdown">
      <a class="nav-link"
         data-toggle="dropdown"
         href="#">
        <i class="far fa-bell"></i>
        <span class="badge badge-warning navbar-badge">15</span>
      </a>
      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <span class="dropdown-item dropdown-header">15 Notifications</span>
        <div class="dropdown-divider"></div>
        <a href="#"
           class="dropdown-item">
          <i class="fas fa-envelope mr-2"></i> 4 new messages
          <span class="float-right text-muted text-sm">3 mins</span>
        </a>
        <div class="dropdown-divider"></div>
        <a href="#"
           class="dropdown-item">
          <i class="fas fa-users mr-2"></i> 8 friend requests
          <span class="float-right text-muted text-sm">12 hours</span>
        </a>
        <div class="dropdown-divider"></div>
        <a href="#"
           class="dropdown-item">
          <i class="fas fa-file mr-2"></i> 3 new reports
          <span class="float-right text-muted text-sm">2 days</span>
        </a>
        <div class="dropdown-divider"></div>
        <a href="#"
           class="dropdown-item dropdown-footer">See All Notifications</a>
      </div>
    </li> -->


<li class="nav-item border-right" *ngIf="msg | async">
  <a class="nav-link pl-1 pr-2" href="#" data-toggle="tooltip" data-placement="bottom" title="Messages">
    <i class="far fa-bell text-info"></i>
    <sup class="badge badge-pill badge-info">{{ msg | async }} </sup>
  </a>
</li>
  <li class="nav-item border-right" *ngIf="app.selectedPodmiotId | async">
    <a class="nav-link pl-1 pr-2" style="padding-top: 2px;"  [routerLink]="['/sensor-config', (app.selectedPodmiotId | async)]"  data-toggle="tooltip" data-placement="bottom" title="Config">
      <img src="czujnik.png" style="width: 40px; top:0" class="brand-image img-circle elevation-1"/>
    </a>
  </li>
<li class="dropdown user user-menu app-login-menu"></li>
<li *ngIf="isAdminRot | async" class="nav-item">
  <a class="nav-link" [routerLink]="['/settings']" >
    <i class="fas fa-cogs"></i>
  </a>
</li>

<!-- User Account: style can be found in dropdown.less -->
<!-- <li class="nav-item">
      <a class="nav-link"
         data-widget="control-sidebar"
         data-slide="true"
         href="#"
         role="button"><i class="fas fa-th-large"></i></a>
    </li> -->
