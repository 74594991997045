import { Axis } from "@syncfusion/ej2-charts";

export class SensorPointLog {
  Id: number;
  Number: string;
  Tag: string;
  Equal: boolean;

  LogId: number;
  SendDate: Date;
  TimeStamp: Date;
  Firmware: number;
  Battery: number;
  CoverageStrength: number;
  NetworkName: string;
  VerticalAxis: Axis;
  AngleX: number;
  AngleY: number;
  AngleZ: number;

  IdPoint: number;
  PointName: string;
  IdDevice: number;
  IdConfig: number;
  PointVisible: boolean;

  IdArea: number;
  DevName: string;
  AreaName: string;
  PodmiotId: number;
  //PodmiotName: string;
}
