<div class="modal-header">
  <h4 class="modal-title">
    <strong> {{ srv.selectedDevice.Name }}</strong>
  </h4>
  <span type="button"
          class="close"
          aria-label="Close"
          (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
</span>
</div>
<div class="modal-body">

</div>
<div class="modal-footer">
  <span type="button"
          class="btn btn-outline-dark"
          (click)="activeModal.close('Close click')">Close</span>
</div>
