import { AxisEnume } from "src/app/shared/enum/axis-enum";
import { NewsEnum } from "src/app/shared/enum/news-enum";

export class Notify {
  Id: number;
  Axis: AxisEnume;
  Start: Date;
  End: Date;
  Genre: NewsEnum;
  IdPoint: number;
  IdArea: number;
  VibroUserId: number;

  constructor() {
    this.Start = new Date();

  }
}

