import { KeyValuePipe } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { not } from "@angular/compiler/src/output/output_ast";
import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppService } from "src/app/services/app.service";
import { ProfileService } from "src/app/services/profile.service";
import { Device } from "src/app/shared/device.model";
import { MeasuringPoint } from "src/app/shared/measuring-point.model";
import { NewsEnum } from "../../shared/enum/news-enum";
import { ModalPointsComponent } from "../modal-points/modal-points.component";
import { Notify } from "../shared/notify.model";
import { catchError, tap } from 'rxjs/operators';
import { AlertService } from "../../shared/_alert";
import { Observable, of, BehaviorSubject } from "rxjs";
import { AxisEnume } from "../../shared/enum/axis-enum";
import { TypeDevice } from "../shared/type-device.model";



@Component({
  selector: "app-gong-alert",
  templateUrl: "./gong-alert.component.html",
  styles: [],
})
export class GongAlertComponent implements OnInit {

  type = TypeDevice.BasicAlert;
  constructor(
    private http: HttpClient,
    public app: AppService,
    public srv: ProfileService, private alert: AlertService,
    private modalService: NgbModal) {
    app.selectedArea.subscribe((area) => {
      if (area) {
        this.http.get<Device[]>("rms/Device/" + area.Id).subscribe(devices => app.devices.next(devices));
      }
    });
    // app.devices.subscribe((devices) => {
    //   if (devices) {
    //     this.app.points.next(new Array());
    //     devices.forEach((device) => {
    //       this.http
    //         .get<MeasuringPoint[]>(`rms/rms/pointNotify/${device.Id}`)
    //         .subscribe((points) => {
    //           let temp = this.app.points.value;
    //           if (temp && temp.find(p => p.key.Id === device.Id)) {
    //             temp[temp.indexOf(temp.find(p => p.key.Id === device.Id))] = { key: device, value: points };
    //             this.app.points.next(temp);
    //           } else {
    //             temp.push({ key: device, value: points });
    //             this.app.points.next(temp);
    //           }
    //         });
    //     });
    //   }
    // });
  }

  ngOnInit(): void { }


}
