import { Component } from '@angular/core';
import { MeasuringConfig } from '../../shared/measuring-config.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MeasuringConfigService } from '../../services/device/measuring-config.service';
import { BaseItemsComponent } from '../../shared/base-items/base-items.component';
import { AuthorizeService } from 'src/api-authorization/authorize.service';

@Component({
  selector: 'app-criticals',
  templateUrl: './criticals.component.html',
  styles: []
})
export class DeviceMeasurConfigsComponent extends BaseItemsComponent<MeasuringConfig> {

  constructor(service: MeasuringConfigService, route: ActivatedRoute, router: Router,
    auth: AuthorizeService) {
    super(MeasuringConfig, service, route, router,auth);
    this.property = [
      { PropertyName: "Description", Display: "Name" },
    ];
  }
}
