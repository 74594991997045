import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-battery',
  templateUrl: './battery.component.html',
  styleUrls: ['./battery.component.css']
})
export class BatteryComponent implements OnInit {
  @Input()
  Battery: number;

  @Input()
  HideValue = true;
  @Input()
  ToolTip = false;

  zeroPercent = 3150;
  constructor() { }

  ngOnInit(): void {
  }

  getBattery(bat: number) {
    let batP = (bat - this.zeroPercent) / 10;
    if (batP > 100)
      batP = 100;
    return `${batP.toFixed(0)}% (${(bat / 1000).toFixed(1)} V)`;
  }
}
