import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '../services/app.service';
import { Area } from '../shared/area.model';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styles: [
  ]
})
export class DefaultComponent implements OnInit {

  // selectedPodmiotId: number;
  // selectedAreaId: number;
  // area: Observable<Area>;
  constructor(private http: HttpClient, private route: ActivatedRoute, public app: AppService) {
    this.route.paramMap.subscribe(parmMap => {
      app.selectedPodmiotId.next(+parmMap.get("podmiotid"));
      app.selectedAreaId.next(+parmMap.get("id"));
    });
  }

  ngOnInit(): void {
  }

}
