import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { Device } from 'src/app/shared/device.model';
import { AxisEnume } from 'src/app/shared/enum/axis-enum';
import { Row } from '../row/row.model';
import { RmsData } from "../../../shared/rms-data.model";
import { ControlState } from "../../../shared/interface/control-state";
import { MeasuringPoint } from "../../../shared/measuring-point.model";
import { Limit } from "../../../shared/limit.model";
import { PeriodEnum } from "../../../shared/enum/period-enume";
import { Config } from "../../../shared/config.model";
import { formatDate } from '@angular/common';
import { StateEnume } from "../../../shared/enum/state-enum";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: 'app-cell',
  templateUrl: './cell.component.html',
  styles: [
  ]
})
export class CellComponent implements OnInit {
  @Input()
  limit: Limit[];

  @Input()
  device: Device;

  @Input()
  point: MeasuringPoint | Row;

  @Input()
  unit: string;

  @Input()
  axis: AxisEnume;

  //@Input()
  //current: RmsData;
  private current: RmsData;
  private _data: RmsData[];
  private isSend= false;

  public currentValue: BehaviorSubject<string> = new BehaviorSubject("NULL");

  @Input() set data(value: RmsData[]) {
    if (value) {
      this.isSend = false;
      this._data = value;
      this.current = this._data.findIndex(p => p.Axis === this.axis) >= 0
        ? this._data.find(p => p.Axis === this.axis)
        : this.getTemp();
      // this.doSomething(this._categoryId);
      this.currentValue.next(this.getValue);
      if (this.app.lastDate.value == null || this.app.lastDate.value < this.current.TimeStamp)
        this.app.lastDate.next(this.current.TimeStamp);
    } else {
      this.isSend = false;
      this.current = this.getTemp();
      this.currentValue.next(this.getValue);
    }
  }

  get data(): RmsData[] {
    return this._data;
  }


  @Output() change = new EventEmitter<ControlState>();
  @Output() selected = new EventEmitter();

  private controlState = new ControlState();
  constructor(private http: HttpClient,
    public app: AppService) { }

  ngOnInit(): void {




  }
  getTemp(): RmsData{
    if (!this.axis && this.data) {
      let t = new RmsData();
      t.IdPoint = this._data[0].IdPoint;
      t.SendDate = this._data[0].SendDate;
      t.Unit = this._data[0].Unit;
      t.Id = this._data[0].Id;
      t.SensorNumer = this._data[0].SensorNumer;
      t.Temp = this._data[0].Temp;
      t.Value = this._data[0].Value;
      t.Axis = null;
      return t; //this._data[0];
    }
    let temp = new RmsData();
    if (this.axis) {
      temp.Id = -(this.axis as number);
    } else {
      //Temperatura
      temp.Id = -4;
    }
   temp.IdPoint = this.point.Id;
   temp.SensorNumer = this.point.SensorNumer;
   temp.Axis = this.axis;
   temp.Unit = +this.unit;
   return temp;
  }

 get getValue(): string {
   if (this.app.isNormalState) {
    if (!this.current || !this.current.Value) {
      return "NULL";
    }
     if (this.axis) {
       return this.current.Value.toFixed(2);
     } // | number:'1.2-2';
     else {
       return this.current.Temp.toFixed(0);
     }
   } else {
     if (!this.current.Value) {
       return "0";
     }
     return this.current.Value.toFixed(0);
   }
  }
  // open() {
  //  const modalRef = this.modalService.open(ModalAlertComponent, { size: 'xl' });
  //  modalRef.componentInstance.device = this.device;
  //  modalRef.componentInstance.point = this.point;
  //  modalRef.componentInstance.axis = this.axis;
  // }

  get cssClass(){//}val: NewsEnum) {
     //if (!val) return;

    const classes = ["btn", "btn-block"];

     //const alertTypeClass = {
     //  [NewsEnum.Lack]: "btn-light",
     //  [NewsEnum.Warning]: "btn-warning",
     //  [NewsEnum.Alert]: "btn-danger",
     //  [NewsEnum.Msg]: "btn-info",
     //};



                //[class.active]="isActive(current)"
                //[class.btn-danger]="ifAlertState(current)"
                //[class.btn-warning]="ifWarningState(current)"
                //[class.btn-light]="ifDefaultState(current)"
    // classes.push(alertTypeClass[val]);
    if (this.isDanger())
      classes.push("btn-outline-danger");

    if (this.isSuccess())
      classes.push("btn-outline-success");

    if (this.isWarning())
      classes.push("btn-outline-warning");

    if (this.isSecondary())
      classes.push("btn-outline-secondary");


    if (this.isActive())
      classes.push("active");

    if (this.ifAlertState())
      classes.push("btn-danger");

    if (this.ifWarningState())
      classes.push("btn-warning");

    if (this.ifDefaultState())
      classes.push("btn-light");

     return classes.join(" ");
  }

  isActive() {
    if (!this.current || this.current == undefined || !this.app.selectedChartData) return false;
    //zaznaczona temperatura
    if (this.app.isNormalState && this.app.selectedChartData && this.app.selectedChartData.IdPoint === this.current.IdPoint && !this.app.selectedChartData.Axis && !this.axis) return true;
    if (this.app.isNormalState && this.app.selectedChartData && this.app.selectedChartData.IdPoint === this.current.IdPoint && this.app.selectedChartData.Axis === this.axis)
      return true;
    return false;
  }
  ifAlertState() {
    return this.current && this.current.Value && this.app.state.value === StateEnume.Alert;
  }
  ifWarningState() {
    return this.current && this.current.Value && this.app.state.value === StateEnume.Warning;
  }
  ifDefaultState() {
    return !this.current|| !this.current.Value || this.current.Value === null;

  }


  isDanger() {
    if (this.app.isHighRiskState) return false;
    if (this.current && this.current.Id && this.current.Id > 0) {

      if (this.limit) {
        const limit = this.limit.find(x => x.Unit === this.current.Unit && x.Axis === this.axis && x.End === null);
        if (limit) {
          const danger = limit.Second;
          if (this.current && danger && this.current.Value >= danger) {
            if (!this.isSend) {
              this.controlState = new ControlState();
              this.controlState.Unit = this.unit;
              this.controlState.IdPoint = this.point.Id;
              this.controlState.Danger += 1;
              this.change.emit(this.controlState);
            }

            this.isSend = true;
             return true;
          }
        }
        return false;
      }
    }
    return false;
  }
  isSuccess() {
    if (this.app.isHighRiskState) return false;
    if (this.current && this.current.Id && this.current.Id > 0) {

      if (this.limit) {
        const limit = this.limit.find(x => x.Unit === this.current.Unit && x.Axis === this.axis && x.End === null);
        if (limit) {
          const warning = limit.First;

          if (this.current && this.current.Value < warning) {
            if (!this.isSend) {
            this.controlState = new ControlState();
            this.controlState.Unit = this.unit;
            this.controlState.IdPoint = this.point.Id;
            this.controlState.Success += 1;
              this.change.emit(this.controlState);
            }

            this.isSend = true;
            return true;
          }
        }
        return false;
      }
    }
    return false;
  }
  isWarning() {
    if (this.app.isHighRiskState) return false;
    if (this.current && this.current.Id && this.current.Id > 0) {


      if (this.limit) {
         const limit = this.limit.find(x => x.Unit === this.current.Unit && x.Axis === this.axis && x.End === null);
        if (limit) {
          if (this.current && this.current.Value >= limit.First && this.current.Value < limit.Second) {
            if (!this.isSend) {
            this.controlState = new ControlState();
            this.controlState.Unit = this.unit;
            this.controlState.IdPoint = this.point.Id;
            this.controlState.Warning += 1;
              this.change.emit(this.controlState);
            }

            this.isSend = true;
            return true;
          }
        }
        return false;
      }
    }
    return false;
  }
  isSecondary() {
    if (this.app.isHighRiskState) return false;
    if (!this.current || !this.current.Id || this.current.Id <= 0) {
      return true;
    }
    if (this.limit === null || this.current.Id > 0)
      return true;
    if (this.current && !this.current.Value)
      return true;
    return false;
  }

  setSelected() {
    if (this.app.isNormalState) {


      //this.selectedData = data;
      this.app.selectedChartData = <RmsData> this.current;

      this.selected.emit({ rms: this.current, point:  this.point, limit: this.limit });
    }
  }
}
