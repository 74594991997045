import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { SensorService } from 'src/app/services/sensor/sensor.service';
import { SettingsService } from 'src/app/services/settings.service';
import { StateEnume } from 'src/app/shared/enum/state-enum';
import { Sensor } from 'src/app/shared/sensor.model';
import { AlertService } from 'src/app/shared/_alert';

@Component({
  selector: 'app-sensor',
  templateUrl: './sensor.component.html',
  styles: []
})
export class SensorComponent implements OnInit {
  @Input() item: Sensor;
 // @Input() state: StateEnume;

  public isTester: BehaviorSubject<boolean | null> = new BehaviorSubject(null);

  color: ThemePalette = "warn";

  constructor(private service: SensorService, auth: AuthorizeService ,  private alert: AlertService, public settings: SettingsService) {
      auth.getUser().pipe(map(u => u.role)).subscribe(roles => {
        if (roles) {
          // this.isAdminRot.next(roles.includes("AdminRot"));
          // this.isZmp.next(roles.includes("ZMP"));
          this.isTester.next(roles.includes("Tester"));
        }else{
          // this.isAdminRot.next(false);
          // this.isZmp.next(false);
          this.isTester.next(false);
        }
      });
    }

  ngOnInit() {
    this.settings.selectedSensor.subscribe(sensor => {
      if (sensor)
        this.item = sensor;
    });

  }
getReleted(){
  if(this.settings.points){
  return this.settings.points.filter(p=>p.SensorNumer == this.item.Number);
  }
  return [];
}
  submit(f: NgForm) {
    if (this.settings.state === StateEnume.New) {
      this.service.add<Sensor>(this.item)
        .subscribe(
          result => {
            console.log(result);
            //this.config = result.json();
            this.settings.state = StateEnume.Details;
            this.item = result;
            this.settings.dataChange.next(true);
            this.alert.success("New record saved.");
          });
    }
    if (this.settings.state === StateEnume.Edit || this.settings.state === StateEnume.Details) {
      let update = new Sensor();
      update.Id = this.item.Id;
      update.IdPodmiot = this.item.IdPodmiot;
      update.Number = this.item.Number;
      update.Tag = this.item.Tag;
      update.Archival = this.item.Archival;

      this.service.update<Sensor>(update, this.item.Id)
        .subscribe(
          result => {
            console.log(result);
            //this.config = result.json();
            this.settings.state = StateEnume.Details;
            this.item = result;
            this.settings.dataChange.next(true);
            this.alert.success("Edited record saved.");
          });
    }
  }

}
