<form #f="ngForm"
      (ngSubmit)="submit(f)">
    <input type="hidden"
           [ngModel]="item.Id"
           #Id="ngModel"
           name="Id"
           id="Id"
           name="Id" />
    <div class="row">
        <div class="col-2">
            <input type="number"  class="form-control" readonly [(ngModel)]="item.Order"
            #Order="ngModel"
            name="Order"
            id="Order">           
        </div>
        <div class="col  pl-0">
            <div class="form-group">
                <input class="form-control"
                       [(ngModel)]="item.SsId"
                       #SsId="ngModel"
                       name="SsId"
                       id="SsId"
                       [attr.disabled]="item.Id?'':null"
                       placeholder="SSID">

            </div>
        </div>
        <div class="col pr-0">
            <div class="form-group">
                <input class="form-control"
                       [(ngModel)]="item.Code"
                       #Code="ngModel"
                       name="Code"
                       id="Code"
                       [attr.disabled]="item.Id?'':null"
                       placeholder="Code">

            </div>
        </div>
        <div class="col-2 pr-0">
            <button type="submit"
                    class="btn " [class.btn-success]="!item.Id" [class.btn-danger]="item.Id">
                <i class="far fa-save" [class.fa-save]="!item.Id" [class.fa-trash-alt]="item.Id"></i>
            </button>
        </div>
    </div>
</form>
