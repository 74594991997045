import { Component } from '@angular/core';
import { AlertService } from '../../shared/_alert';

@Component({
  selector: 'app-counter-component',
  templateUrl: './counter.component.html'
})
export class CounterComponent {
  public currentCount = 0;


  options = {
    autoClose: false,
    keepAfterRouteChange: false
};

  constructor(public  alertService: AlertService){
    //throw Error("Test Error ssss");
  }

  public incrementCounter() {
    this.currentCount++;
  }
}
