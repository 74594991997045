import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AppService } from "src/app/services/app.service";
import { ProfileService } from "src/app/services/profile.service";
import { AxisEnume } from "src/app/shared/enum/axis-enum";


@Component({
  selector: "app-modal-points",
  templateUrl: "./modal-points.component.html",
  styles: [],
})
export class ModalPointsComponent implements OnInit {
  $id: number;
  /**
   * Device Id
   */
  @Input() set Id(id: number) {
    this.$id = id;
  }
  get Id() {
    return this.$id;
  }



  constructor(
    public activeModal: NgbActiveModal,
    public app: AppService,
    public srv: ProfileService) { }

 

  ngOnInit() { }
}
