import { ErrorHandler, Injectable } from "@angular/core";
//import { AlertService } from "../_alert";


@Injectable()
export class AppErrorHandler extends ErrorHandler {



  handleError(error) {
    console.log("Handling error: " + error);
   //// debugger;
   // this.alertService.error(error.rejection.message, {
   //   autoClose: true,
   //   keepAfterRouteChange: true
   // });
    super.handleError(error);
  }

  ///**
  // * Need to get AlertService from injector rather than constructor injection to avoid cyclic dependency error
  // * @returns {} 
  // */
  //private get alertService(): AlertService {
  //  return this.injector.get(AlertService);
  //}

}
