import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Config } from '../../shared/config.model';
import { DataService } from '../data.service';
import { AlertService } from 'src/app/shared/_alert';


@Injectable({
  providedIn: 'root'
})
export class ConfigService extends DataService {

  // httpOptions = {
  //   headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  // };

  constructor(http: HttpClient, alert: AlertService) {
    super('rms/Configs', http, alert);
  }
}
