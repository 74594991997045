import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Area } from '../shared/area.model';
import { Device } from '../shared/device.model';
import { AxisEnume } from '../shared/enum/axis-enum';
import { PeriodEnum } from '../shared/enum/period-enume';
import { StateEnume } from '../shared/enum/state-enum';
import { Limit } from '../shared/limit.model';
import { MeasuringPoint } from '../shared/measuring-point.model';
import { RmsData } from '../shared/rms-data.model';
import { formatDate } from '@angular/common';
import { Config } from '../shared/config.model';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ControlState } from '../shared/interface/control-state';
import { GongService } from "../services/gong.service";
import { NewsEnum } from "../shared/enum/news-enum";
import { ChartNews } from "../shared/chart-news.model";
import { AppService } from "../services/app.service";
import { PagesPaths } from "../app.constants";
import { TypeDevice } from "../profile/shared/type-device.model";
import { Row } from "../profile/device/row/row.model";
import { AlertService } from "../shared/_alert/alert.service";
import { DeviceViewService } from "../profile/device/device-view.service";
import { Notify } from "../profile/shared/notify.model";

@Component({
  selector: 'app-device-last',
  templateUrl: './device-last.component.html',
  styles: [
  ]
})
export class DeviceLastComponent implements OnInit{//, OnDestroy  {

  type: TypeDevice = TypeDevice.Default;

  
  rows: BehaviorSubject<Row[] | null> = new BehaviorSubject(null);
  @Input()
  unit: string;

  $device: Device;
  @Input()
  set device(dev: Device) {
    if (this.$device !== dev) {
      this.$device = dev;

      this.http.get<MeasuringPoint[]>(`rms/rms/point/${dev.Id}`)
        .subscribe((points) => {
          this.rows.next(points);
        });
    }
  }
  get device() {
    return this.$device;
  }
  constructor(
    private http: HttpClient,
    public app: AppService,
    private alert: AlertService,
    public view: DeviceViewService
  ) { }

  ngOnInit(): void {
    this.app.ActivePage.subscribe(p => {
      if (p === PagesPaths.Alert) {
        this.type = TypeDevice.AlertView;
      } else if (p === PagesPaths.Warning) {
        this.type = TypeDevice.WarningView;
      } else {
        this.type = TypeDevice.Default;
      }
    });
  }


  //test: Observable<[PeriodEnum, Device, StateEnume, string]>;
 

  //public news: BehaviorSubject<ChartNews[] | null> = new BehaviorSubject(null);

  //@Input() area: Area;

  //$device: BehaviorSubject<Device | null> = new BehaviorSubject(null);
  //@Input()
  //set device(val: Device) {
  //  if (val && this.$device.value !== val) {
  //    this.$device.next(val);
  //  }
  //}
  //get device() {
  //  return this.$device.value;
  //}

  //@Input() tab: string;
  //@Input() $unit: BehaviorSubject<string | null> = new BehaviorSubject(null);// string;
  //@Input()
  //set unit(val: string) {
  //  if (this.$unit.value !== val) {
  //    this.$unit.next(val);// = val;
  //    this.getAllData();
  //  }
  //}
  //get unit(): string {
  //  return this.$unit.value;
  //}


  @Output() change = new EventEmitter<ControlState>();
  @Output() selected = new EventEmitter();

  //private controlState = new ControlState();

  //private confId: number;
  //public selectedData: RmsData;

  //public $pointItems: MeasuringPoint[];

  //public pointItems: BehaviorSubject<MeasuringPoint[]> = new BehaviorSubject([new MeasuringPoint()]);

  //public tempPoint: BehaviorSubject<MeasuringPoint[]> = new BehaviorSubject([new MeasuringPoint()]);
  lastData: Date;
  //constructor(private http: HttpClient,
  //  public app: AppService,
  //  private route: ActivatedRoute,
  //  private gong: GongService) {
  //  this.app.selectedTab.subscribe(tab => {
  //    if (this.app.selectedChartData) {
  //      let point = this.pointItems.value.find(p => p.Id === this.app.selectedChartData.IdPoint);
  //      if (tab !== this.unit && point) {
  //        if (this.selectedData != null)
  //          this.app.selectedChartData = this.selectedData;
  //        this.selectedData = null;
  //      } else {
  //        if (this.app.selectedChartData.Unit.toString() === this.unit && point) {
  //          this.selectedData = this.app.selectedChartData;
  //        }
  //      }
  //    }
  //  });

  //  this.test = combineLatest([this.app.period, this.$device, this.app.state, this.$unit]);
  //  this.test.subscribe(([period, device, state, unit]) => {
  //    if (period && device && state && unit) {
  //      if (period !== this.app.periodT || device.Id !== this.app.deviceT || state !== this.app.stateT || unit !== this.app.unitT) {
  //        if (device && (state === StateEnume.Alert || state === StateEnume.Warning)) {
  //          const newsType = state === StateEnume.Alert ? NewsEnum.Alert : NewsEnum.Warning;
  //          let currentDt: Date = null;
  //          switch (period) {
  //          case PeriodEnum.Day:
  //            currentDt = new Date(this.app.selectedYear.value,
  //              this.app.selectedMonth.value - 1,
  //              this.app.selectedDay.value);
  //            break;
  //          case PeriodEnum.Month:
  //            currentDt = new Date(this.app.selectedYear.value, this.app.selectedMonth.value - 1, 1);
  //            break;
  //          case PeriodEnum.Year:
  //            currentDt = new Date(this.app.selectedYear.value, 1, 1);
  //            break;
  //          case PeriodEnum.Last:
  //            currentDt = null;
  //            break;
  //          default:
  //            return;
  //          }
  //          if (this.app.ActivePage.value === PagesPaths.Alert && state === StateEnume.Alert ||
  //            this.app.ActivePage.value === PagesPaths.Warning && state === StateEnume.Warning) {
  //            if (unit && unit === "86") {
  //              this.app.periodT = period;
  //              this.app.deviceT = device.Id;
  //              this.app.stateT = state;
  //              this.app.unitT = unit;
  //              this.getNews(device, period, newsType, currentDt);
  //            }
  //          }
  //        }
  //      }
  //    }
  //  });
  //}
  //ngOnInit(): void {
  //  combineLatest([this.news, this.tempPoint])
  //  .subscribe(([cNews, temp]) => {
  //    if (cNews) {
  //      temp.forEach(point => {
  //        point.Data.forEach(d => {
  //          const n = cNews.filter(p => p.IdPoint === point.Id && p.Unit === `${this.unit}` && p.Axis === d.Axis);
  //          if (n) {
  //            d.Value = n.length;
  //          }
  //        });
  //      });
  //      this.$pointItems = temp;
  //      this.pointItems.next(temp);
  //    }
  //  });
  //}

  
  //getAllData() {
  //  if (this.app.isNormalState) {
  //    this.controlState = new ControlState();
  //    this.http.get<MeasuringPoint[]>(`rms/rms/point/${this.device.Id}`, { withCredentials: true }).subscribe(
  //      result => {
  //        this.$pointItems = result;
  //        let index = 0;
  //        this.$pointItems.forEach(point => {
  //          index += 1;
  //          let x = new RmsData();
  //          x.Id = -index;
  //          x.IdPoint = point.Id;
  //          x.SensorNumer = point.SensorNumer;
  //          x.Axis = AxisEnume.X;
  //          x.Unit = +this.$unit.value;

  //          index += 1;
  //          let y = new RmsData();
  //          y.Id = -index;
  //          y.IdPoint = point.Id;
  //          y.SensorNumer = point.SensorNumer;
  //          y.Axis = AxisEnume.Y;
  //          y.Unit = +this.$unit.value;

  //          index += 1;
  //          let z = new RmsData();
  //          z.Id = -index;
  //          z.IdPoint = point.Id;
  //          z.SensorNumer = point.SensorNumer;
  //          z.Axis = AxisEnume.Z;
  //          z.Unit = +this.$unit.value;

  //          point.Data = [x, y, z];
  //          this.http.get<Limit[]>(`rms/limits/${point.Id}`, { withCredentials: true }).subscribe(limits => {

  //            this.$pointItems.find(x => x.Id == point.Id).Limits = limits;
  //          });
  //          this.http.get<RmsData[]>(
  //            `rms/rms/${point.Id}/${PeriodEnum.Last}/${AxisEnume.All}/${formatDate(new Date(), 'yyyy-MM-dd', 'en-US')
  //            }/${this.unit}`,
  //            { withCredentials: true }).subscribe(rms => {

  //              this.controlState = new ControlState();
  //              if (rms) {
  //                this.lastData = rms[0].TimeStamp;
  //                rms.forEach(r => {
  //                  if (point.Limits) {
  //                    let limit = point.Limits.find(x => x.Unit === r.Unit && x.Axis === r.Axis && x.End === null);
  //                    if (limit) {
  //                      if (r.Value >= limit.Second) {
  //                        this.controlState.Danger += 1;
  //                      } else if (r.Value >= limit.First) {
  //                        this.controlState.Warning += 1;
  //                      } else {
  //                        this.controlState.Success += 1;
  //                      }
  //                    }
  //                  }
  //                  this.$pointItems.find(x => x.Id == point.Id).Temp = r.Temp;
  //                  this.$pointItems.find(x => x.Id == point.Id).Data[r.Axis - 1] = r;
  //                });
  //              }
  //              this.change.emit(this.controlState);
  //              if (this.confId !== point.IdConfig) {
  //                this.confId = point.IdConfig;
  //                this.http.get<Config>(`rms/configs/${point.IdConfig}`, { withCredentials: true }).subscribe(conf => {
  //                  this.$pointItems.find(x => x.IdConfig === conf.Id).Config = conf;

  //                },
  //                  errorConf => console.error(errorConf));
  //              }

  //            },
  //              errorRms => console.error(errorRms));
  //        });

  //        this.pointItems.next(this.$pointItems);
  //      },
  //      errorPoint => console.error(errorPoint));
  //  } else {
  //    this.http.get<MeasuringPoint[]>(`rms/rms/point/${this.device.Id}`, { withCredentials: true }).subscribe(
  //      result => {
  //        this.$pointItems = result;
  //        let index = 0;
  //        this.$pointItems.forEach(point => {
  //          index += 1;
  //          let x = new RmsData();
  //          x.Id = -index;
  //          x.IdPoint = point.Id;
  //          x.SensorNumer = point.SensorNumer;
  //          x.Axis = AxisEnume.X;
  //          x.Unit = +this.$unit.value;

  //          index += 1;
  //          let y = new RmsData();
  //          y.Id = -index;
  //          y.IdPoint = point.Id;
  //          y.SensorNumer = point.SensorNumer;
  //          y.Axis = AxisEnume.Y;
  //          y.Unit = +this.$unit.value;

  //          index += 1;
  //          let z = new RmsData();
  //          z.Id = -index;
  //          z.IdPoint = point.Id;
  //          z.SensorNumer = point.SensorNumer;
  //          z.Axis = AxisEnume.Z;
  //          z.Unit = +this.$unit.value;

  //          point.Data = [x, y, z];
  //        });
  //        this.tempPoint.next(this.$pointItems);
          
  //      });
  //  }
  //}


  isTempVisible() {

    if (this.app.isHighRiskState) return false;
    return true;
  }

  //getAlertCount(current: RmsData) {

  //  if (current && current.Alert)
  //    return current.Alert.length;
  //  return "";
  //}
  //getWarningCount(current: RmsData) {

  //  if (current && current.Warning)
  //    return current.Warning.length;
  //  return "";
  //}



  selectedChange(data) {
    this.selected.emit(data);
  }
  onChange(data: ControlState) {
    this.change.emit(data);
  }

}
