<div class="card">
  <div class="card-header pl-3"
       *ngIf="device">
    <!--<i class="fas fa-cog"
       [class.text-success]="controlState == 1"
       [class.text-warning]="controlState == 2"
       [class.text-danger]="controlState == 3"></i>-->
    <app-logo style="display:inline-block; width: 30px !important; height: 30px !important; "
              [state]="deviceState | async"></app-logo>
    <span *ngIf="(velocityState | async) && app.isNormalState">
      <span class="mr-1 border border-success rounded">
        <span class="p-1 text-success">{{ (velocityState | async).Success }}</span>
      </span>
      <span class="mr-1 border border-warning rounded">
        <span class="p-1 text-warning">{{ (velocityState | async).Warning }}</span>
      </span>
      <span class="mr-1 border border-danger rounded">
        <span class="p-1 text-danger">{{ (velocityState | async).Danger }}</span>
      </span>
      <!--<span class="text-success text-bold">{{ (velocityState | async).Success }}</span>
  <span class="text-warning text-bold">{{ (velocityState | async).Warning }}</span>
  <span class="text-danger text-bold">{{ (velocityState | async).Danger }}</span>-->
    </span>
    <strong> {{ device.Name }}</strong>
    <span *ngIf="app.isTester | async"> - #{{ device.Id }} </span>
    <div class="card-tools">
      <button class="btn btn-tool"
              type="button"
              data-toggle="tooltip"
              data-placement="top"
              title="Request contact"
              (click)="onSendRequest(device.Id)">
        <i class="fas fa-phone-square-alt text-darkred fa-2x"></i>
        <i class="fas fa-chalkboard-teacher text-darkred fa-2x"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <tabset>
      <tab #tabA="tab"
           (selectTab)="onSelect('86')">
        <ng-template tabHeading>
          Velocity
          <span *ngIf="(velocityState | async) && app.isNormalState">
            <span class="mr-1 border border-success rounded">
              <span class="p-1 text-success">{{ (velocityState | async).Success }}</span>
            </span>
            <span class="mr-1 border border-warning rounded">
              <span class="p-1 text-warning">{{ (velocityState | async).Warning }}</span>
            </span>
            <span class="mr-1 border border-danger rounded">
              <span class="p-1 text-danger">{{ (velocityState | async).Danger }}</span>
            </span>
            <!--<span class="text-success text-bold">{{ (velocityState | async).Success }}</span>
            <span class="text-warning text-bold">{{ (velocityState | async).Warning }}</span>
            <span class="text-danger text-bold">{{ (velocityState | async).Danger }}</span>-->
          </span>
        </ng-template>
        <app-device-last [device]="device"
                         [unit]="'86'"
                         (selected)="selectedChange($event)"
                         (change)="onChangeVelocity($event)"></app-device-last>
      </tab>

      <tab *ngIf="app.isZmp | async"
           #tabB="tab"
           (selectTab)="onSelect('65')">
        <ng-template tabHeading>
          Acceleration
          <span *ngIf="(accelerationState | async) && app.isNormalState">
            <span class="mr-1 border border-success rounded">
              <span class="p-1 text-success">{{ (accelerationState | async).Success }}</span>
            </span>
            <span class="mr-1 border border-warning rounded">
              <span class="p-1 text-warning">{{ (accelerationState | async).Warning }}</span>
            </span>
            <span class="mr-1 border border-danger rounded">
              <span class="p-1 text-danger">{{ (accelerationState | async).Danger }}</span>
            </span>
            <!--<span class="text-success text-bold">{{ (accelerationState | async).Success }}</span>
            <span class="text-warning text-bold">{{ (accelerationState | async).Warning }}</span>
            <span class="text-danger text-bold">{{ (accelerationState | async).Danger }}</span>-->
          </span>
        </ng-template>
        <app-device-last [device]="device"
                         [unit]="'65'"
                         (selected)="selectedChange($event)"
                         (change)="onChangeAcceleration($event)"></app-device-last>
      </tab>
    </tabset>

  </div>
</div>
<div class="card"
     *ngIf="(selectedData | async) && app.isNormalState">
  <div class="card-body">
    <app-rms-chart [selectedData]="selectedData | async"
                   [limits]="selectedLimits | async"
                   (chartSelect)="onChartSelect($event)"></app-rms-chart>
  </div>
</div>
<!-- <br *ngIf="isRmsChart" /> -->
<div class="card"
     *ngIf="isTested">
  <div class="card-body">
    <div class="form-row">
      <div class="input-group col-md-4  mb-2">
        <div class="input-group-prepend">
          <span class="input-group-text"
                style="width: 60px;">No.:</span>
        </div>
        <div class="form-control text-center">
          <span *ngIf='selectedData | async'>{{ (selectedData | async).SensorNumer }}</span>
        </div>
      </div>
      <div class="input-group col-md-4 mb-2">
        <div class="input-group-prepend">
          <span class="input-group-text"
                style="width: 60px;">Last:</span>
        </div>
        <div class="form-control text-center">
          <span *ngIf='selectedData | async'>{{ (selectedData | async).SendDate | date:'yyyy-MM-dd / HH:mm' }}</span>
        </div>
      </div>

      <div class="input-group col-md-4  mb-2">
        <div class="input-group-prepend">
          <span class="input-group-text"
                style="width: 60px;">Temp:</span>
        </div>
        <div class="form-control text-center">
          <span *ngIf='selectedData | async'>{{ (selectedData | async).Temp | number: '1.1-1' }} &#8451;</span>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="input-group col-sm-4  mb-2">
        <div class="input-group-prepend">
          <span class="input-group-text"
                style="width: 60px;">Unit:</span>
        </div>
        <div class="form-control text-center"><span *ngIf='selectedData | async'>{{ Unit }}</span></div>
      </div>

      <div class="input-group col-sm-4  mb-2">
        <div class="input-group-prepend">
          <span class="input-group-text"
                style="width: 60px;">Freq:</span>
        </div>
        <div class="form-control text-center">
          <span *ngIf='selectedData | async'>{{ (selectedData | async).FftFrequency }}</span>
        </div>
      </div>
      <div class="input-group col-sm-4  mb-2">
        <div class="input-group-prepend">
          <span class="input-group-text"
                style="width: 60px;">Error:</span>
        </div>
        <div class="form-control text-center">
          <span *ngIf='selectedData | async'>{{ (selectedData | async).ErrorCount }}</span>
        </div>
      </div>

    </div>
    <div class="form-row">

      <div class="input-group col-sm-4  mb-2">
        <div class="input-group-prepend">
          <span class="input-group-text"
                style="width: 60px;">Axis:</span>
        </div>
        <div class="form-control text-center"><span *ngIf='selectedData | async'>{{ Axis }}</span></div>
      </div>

      <div class="input-group col-sm-4  mb-2">
        <div class="input-group-prepend">
          <span class="input-group-text"
                style="width: 60px;">Lines:</span>
        </div>
        <div class="form-control text-center">
          <span *ngIf='selectedData | async'>{{ (selectedData | async).FftLines }}</span>
        </div>
      </div>

      <div class="input-group col-sm-4  mb-2">
        <div class="input-group-prepend">
          <span class="input-group-text"
                style="width: 60px;">RMS:</span>
        </div>
        <div class="form-control text-center">
          <span *ngIf='selectedData | async'>{{ (selectedData | async).Value | number: '1.2-2' }}</span>
        </div>
      </div>

    </div>
  </div>
</div>
<div class="card"
     *ngIf="isTested">
  <div class="card-body">
    <ejs-chart id='chart-container'
               [primaryXAxis]='xAxis'
               [primaryYAxis]='yAxis'
               [title]='chartTitle'
               [legendSettings]='legend'
               [zoomSettings]='zoom'
               [tooltip]='tooltipSettings'>
      <e-series-collection>
        <e-series type='Line'
                  [dataSource]='data'
                  xName='frq'
                  labelFormat="n1"
                  yName='amp'
                  name='FFT'></e-series>
      </e-series-collection>
    </ejs-chart>
  </div>
</div>
<div *ngIf="isTested && (selectedData | async)">
  <div class="card">
    <div class="card-header"
         data-toggle="collapse"
         data-target="#fftDivData"
         aria-expanded="false"
         aria-controls="collapseExample">
      FFT data
    </div>
    <div id="fftDivData" class="collapse card-body">
      <i class="far fa-copy float-right fa-2x" [cdkCopyToClipboard]="copyFft()"></i>
      {{ (selectedData | async).ListFft }}
    </div>
  </div>
</div>
<br />
<div class="card"
     *ngIf="isTested">
  <div class="card-body">
    <ejs-chart id='chart-twf-container'
               [primaryXAxis]='xAxisTwf'
               [primaryYAxis]='yAxisTwf'
               [title]='chartTitleTwf'
               [legendSettings]='legend'
               [zoomSettings]='zoom'
               [tooltip]='tooltipSettings'>
      <e-series-collection>
        <e-series type='Line'
                  [dataSource]='twf'
                  labelFormat="n1"
                  xName='frq'
                  yName='amp'
                  name='Twf'></e-series>
      </e-series-collection>
    </ejs-chart>

  </div>
</div>

<div *ngIf="isTested && (selectedData | async)">
  <div class="card">
    <div class="card-header"
         data-toggle="collapse"
         data-target="#twfDivData"
         aria-expanded="false"
         aria-controls="collapseExample">
      TWF data
    </div>
    <div id="twfDivData" class="card-body collapse">
      <i class="far fa-copy  float-right fa-2x" [cdkCopyToClipboard]="copyTwf()"></i>
      {{ (selectedData | async).ListTwf }}
    </div>
  </div>
</div>
<br />
<br />
<div class="row"
     *ngIf="isTested">
  <div class="col-6 mb-2">
    <div class="card">
      <div class="card-header"
           data-toggle="collapse"
           data-target="#statusDiv"
           aria-expanded="false"
           aria-controls="collapseExample">
        Status
      </div>
      <div id="statusDiv" class="collapse card-body">
        <pre> {{ sLog | json }}</pre>
      </div>
    </div>
  </div>
  <div class="col-6 mb-2">
    <!--<div class="card">
      <div class="card-header">
        Zmiana
      </div>
      <div class="card-body">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">No.:</span>
          </div>
          <div class="form-control text-center">
            <span *ngIf='selectedData | async'>{{ (selectedData | async).SensorNumer }}</span>
          </div>
        </div>
      </div>
    </div>-->
  </div>
  <div class="col-6 mb-2">
    <div class="card">
      <div class="card-header"
           data-toggle="collapse"
           data-target=".config-sensor"
           aria-expanded="false"
           aria-controls="collapseExample">
        Konfiguracja w bazie
      </div>
      <div class="collapse card-body config-sensor">
        <pre>  {{ ConfigData | json }}</pre>
      </div>
    </div>
  </div>
  <div class="col-6 mb-2">
    <div class="card">
      <div class="card-header"
           data-toggle="collapse"
           data-target=".config-sensor"
           aria-expanded="false"
           aria-controls="collapseExample">
        Konfiguracja na czujniku
      </div>
      <div  class="collapse card-body config-sensor">
        <pre> {{ ConfigDataRaw | json }} </pre>
      </div>
    </div>
  </div>
</div>

<div class="row"
     *ngIf="isTested">
  <div class="col-12">
    <div class="card">
      <div class="card-header"
           data-toggle="collapse"
           data-target="#viewData"
           aria-expanded="false"
           aria-controls="collapseExample">
        <i class="fas fa-database"></i> Data
      </div>
      <div id="viewData"
           class="collapse card-body">
        <pre>{{ (selectedData | async) | json }}</pre>
      </div>
    </div>
  </div>
</div>
