import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Directive({
  selector: '[parentName]'
})
export class ParentNameDirective {

  @Input() parents: [];
  @Input() id: number;  
  @Input('textContent') parentName: string;
  @Output() sort = new EventEmitter<string>();
  constructor() {
    parent
   }

}
