import { Component } from '@angular/core';
import { BaseItemsComponent } from '../../shared/base-items/base-items.component';
import { Router, ActivatedRoute } from '@angular/router';
import { KindConfig } from '../../shared/kind-config.model';
import { DeviceKindService } from '../../services/device/device-kind.service';
import { AuthorizeService } from 'src/api-authorization/authorize.service';

@Component({
  selector: 'app-device-kinds',
  templateUrl: './device-kinds.component.html',
  styles: []
})
export class DeviceKindsComponent extends BaseItemsComponent<KindConfig> {

  constructor(service: DeviceKindService, route: ActivatedRoute, router: Router,
    auth: AuthorizeService) {
    super(KindConfig, service, route, router,auth);
    this.property = [
      { PropertyName: "Name", Display: "Name" },
      { PropertyName: "Grange", Display: "Grange" }
    ];
  }
}
