import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MeasuringPoint } from 'src/app/shared/measuring-point.model';
import { StateEnume } from 'src/app/shared/enum/state-enum';
import { PointService } from 'src/app/services/point/point.service';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Config } from 'src/app/shared/config.model';
import { Device } from 'src/app/shared/device.model';
import { SelectService } from 'src/app/services/select.service';
import { HttpClient } from '@angular/common/http';
import { AlertService } from 'src/app/shared/_alert';
import { BehaviorSubject } from 'rxjs';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { map } from 'rxjs/operators';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-point',
  templateUrl: './point.component.html',
  styles: []
})
export class PointComponent implements OnInit {
  $item: MeasuringPoint;
  @Input()
  set item(val: MeasuringPoint) {
    this.$item = val;

    this.http.get(`rms/MeasuringPoints/getLimit/${this.item.SensorNumer}`).subscribe(p => {
      const ele = p;
      //this.genreDetails = p;
    });
  }
  get item() {
    return this.$item;
  }
  //@Input() state: StateEnume;

  @Output() hasChange = new EventEmitter<MeasuringPoint>();

  numberStatus = true;
  checkNumberStatus = true;
  numberInit: string;
  isEditNumber = false;
  sensorNumberDetails = "Sensor number valid.";
  chLimit: [];

  genreDetails: string;

  configs: Config[];
  devices: Device[];
  public isTester: BehaviorSubject<boolean | null> = new BehaviorSubject(null);

  constructor(private http: HttpClient, protected dd: SelectService, private service: PointService, private route: ActivatedRoute,
    private router: Router,
    auth: AuthorizeService, private alert: AlertService, public settings: SettingsService) {
    auth.getUser().pipe(map(u => u.role)).subscribe(roles => {
      if (roles) {
        // this.isAdminRot.next(roles.includes("AdminRot"));
        // this.isZmp.next(roles.includes("ZMP"));
        this.isTester.next(roles.includes("Tester"));
      } else {
        // this.isAdminRot.next(false);
        // this.isZmp.next(false);
        this.isTester.next(false);
      }
    });
  }

  ngOnInit() {
    this.settings.selectedPoint.subscribe(point => {
      if (point)
        this.item = point;
    });
    if (this.item && this.item.SensorNumer) {
      this.http.get<[]>(`rms/MeasuringPoints/chlimit/${this.item.SensorNumer}`).subscribe(chlimit => {
        console.log(chlimit);
        this.chLimit = chlimit;
      }, error => console.error(error));
    }
    this.dd.active<Config>('config', true)
      .subscribe(
        result => {
          this.configs = result;
        });
    this.dd.active<Device>('device', true)
      .subscribe(
        result => {
          this.devices = result;
        });
    // if (!this.settings.state) {
    //   this.settings.state = StateEnume.Details;
    //   const id: number = +this.route.snapshot.paramMap.get('id');
    //   if (id && id !== 0) {
    //     this.service.get<MeasuringPoint>(id)
    //       .subscribe(
    //         result => {
    //           this.item = result;
    //           this.settings.state = StateEnume.Edit;
    //         });
    //   }
    // }
    if (this.item && this.item.SensorNumer) {
      this.numberInit = this.item.SensorNumer;
    }

  }

  get isDetails() {
    return this.settings.state === StateEnume.Details;
  }

  noSensChanged(newObj) {
    if (this.numberInit !== newObj)
      this.numberStatus = false;
    else
      this.numberStatus = true;
    console.log(newObj);
  }

  editNumber(obj) {
    if (obj === 1) {
      //Edit Number
      this.isEditNumber = true;
      this.sensorNumberDetails = "";

    } else if (obj === 2) {
      //Cancel edit
      this.isEditNumber = false;
      this.item.SensorNumer = this.numberInit;
      this.numberStatus = true;
      this.sensorNumberDetails = "Sensor number valid.";

    } else if (obj === 3) {
      //save edit
      this.isEditNumber = false;
      if (this.item.SensorNumer !== "" && this.numberInit !== this.item.SensorNumer) {
        this.sensorNumberDetails = "Validation is in progress...";
        this.checkNumberStatus = true;
        this.http.get<boolean>(`rms/MeasuringPoints/chsno/${this.item.SensorNumer}`).subscribe(checkOk => {
          console.log(checkOk);
          if (checkOk) {
            this.numberStatus = true;
            this.sensorNumberDetails = "Sensor number valid.";
          } else {
            this.checkNumberStatus = false;
            this.numberStatus = false;
            this.sensorNumberDetails = "<span class='text-red'>Incorrect sensor number, no sensor in the database or change not allowed.</span>";
          }
        }, error => console.error(error));
      } else {
        this.isEditNumber = false;
        // this.item.SensorNumer = this.numberInit;
        this.numberStatus = true;
        this.sensorNumberDetails = "Sensor number valid.";
      }
    }
  }
  genreChanged(obj) {
    if (obj === 0) {
      this.genreDetails = "L: ; H: .";
      return;
    }

    this.genreDetails = this.chLimit.find(p => {
      if (p["Key"] === obj)
        return p;
    })["Value"];
  }


  submit(f: NgForm) {
    if (this.settings.state === StateEnume.New) {
      this.service.add<MeasuringPoint>(this.item)
        .subscribe(
          result => {
            // console.log(result);
            //this.config = result.json();
            this.settings.state = StateEnume.Details;
            //this.item = result;
            if (result) {
              this.hasChange.emit(result);
            }
            this.settings.dataChange.next(true);
            this.alert.success("New record saved.");
          },
          errorLog => console.error(errorLog));
    }
    if (this.settings.state === StateEnume.Edit || this.settings.state === StateEnume.Details) {
      this.service.update<MeasuringPoint>(this.item, this.item.Id)
        .subscribe(
          result => {
            console.log(result);
            //this.config = result.json();
            this.settings.state = StateEnume.Details;
            this.hasChange.emit(result);
            // this.item = result;
            this.settings.dataChange.next(true);

            this.alert.success("Edited record saved.");
          },
          errorLog => console.error(errorLog));
    }
  }

}
