import { Component, OnInit } from '@angular/core';
import { PointService } from '../../services/point/point.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MeasuringPoint } from '../../shared/measuring-point.model';
import { BaseItemsComponent } from '../../shared/base-items/base-items.component';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { SettingsService } from 'src/app/services/settings.service';
import { StateEnume } from 'src/app/shared/enum/state-enum';

@Component({
  selector: 'app-points',
  templateUrl: './points.component.html',
  styles: []
})
export class PointsComponent extends BaseItemsComponent<MeasuringPoint> {

  constructor(service: PointService, route: ActivatedRoute, router: Router,
    auth: AuthorizeService, public settings: SettingsService) {
    super(MeasuringPoint, service, route, router, auth);

    this.IsDataSources = true;
    service.getAll<MeasuringPoint>()
      .subscribe(
        result => {
          //this.DataSources.next(result);
          settings.points = result;
        });
        settings.orderPoints.subscribe(p => {
          if (p!=null) {
            this.DataSources.next(p);
          }
        });

    this.property = [
      { PropertyName: "IdDevice", Display: "Device" },
      { PropertyName: "Name", Display: "Name" },
      { PropertyName: "SensorNumer", Display: "Sensor No." },
      { PropertyName: "IdConfig", Display: "Config" }
    ];
    this.selectedEvent.subscribe(item => {

      settings.selectedPoint.next(item);
    });
    settings.active.next("point");
    if (settings.selectedDevice.value) {
      this.filter = settings.selectedDevice.value.Id;
      this.filterPropert = "IdDevice";

    } else {
      this.filter = null;
      this.filterPropert = null;
    }
    if (settings.selectedPoint.value) {
      this.hasChange(settings.selectedPoint.value)
    }
    settings.dataChange.subscribe(flg => {
      if (flg == true) {

        service.getAll<MeasuringPoint>()
          .subscribe(
            result => {
              this.DataSources.next(result);
              settings.points = result;
            });
            settings.dataChange.next(false);
      }
    });
  }


  addPoint() {

    this.selectedItem = new MeasuringPoint();

    this.items.splice(0, 0, this.selectedItem);
    //this.configs.push(this.selectedConfig);
    this.settings.state = StateEnume.New;
    this.settings.selectedPoint.next(this.selectedItem);
  }
}
