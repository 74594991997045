import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { AppService } from "src/app/services/app.service";
import { Device } from "src/app/shared/device.model";
import { AxisEnume } from "src/app/shared/enum/axis-enum";
import { TypeDevice } from "../../shared/type-device.model";
import { Row } from "./row.model";
import { Limit } from "../../../shared/limit.model";
import { PeriodEnum } from "../../../shared/enum/period-enume";
import { formatDate } from "@angular/common";
import { ControlState } from "../../../shared/interface/control-state";
import { RmsData } from "../../../shared/rms-data.model";
import { Config } from "../../../shared/config.model";
import { MeasuringPoint } from "../../../shared/measuring-point.model";
import { Observable, of, Subject, BehaviorSubject } from "rxjs";

import {
  debounceTime, distinctUntilChanged, switchMap, map, tap
} from 'rxjs/operators';
import { GongService } from "../../../services/gong.service";
import { NewsEnum } from "../../../shared/enum/news-enum";
import { ChartNews } from "../../../shared/chart-news.model";

@Component({
  selector: "dev-row",
  templateUrl: "./row.component.html",
  styles: [],
})
export class RowViewComponent implements OnInit {
  @Input()
  limit: Limit[];

  @Input()
  type: TypeDevice;

  @Input()
  unit: string;

  $row: MeasuringPoint | Row;
  @Input()
  set row(r) {
    if (this.$row !== r) {
      this.$row = r;
      if (this.type === TypeDevice.Default) {
        this.http.get<Limit[]>(`rms/limits/${r.Id}`, { withCredentials: true }).subscribe(limits => {
          this.limit = limits;
        });

        let dt = new Date();
        let url = `rms/rms/${this.$row.Id}/${PeriodEnum.Last}/${AxisEnume.All}/${formatDate(dt, 'yyyy-MM-dd', 'en-US')
          }/${this.unit}`;
        this.rowUrl.next(url);
      }
    }
  }

  get row() {
    return this.$row;
  }

  @Input()
  device: Device;


  @Output() change = new EventEmitter<ControlState>();
  @Output() selected = new EventEmitter();

  axes: AxisEnume[] = Object.keys(AxisEnume).filter((key) => key !== "All" && isNaN(+key)).map((type) => AxisEnume[type]);


  data$: Observable<RmsData[]>;
  private rowUrl = new BehaviorSubject<string>("");

  public news: BehaviorSubject<ChartNews[] | null> = new BehaviorSubject(null);

  constructor(public app: AppService, private http: HttpClient, private gong: GongService) {}

  ngOnInit(): void {
    if (this.type === TypeDevice.Default) {
      this.data$ = this.rowUrl.pipe(
        //// wait 300ms after each keystroke before considering the term
        //debounceTime(300),

        //// ignore new term if same as previous term
        //distinctUntilChanged(),

        // switch to new search observable each time the term changes
        switchMap((url: string) => this.getRmsData(url))
      );
    }
  }


  getRmsData(url: string): Observable<RmsData[]> {
    if (!this.$row || !this.unit)
      return of([]);

    return this.http.get<RmsData[]>(url, { withCredentials: true });
  }

  selectedChange(data) {
    this.selected.emit(data);
  }

  onChange(data: ControlState) {
    this.change.emit(data);
  }

  ifDevDefault(){
    return this.type === TypeDevice.Default;
  }

  ifDevBasic(){
    return this.type === TypeDevice.BasicAlert;
  }

  ifDevCustom() {
    return this.type === TypeDevice.CustomAlert;
  }
  ifAlertView() {
    return this.type === TypeDevice.AlertView;
  }
  ifWarningView() {
    return this.type === TypeDevice.WarningView;
  }
  ifAOrW() {
    return this.ifAlertView() || this.ifWarningView();
  }
}
