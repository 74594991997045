import { Component, Directive, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { BaseItemsComponent } from '../../shared/base-items/base-items.component';
import { Area } from '../../shared/area.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AreaService } from '../../services/area/area.service';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { PropertyDisplay } from 'src/app/shared/interface/property-display';
import { Podmiot } from 'src/app/shared/podmiot.model';
import { SelectService } from 'src/app/services/select.service';
import { BehaviorSubject } from 'rxjs';
import { SettingsService } from 'src/app/services/settings.service';
import { StateEnume } from 'src/app/shared/enum/state-enum';

@Component({
  selector: 'app-areas',
  templateUrl: './areas.component.html',
  styles: []
})
export class AreasComponent extends BaseItemsComponent<Area> {

  constructor(service: AreaService, route: ActivatedRoute, router: Router,
    auth: AuthorizeService, public settings: SettingsService) {
    super(Area, service, route, router, auth);

    this.IsDataSources = true;
    service.getAll<Area>()
      .subscribe(
        result => {
          this.DataSources.next(result);
          settings.areas = result;
        });

    this.property = [
      { PropertyName: "PodmiotId", Display: "Client" },
      { PropertyName: "Name", Display: "Name" }
    ];

    this.selectedEvent.subscribe(item => {
      if (settings.selectedArea.value && settings.selectedArea.value.Id != item.Id) {
        if (settings.selectedDevice.value)
          settings.selectedDevice.next(null);

        if (settings.selectedPoint.value)
          settings.selectedPoint.next(null);

        if (settings.selectedSensor.value)
          settings.selectedSensor.next(null);
      }
      settings.selectedArea.next(item);
      //settings.selectedIdPodmiot.next(item.PodmiotId);

    });

    settings.active.next("area");
    if (settings.selectedArea.value) {
      this.hasChange(settings.selectedArea.value)
    }

    settings.dataChange.subscribe(flg => {
      if (flg == true) {

        service.getAll<Area>()
          .subscribe(
            result => {
              this.DataSources.next(result);
              settings.areas = result;
            });
            settings.dataChange.next(false);
      }
    });
  }
  addArea() {

    this.selectedItem = new Area();

    this.items.splice(0, 0, this.selectedItem);
    //this.configs.push(this.selectedConfig);
    this.settings.state = StateEnume.New;
    this.settings.selectedArea.next(this.selectedItem);
  }

}
