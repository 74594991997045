import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseItemsComponent } from '../../shared/base-items/base-items.component';
import { Sensor } from '../../shared/sensor.model';
import { SensorService } from '../../services/sensor/sensor.service';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { SettingsService } from 'src/app/services/settings.service';
import { StateEnume } from 'src/app/shared/enum/state-enum';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-sensors',
  templateUrl: './sensors.component.html',
  styles: []
})
export class SensorsComponent extends BaseItemsComponent<Sensor> {
  color: ThemePalette = "warn";
  constructor(service: SensorService, route: ActivatedRoute, router: Router,
    auth: AuthorizeService, public settings: SettingsService) {
    super(Sensor, service, route, router, auth);

    this.IsDataSources = true;
    service.getAll<Sensor>()
      .subscribe(
        result => {
          // this.DataSources.next(result);
          settings.sensors = result;
        });

    settings.orderSensors.subscribe(p => {
      if (p != null) {
        this.DataSources.next(p.filter(p => p.Archival == false || p.Archival == this.settings.archival));
      }
    });
    this.property = [
      { PropertyName: "Number", Display: "Number" },
      { PropertyName: "IdPodmiot", Display: "Podmiot" },
      { PropertyName: "Tag", Display: "Tag" }
    ];
    this.selectedEvent.subscribe(item => {
      settings.selectedSensor.next(item);
    });

    settings.active.next("sensor");
    // if (settings.selectedIdPodmiot.value) {
    //   this.filter = settings.selectedIdPodmiot.value;
    //   this.filterPropert = "IdPodmiot";

    // } else {
    //   this.filter = null;
    //   this.filterPropert = null;
    // }
    if (settings.selectedSensor.value) {
      this.hasChange(settings.selectedSensor.value)
    }
    settings.dataChange.subscribe(flg => {
      if (flg == true) {

        service.getAll<Sensor>()
          .subscribe(
            result => {
              this.DataSources.next(result.filter(p => p.Archival == false || p.Archival == this.settings.archival));
              settings.sensors = result;
            });

        settings.dataChange.next(false);
      }
    });
    settings.filterChange.subscribe(flg => {
      if (flg == true) {
        settings.sensors =  settings.sensors;
        settings.filterChange.next(false);
      }
    });
  }

  addSensor() {

    this.selectedItem = new Sensor();

    this.items.splice(0, 0, this.selectedItem);
    //this.configs.push(this.selectedConfig);
    this.settings.state = StateEnume.New;
    this.settings.selectedSensor.next(this.selectedItem);
  }
}

