import { Component } from '@angular/core';
import { Device } from '../../shared/device.model';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceService } from '../../services/device/device.service';
import { BaseItemsComponent } from '../../shared/base-items/base-items.component';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { SettingsService } from 'src/app/services/settings.service';
import { StateEnume } from 'src/app/shared/enum/state-enum';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styles: []
})
export class DevicesComponent extends BaseItemsComponent<Device> {

  constructor(service: DeviceService, route: ActivatedRoute, router: Router,
    auth: AuthorizeService, public settings: SettingsService) {
    super(Device, service, route, router, auth);

    this.IsDataSources = true;
    service.getAll<Device>()
      .subscribe(
        result => {
          // this.DataSources.next(result);
          settings.devices = result;
        });
    settings.orderDevices.subscribe(p => {
      if (p!=null) {
        this.DataSources.next(p);
      }
    });

    this.property = [
      { PropertyName: "IdArea", Display: "Area" },
      { PropertyName: "Name", Display: "Name" }
    ];
    this.selectedEvent.subscribe(item => {
      if (settings.selectedDevice.value && settings.selectedDevice.value.Id != item.Id) {
        if (settings.selectedPoint.value)
          settings.selectedPoint.next(null);
      }
      settings.selectedDevice.next(item);
    });
    settings.active.next("device");

    // settings.selectedArea.subscribe(area => {
    //   if (area) {
    //     this.filter = area.Id;
    //     this.filterPropert = "IdArea";
    //   } else {
    //     this.filter = null;
    //     this.filterPropert = null;
    //   }
    // });
    if (settings.selectedArea.value) {
      this.filter = settings.selectedArea.value.Id;
      this.filterPropert = "IdArea";

    } else {
      this.filter = null;
      this.filterPropert = null;
    }
    if (settings.selectedDevice.value) {
      this.hasChange(settings.selectedDevice.value)
    }

    settings.dataChange.subscribe(flg => {
      if (flg == true) {

        service.getAll<Device>()
          .subscribe(
            result => {
              this.DataSources.next(result);
              settings.devices = result;
            });
            settings.dataChange.next(false);
      }
    });
  }
  addDevice() {

    this.selectedItem = new Device();

    this.items.splice(0, 0, this.selectedItem);
    //this.configs.push(this.selectedConfig);
    this.settings.state = StateEnume.New;
    this.settings.selectedDevice.next(this.selectedItem);
  }
}
