<div class="modal-header">
  <h4 class="modal-title">
    <i class="fas fa-exchange-alt"></i> <strong> {{ title }}</strong>
  </h4>
  <span type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </span>
</div>
<div class="modal-body">
  <form #f="ngForm">
    <div class="form-group">
      <mat-form-field class="example-full-width"
                      appearance="fill">
        <mat-label>Current sensor No.</mat-label>
        <input type="text"
               aria-label="Number"
               matInput
               [formControl]="oldSensor">
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field class="example-full-width "
                      appearance="fill">
        <mat-label>New sensor No.</mat-label>
        <span matPrefix
        [class.text-danger]="(isWarning | async)=='!'"
        [class.text-success]="(isWarning | async)=='ok'"

        mat-raised-button
        [matTooltip]="toolTipMsg"
        [matTooltipClass]="toolTipClass"

        >{{ isWarning | async }} &nbsp;</span>
        <input type="text"
               placeholder="Pick one"
               aria-label="Number"
               matInput
               [formControl]="newSensor"
               [matAutocomplete]="auto">

        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let option of filteredOptions | async"
                      [value]="option.SensorNumer">
                      <i [class]="getIcone(option.From)"></i> &nbsp;
            <span>{{option.SensorNumer }}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>


        <!-- <mat-progress-spinner class="example-size"
                              *ngIf="load"
                              mode="indeterminate">
        </mat-progress-spinner> -->
      <!-- <small id="SensorNumerHelp"
             class="form-text text-muted"
             [innerHTML]="sensorNumberDetails">
      </small> -->
    </div>
  </form>


</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('Cross click')">Close</button>
  <button type="button" class="btn btn-primary" (click)="SaveChange()">Save changes</button>
</div>
