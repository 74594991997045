<!-- <ul class="navbar-nav" *ngIf="isAuthenticated | async">
    <li class="nav-item">
        <a  class="nav-link text-dark" [routerLink]='["/authentication/profile"]' title="Manage">Hello {{ userName | async }}</a>
    </li>
    <li class="nav-item">
        <a  class="nav-link text-dark" [routerLink]='["/authentication/logout"]' [state]='{ local: true }' title="Logout">Logout</a>
    </li>
</ul> -->
<!-- <ul class="navbar-nav" *ngIf="!(isAuthenticated | async)">
  <li class="nav-item">
        <a class="nav-link text-dark" [routerLink]='["/authentication/register"]'>Register</a>
    </li>
    <li class="nav-item">
        <a class="nav-link text-dark" [routerLink]='["/authentication/login"]'>Login</a>
    </li>
</ul> -->
    <a *ngIf="isAuthenticated | async" href="#"
       class="nav-link dropdown-toggle"
       data-toggle="dropdown"
       role="button"
       aria-haspopup="true"
       aria-expanded="false"
       style="border: none;">
      <img src="assets/img/user.png"
           class="user-image"
           alt="User Image">
      <span class="d-none">{{ userName | async }}</span>
    </a>
    <ul *ngIf="isAuthenticated | async" class="dropdown-menu dropdown-menu-right">
      <!-- User image -->
      <li class="user-header">
        <img src="assets/img/user.png"
             class="img-circle"
             alt="User Image">

        <p>
          {{ userName | async }}
        </p>
      </li>
      <!-- Menu Footer-->
      <li class="user-footer">
        <div class="float-left">
          <span class="btn btn-default btn-flat" (click)="clickPreferences()">Preferences</span>
        </div>
        <div class="float-right">
          <a [routerLink]='["/authentication/logout"]' [state]='{ local: true }'
             class="btn btn-default btn-flat">Sign out</a>
        </div>
      </li>
    </ul>
