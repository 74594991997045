<br />
<div *ngIf="(app.selectedArea | async).Id == 3 || (app.selectedArea | async).Id == 6" class="alert alert-danger" role="alert">
  W związku z wygaśnięciem umowy działanie systemu pomiarowego zostało zakończone.
  <br />
  Dane archiwalne są nadal dostępne.
</div>
<app-client-header [area]="app.selectedArea | async"></app-client-header>

<router-outlet>
</router-outlet>
