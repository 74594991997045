import { Component, Input, OnInit } from "@angular/core";
export enum SvgSize {
  S = 1,
  M = 2,
  L = 3
}
@Component({
  selector: "svg-signal",
  templateUrl: "./wifi_signal.svg",
  styleUrls: ["./signal.component.css"]
})
export class SignalComponent implements OnInit {
  @Input() strength: number;
  private $size: SvgSize = SvgSize.S;
  @Input() set size(size: SvgSize) {
    if (this.$size != size) {
      this.$size = size;
      switch (this.$size) {
        case SvgSize.S:
          this.width = 12;
          this.height = 6;
          break;
        case SvgSize.M:
          this.width = 2 * 12;
          this.height = 2 * 6;
          break;
        case SvgSize.L:
          this.width = 3 * 12;
          this.height = 3 * 6;
          break;

        default:
          this.width = 12;
          this.height = 6;
          break;
      }
    }
  }
  get size() {
    return this.$size;
  }
  fillColor = "rgb(255, 0, 0)";
  strokeColor = "";
  rectCount = 0;

  width = 12;
  height = 6;

  ngOnInit(): void {
    this.changeColor();
  }

  opacyty(id) {
    if (id > this.rectCount)
      return 0.3;
    return 1;
  }



  changeColor() {

    // const r = Math.floor(Math.random() * 256);
    // const g = Math.floor(Math.random() * 256);
    // const b = Math.floor(Math.random() * 256);
    // this.fillColor = `rgb(${r}, ${g}, ${b})`;
    if (this.strength > -65) {
      this.fillColor = "#569e26";
      this.strokeColor = "#569e26";
      this.rectCount = 4;
    }
    else if (this.strength > -76) {
      this.fillColor = "#efc411";
      this.strokeColor = "#569e26";
      this.rectCount = 3;
    }
    else if (this.strength > -86) {
      this.fillColor = "#b52808";
      this.strokeColor = "#569e26";
      this.rectCount = 2;
    }
    else {
      this.fillColor = "#848484";
      this.strokeColor = "#569e26";
      this.rectCount = 1;
    }
  }
}
