import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Device } from 'src/app/shared/device.model';
import { AxisEnume, AxisEnumeDisplay } from 'src/app/shared/enum/axis-enum';
import { NewsEnum } from 'src/app/shared/enum/news-enum';
import { AlertService } from 'src/app/shared/_alert';
import { Notify } from '../../shared/notify.model';
import { DeviceViewService } from '../device-view.service';
import { Row } from '../row/row.model';
import { MeasuringPoint } from "../../../shared/measuring-point.model";

@Component({
  selector: 'app-cell-button',
  templateUrl: './cell-button.component.html',
  styles: [
  ]
})
export class CellButtonComponent implements OnInit {
  $point: MeasuringPoint | Row;
  @Input()
  set point(val: MeasuringPoint | Row) {
    this.$point = val;
    if (this.point && this.point.Id) {
      let current = new Date(Date.now());
      this.http
        .get<Notify[]>(`rms/rms/notifys/${this.point.Id}`)
        .subscribe((noti) => {
          this.notify = noti;
          let temp = this.notify.find(p => p.Axis === this.axis && current > new Date(p.Start) && new Date(p.End) > current);
          if (temp) {
            this.lastState.next(temp.Genre);
          }
        });
    }
  }
  get point() {
    return this.$point;
  }

  @Input()
  axis: AxisEnume;

  @Input()
  device: Device;

  notify: Notify[];
  lastState: BehaviorSubject<NewsEnum | null> = new BehaviorSubject(
    NewsEnum.Lack
  );


  lstState: NewsEnum[] = [NewsEnum.Lack, NewsEnum.Warning, NewsEnum.Alert];

  constructor(private http: HttpClient,
    private alert: AlertService,
    public view: DeviceViewService) {

  }

  ngOnInit(): void {
  }

  cellClick(val: NewsEnum) {
    switch (val) {
      case NewsEnum.Lack:
        this.lastState.next(NewsEnum.Warning);
        break;
      case NewsEnum.Warning:
        this.lastState.next(NewsEnum.Alert);
        break;
      case NewsEnum.Alert:
        this.lastState.next(NewsEnum.Lack);
        break;
    }
    let notify = new Notify();
    notify.Axis = this.axis;
    notify.Genre = this.lastState.value;
    notify.IdPoint = this.$point.Id;
    let model = { Key: this.device.Id, Value: notify };
    this.view.add(model).subscribe(points => {
      // let current = new Date(Date.now());
      // this.http
      //   .get<Notify[]>(`rms/rms/notifys/${this.$point.Id}`)
      //   .subscribe((noti) => {
      //     this.notify = noti;
      //     let temp = this.notify.find(p => p.Axis === this.axis && current > new Date(p.Start) && new Date(p.End) > current);
      //     if (temp) {
      //       this.lastState.next(null);
      //       this.lastState.next(temp.Genre);
      //     }
      //   });
      //if (model.Value.Genre === NewsEnum.Alert)
      //  this.alert.error(`${this.$point.Name} - ${this.axis} mark as alert.`);
      //if (model.Value.Genre === NewsEnum.Warning)
      //  this.alert.warn(`${this.$point.Name} - ${this.axis} mark as warning.`);
      //if (model.Value.Genre === NewsEnum.Lack)
      //  this.alert.info(`${this.$point.Name} - ${this.axis} marked removed.`);

      if (model.Value.Genre === NewsEnum.Alert)
        this.alert.error(`${this.$point.Name} / ${AxisEnumeDisplay[this.axis]} : Alert notifications enabled`);
      if (model.Value.Genre === NewsEnum.Warning)
        this.alert.warn(`${this.$point.Name} / ${AxisEnumeDisplay[this.axis]} : Warning and Alert notifications enabled`);
      if (model.Value.Genre === NewsEnum.Lack)
        this.alert.info(`${this.$point.Name} / ${AxisEnumeDisplay[this.axis]} : All notifications disabled`);

    });
  }

  cssClass(val: NewsEnum) {
    if (!val) return;

    const classes = ["btn", "btn-block"];

    const alertTypeClass = {
      [NewsEnum.Lack]: "btn-light",
      [NewsEnum.Warning]: "btn-warning",
      [NewsEnum.Alert]: "btn-danger",
      [NewsEnum.Msg]: "btn-info",
    };

    classes.push(alertTypeClass[val]);

    return classes.join(" ");
  }
  cssClassIcon(val: NewsEnum) {
    if (!val) return;
    switch (val) {
      case NewsEnum.Lack:
        return "fas fa-minus";
      case NewsEnum.Alert:
        return "fas fa-check";
      case NewsEnum.Warning:
        return "fas fa-check";
    }
  }
}
