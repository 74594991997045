import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MeasuringPoint } from 'src/app/shared/measuring-point.model';
import { AlertService } from 'src/app/shared/_alert';

export class FreeSensor {
  Id: number;
  SensorNumer: string;
  From: string;
}
export function existsInListValidator(list: FreeSensor[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (list.length == 0) return null;
    const forbidden = !list.find(p => p.SensorNumer == control.value); // nameRe.test(control.value);
    return forbidden ? { existsInList: { value: control.value } } : null;
  };
}

@Component({
  selector: 'app-change-sensor-no',
  templateUrl: './change-sensor-no.component.html',
  styleUrls: ['./change-sensor-no.component.css']
})
export class ChangeSensorNoComponent implements OnInit {
  @Input()
  options: FreeSensor[] = [];
  @Input()
  SensorNumer: string;
  @Input()
  IdArea: number;
  @Input()
  PointId: number;

  newSensor = new FormControl();
  oldSensor = new FormControl();
  load: boolean = true;
  filteredOptions: Observable<FreeSensor[]>;
  title: string = "Changing the sensor";
  isWarning: Observable<string>;

  canSave: boolean = null;
  toolTipMsg = "";
  toolTipClass = "";


  sensorNumberDetails = "Sensor number valid.";
  constructor(public activeModal: NgbActiveModal, private http: HttpClient,
    private alert: AlertService) {

  }

  private _filterStates(value: string): FreeSensor[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(state => state.SensorNumer.toLowerCase().includes(filterValue));
  }


  ngOnInit(): void {
    //this.newSensor = new FormControl("", [
    //  Validators.required,
    //  existsInListValidator(this.options.slice())
    //]);
    this.newSensor.setValidators([
      Validators.required,
      existsInListValidator(this.options.slice())
    ]);
    this.filteredOptions = this.newSensor.valueChanges
      .pipe(
        startWith(''),
        map(state => state ? this._filterStates(state) : this.options.slice())
      );
    this.isWarning = this.newSensor.valueChanges.pipe(map(value => {
      if (this.newSensor.valid) {
        const filterValue = value.toLowerCase();
        if (this.options.filter(p => p.SensorNumer.toLowerCase() === filterValue && p.From !== "wolne").length === 1) {
          this.toolTipMsg = "Czujnik przypisany już do innego punktu pomiarowego!";
          this.toolTipClass = "tooltip-red";
          this.canSave = false;
          return "!";
        }
        this.canSave = true;
        this.toolTipClass = "tooltip-green";
        this.toolTipMsg = "Czujnik gotowy do zamiany!";
        return "ok";
      }

      this.toolTipMsg = "";
      return "";
    }));
    this.oldSensor.setValue(this.SensorNumer);
    this.oldSensor.disable();
  }
  SaveChange() {
    if (this.newSensor.valid) {
      if (this.canSave === false) {
        if (!confirm("Sensor in use!\nAre you sure?")) {
          return;
        }
      }
      //this.http.get<boolean>(`rms/MeasuringPoints/chsno/${this.newSensor.value}`).subscribe(checkOk => {
      //  console.log(checkOk);
      //  if (checkOk) {
        //  this.sensorNumberDetails = "Sensor number valid.";
          let model = new MeasuringPoint();
          model.Id = this.PointId;
          model.SensorNumer = this.newSensor.value;
          this.http.put(`rms/MeasuringPoints/point/${this.PointId}`, model).subscribe(p => {
            this.alert.info(`#${this.PointId} ${this.oldSensor.value} -> ${this.newSensor.value} : Sensor number change`, { autoClose: false});
            this.activeModal.dismiss();
          });
      //  } else {
      //    this.sensorNumberDetails = "<span class='text-red'>Incorrect sensor number, no sensor in the database or change not allowed.</span>";
      //  }
      //}, error => console.error(error));
    }

  }
  getIcone(from) {
    switch (from) {
      case "wolne ZMP":
        return 'far fa-money-bill-alt text-primary';
        break;
      case "wolne":
        return 'far fa-hand-pointer text-success';
        break;
      default:
        return 'fas fa-exclamation text-danger';
        break;
    }
  }


  // noSensChanged(newObj) {
  //   if (this.numberInit !== newObj)
  //     this.numberStatus = false;
  //   else
  //     this.numberStatus = true;
  //   console.log(newObj);
  // }

  // editNumber(obj) {
  //   if (obj === 1) {
  //     //Edit Number
  //     this.isEditNumber = true;
  //     this.sensorNumberDetails = "";

  //   } else if (obj === 2) {
  //     //Cancel edit
  //     this.isEditNumber = false;
  //     this.SensorNumer = this.numberInit;
  //     this.numberStatus = true;
  //     this.sensorNumberDetails = "Sensor number valid.";

  //   } else if (obj === 3) {
  //     //save edit
  //     this.isEditNumber = false;
  //     if (this.SensorNumer !=="" &&  this.numberInit !== this.SensorNumer) {
  //       this.sensorNumberDetails = "Validation is in progress...";
  //       this.checkNumberStatus = true;
  //       this.http.get<boolean>(`rms/MeasuringPoints/chsno/${this.SensorNumer}`).subscribe(checkOk => {
  //         console.log(checkOk);
  //         if (checkOk) {
  //           this.numberStatus = true;
  //           this.sensorNumberDetails = "Sensor number valid.";
  //         } else {
  //           this.checkNumberStatus = false;
  //           this.numberStatus = false;
  //           this.sensorNumberDetails = "<span class='text-red'>Incorrect sensor number, no sensor in the database or change not allowed.</span>";
  //         }
  //       }, error => console.error(error));
  //     } else {
  //       this.isEditNumber = false;
  //      // this.item.SensorNumer = this.numberInit;
  //       this.numberStatus = true;
  //       this.sensorNumberDetails = "Sensor number valid.";
  //     }
  //   }
  // }

}
