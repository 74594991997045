import { Injectable } from '@angular/core';
import { DataService } from '../data.service';
import { HttpClient } from '@angular/common/http';
import { AlertService } from 'src/app/shared/_alert';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService  extends DataService {

  constructor(http: HttpClient, alert: AlertService) {
    super('rms/connections', http, alert);
  }
}
