<button *ngIf="notify.length ==0"
        class="btn btn-light btn-block"
        (click)="open()">
  <i class="fas fa-minus"></i>
</button>
<button *ngIf="notify.length ==1"
        class="btn btn-outline-dark btn-block"
        (click)="open()">{{ notify[0].Value | number: '1.2-2'}}
  <i class="fas fa-level-up-alt ml-1"
     *ngIf="notify[0].Increase"></i>
  <i class="fas fa-level-down-alt ml-1"
     *ngIf="!(notify[0].Increase)"></i>
  <i *ngIf="(notify[0].IsRepeated)"
     class="fas fa-infinity ml-1"></i>
  <i *ngIf="!(notify[0].IsRepeated)"
     class="fas fa-asterisk"></i>
</button>
<button *ngIf="notify.length >1"
        class="btn btn-outline-dark btn-block"
        (click)="open()">
  <i class="fas fa-ellipsis-h"></i>
</button>
