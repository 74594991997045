<div class="row">
  <div class="col-md-12 col-lg-6 order-md-first order-lg-last mb-3"
       *ngIf="selectedItem">
    <app-critical [item]="selectedItem"
                              [state]="state">
    </app-critical>
  </div>
  <div class="col col-md-12 col-lg-6">
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th *ngFor="let prop of property"
                scope="col"
                [sortable]="prop.PropertyName"
                (sort)="onSort($event)">{{ prop.Display }}</th>
            <th scope="col">
              <button class="btn btn-success btn-sm pull-right"
                      (click)="createConfig()">
                <i class="fas fa-plus"></i>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let current of pagination; let i = index"
              [class.active]="i == selectedRow"
              (click)="setSelected(current, i)">
            <td scope="col"
                *ngIf="current.Id!=0">
              {{ current.Id }}
            </td>
            <td scope="col"
                *ngIf="current.Id==0"
                data-toggle="tooltip"
                data-placement="top"
                title="Save changes!">
              <i class="fas fa-exclamation text-danger"></i>
            </td>
            <td *ngFor="let prop of property"
                scope="col">
              {{ current[prop.PropertyName] }}
            </td>
            <td scope="col"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-between p-2"
         *ngIf="collectionSize > pageSize">
      <ngb-pagination [collectionSize]="collectionSize"
                      [(page)]="page"
                      [pageSize]="pageSize"
                      (pageChange)="refreshCountries()">
      </ngb-pagination>



      <select class="custom-select"
              style="width: auto"
              [(ngModel)]="pageSize"
              (ngModelChange)="refreshCountries()">
        <option *ngFor="let element of pageList"
                [ngValue]="element">{{ element }}</option>
      </select>
    </div>
  </div>
</div>
