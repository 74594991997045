import { Component, Input, OnInit } from "@angular/core";
import { ControlState } from "src/app/shared/interface/control-state";

@Component({
  selector: "app-logo",
  templateUrl: "./logo.component.svg",
  styleUrls: ["./logo.component.css"],
})
export class LogoComponent implements OnInit {
  @Input() success: boolean;
  @Input() warning: boolean;
  @Input() danger: boolean;

  $state: ControlState;
  max = 3;
  height1 = 10;
  height2 = 7;
  height3 = 15;
  y1 = 10;
  y2 = 13;
  y3 = 5;

  @Input()
  set state(value: ControlState) {
    if (value) {
      this.$state = value;

      this.max =
        this.$state.Success + this.$state.Warning + this.$state.Danger;
      if (this.max === 0) {
        this.max = 1;
      }

      this.height1 = Math.round((this.$state.Success / this.max) * 18) + 1;
      this.height2 = Math.round((this.$state.Warning / this.max) * 18) + 1;
      this.height3 = Math.round((this.$state.Danger / this.max) * 18) + 1;
      this.y1 = 25 - this.height1;
      this.y2 = 25 - this.height2;
      this.y3 = 25 - this.height3;

    }
  }

  get state(): ControlState {
    return this.$state;
  }

  constructor() {
    this.height1 = 10;
    this.height2 = 7;
    this.height3 = 15;
    this.y1 = 10;
    this.y2 = 13;
    this.y3 = 5;
  }

  ngOnInit(): void {
    //this.height1 = 10;
    //this.height2 = 7;
    //this.height3 = 15;
    //this.y1 = 10;
    //this.y2 = 13;
    //this.y3 = 5;
  }

  getOpacity(color) {
    switch (color) {
      case "green":
        return this.success === undefined || this.success ? 1 : 0.2;
      case "orange":
        return this.warning === undefined || this.warning ? 1 : 0.2;
      case "red":
        return this.danger === undefined || this.danger ? 1 : 0.2;
      default:
        return 1;
    }
  }
}
