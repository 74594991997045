
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { PagesPaths } from 'src/app/app.constants';
import { GongService } from 'src/app/services/gong.service';
import { Area } from 'src/app/shared/area.model';
import { NewsEnum } from 'src/app/shared/enum/news-enum';
import { PeriodEnum } from 'src/app/shared/enum/period-enume';
import { AppService } from "../../services/app.service";


@Component({
  selector: 'app-client-header',
  templateUrl: './client-header.component.html',
  styles: [
  ]
})
export class ClientHeaderComponent implements OnInit {
  $area: Area;
  @Input()
  set area(val: Area) {
    if (val && this.$area !== val) {
      this.$area = val;

      this.getWarnings();
      this.getMsg();
      this.getAlerts();
    }
  }
  get area(): Area {
    return this.$area;
  }

  dangerCounting: BehaviorSubject<number | null> = new BehaviorSubject(null);
  warningCounting: BehaviorSubject<number | null> = new BehaviorSubject(null);

  constructor(public app: AppService, private gong: GongService, private route: ActivatedRoute) { }

  get isActiveClient() {
    return this.app.ActivePage.value === PagesPaths.Client || this.app.ActivePage.value === PagesPaths.Supervision;
  }
  get isActiveWarning() {
    return this.app.ActivePage.value === PagesPaths.Warning;
  }
  get isActiveAlert() {
    return this.app.ActivePage.value === PagesPaths.Alert;
  }
  warningClick() {
    this.getMsg();
    this.getAlerts();
  }

  alertClick() {
    this.getWarnings();
    this.getMsg();
  }
  clientClick() {
    this.getWarnings();
    this.getMsg();
    this.getAlerts();
  }
  getWarnings() {
    this.gong.get(this.app.selectedAreaId.value, PeriodEnum.Last, NewsEnum.Warning).subscribe(msg => {
      this.app.warning.next(msg["Value"]);
      this.app.warningDt.next(msg["Key"]);
    });
  }
  getAlerts() {
    this.gong.get(this.app.selectedAreaId.value, PeriodEnum.Last, NewsEnum.Alert).subscribe(msg => {
      this.app.danger.next(msg["Value"]);
      this.app.dangerDt.next(msg["Key"]);
    });
  }
  getMsg() {
    this.gong.get(this.app.selectedAreaId.value, PeriodEnum.Last, NewsEnum.Msg).subscribe(msg => {
      this.app.msg.next(msg["Value"]);
      this.app.msgDt.next(msg["Key"]);
    });
  }
  ngOnInit(): void {
  }

  isInfinity(num: number) {
    //if (num === 0) {
    //  return "";
    //}
    if (num > 999) {
      return "∞";
    } else {
      return num;
    }
  }
}
