import { KeyValue } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Device } from 'src/app/shared/device.model';
import { AxisEnume } from 'src/app/shared/enum/axis-enum';
import { NewsEnum } from 'src/app/shared/enum/news-enum';
import { MeasuringPoint } from 'src/app/shared/measuring-point.model';
import { AlertService } from 'src/app/shared/_alert';
import { Notify } from '../shared/notify.model';

@Injectable({
  providedIn: 'root'
})
export class DeviceViewService {
  httpOptions =
  {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true
  };

  isTester: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(private http: HttpClient, private alert: AlertService) { }

  get(areaId: number): Observable<Notify> {
    return this.http.get<Notify>(`profile/notify/area/${areaId}`, this.httpOptions);
  }

  add(resource: { Key: number, Value: Notify }): Observable<MeasuringPoint[]> {
    return this.http.post<MeasuringPoint[]>("profile/notify", resource, this.httpOptions);
  }
}
