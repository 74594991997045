import { Component, OnInit } from "@angular/core";
import { AppService } from "../services/app.service";
import { PeriodEnum } from "../shared/enum/period-enume";
import { StateEnume } from "../shared/enum/state-enum";
import { PagesPaths } from "../app.constants";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
lastPeriod =PeriodEnum.Last;
  constructor(public app: AppService) {
    app.currentPage.next("area");
    // app.selectedAreaId.next(null);
    // app.selectedPodmiotId.next(null);
    //app.selectedArea.next(null);
    app.ActivePage.next(PagesPaths.Client);
    app.state.next(StateEnume.Details);
  }

  ngOnInit() {
  }
}
