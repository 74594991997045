import { IdBase } from "./ID.base";

export class Sensor implements IdBase {
  Id: number;
  Number: string;
  Tag: string;
  //Equal: boolean;
  IdPodmiot: number;
  Archival: boolean;
  // public virtual ICollection<SensorLog> Logs : number;
  // public virtual ICollection<HistPointSensor> HistPointSensor : number;
  GetEditUrl: string;

  constructor() {

    this.GetEditUrl = 'sensor/edit';
  }


}
