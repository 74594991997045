
import { Device } from "./device.model";
import { RmsData } from "./rms-data.model";
import { Config } from "./config.model";
import { IdBase } from "./ID.base";
import { AxisEnume } from "./enum/axis-enum";
import { LimitGenre } from "./enum/limit-genre";
import { Limit } from "./limit.model";
import { Notify } from "../profile/shared/notify.model";
import { NewsEnum } from "./enum/news-enum";
import { Row } from "../profile/device/row/row.model";

export class MeasuringPoint implements IdBase, Row {
  Id: number;
  IdDevice: number;
  SensorNumer: string;
  IdConfig: number;
  Name: string;
  Horizontal: AxisEnume;
  Axial: AxisEnume;
  Genre: LimitGenre;
  Visible: Boolean = true;


  Config: Config;
  Device: Device;

  // Errors: Error[];
  Data: RmsData[];
  Limits: Limit[];

  UserNotifies: Notify[];

  private _temp: number;
  get Temp() {
    return this._temp;
  }
  set Temp(temp) {
    this._temp = temp;
  }

  GetEditUrl: string;
  // HistPointSensor: HistPointSensor[];
  constructor() {
    this.Id = 0;
    this.IdDevice = 0;
    this.IdConfig = 0;
    this.SensorNumer = '';
    this.Data = [new RmsData(), new RmsData(), new RmsData()];
    // for (let index = 0; index < 5; index++) {
    //     var ele = new RmsData();
    //     ele.Id = index;
    //     ele.SensorNumer = (index * Math.random() * 90000).toString();
    //     ele.Value = (index * Math.random() * 20);
    //     ele.Value = ( Math.random() * 20);
    //     ele.ErrorCount = ( Math.random() * 30);


    //     this.Data.push(ele);
    // }
    this.GetEditUrl = 'point/edit';
  }

  get TempStyle() {
    return "btn btn-outline-secondary btn-block";
  }

}
