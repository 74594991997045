import { Component, OnInit } from '@angular/core';
import { BaseItemsComponent } from "../../shared/base-items/base-items.component";
import { AuthorizeService } from "../../../api-authorization/authorize.service";
import { ActivatedRoute, Router } from '@angular/router';
import { SensorPointLog } from 'src/app/shared/sensor-point-log.model';
import { ViewsService } from 'src/app/services/views.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangeSensorNoComponent, FreeSensor } from '../change-sensor-no/change-sensor-no.component';
import { HttpClient } from '@angular/common/http';
import { AppService } from 'src/app/services/app.service';
import { MeasuringPoint } from 'src/app/shared/measuring-point.model';

@Component({
  selector: 'app-system',
  templateUrl: './system.component.html',
  styles: [
  ]
})
export class SystemComponent extends BaseItemsComponent<SensorPointLog> implements OnInit {
  options: FreeSensor[];
  interval: number;


  constructor(service: ViewsService,
   private route1: ActivatedRoute,
    router: Router,
    auth: AuthorizeService,
    private modalService: NgbModal,
    private http: HttpClient,
    private app: AppService) {
    super(SensorPointLog, service, route1, router, auth);

    this.property = [
      { PropertyName: "AreaName", Display: "Area" },
      { PropertyName: "DevName", Display: "Device" },
      { PropertyName: "PointName", Display: "Point" },
      { PropertyName: "Number", Display: "Sensor no." },
      { PropertyName: "Change", Display: "Change sensor" },
      { PropertyName: "CoverageStrength", Display: "Coverage" },
      { PropertyName: "Battery", Display: "Battery" },
      { PropertyName: "ETR", Display: "Estimated time to replace" }
    ];
    if (!this.app.selectedPodmiotId.value) {
      this.route1.paramMap.subscribe(parmMap => {
        app.selectedPodmiotId.next(+parmMap.get("podmiotid"));
        // app.selectedAreaId.next(+parmMap.get("id"));
        this.getOptions();
      });
    } else {
      this.getOptions();
    }
  }

  getOptions() {
    this.options = [];
    this.http.get<FreeSensor[]>(`rms/MeasuringPoints/sens/list/${this.app.selectedPodmiotId.value}`).subscribe(
      free => {
        this.options = free; //.map(p=>p.SensorNumer);

      });
  }


  getETR(current: SensorPointLog) {
    this.interval = 2 * 60 * 60;
    let totalDays = (((current.Battery - 3150) / 1000) * 1850 * this.interval) / ((0.75 + (this.interval / 36000)) * 86400);

    return this.convertTotalDaysToDateTimeString(totalDays);
  }
  convertTotalDaysToDateTimeString(totalDays: number) {

    let days = +totalDays.toFixedNoRounding(0);
    //let totalHours = (totalDays - days) * 24;
    //let hours = +totalHours.toFixedNoRounding(0);
    //let totalMinutes = (totalHours - hours) * 60;
    //let minutes = +totalMinutes.toFixedNoRounding(0);

    return `${days}d`;//` ${hours}h:${minutes}m`;
  }
  exchange(current: SensorPointLog) {

    const modalRef = this.modalService.open(ChangeSensorNoComponent);//, { size: 'xl' });
    modalRef.componentInstance.options = this.options;
    modalRef.componentInstance.SensorNumer = current.Number;
    modalRef.componentInstance.IdArea = current.IdArea;
    modalRef.componentInstance.PointId = current.IdPoint;
    modalRef.componentInstance.onClose = this.onCloseModal();


    // modalRef.componentInstance.point = this.point;
    // modalRef.componentInstance.axis = this.axis;
    // modalRef.componentInstance.notify = this.notify;
  }

  remove(current: SensorPointLog){
    if(confirm("Are you sure to remove Sensor No.? ")) {
    let model = new MeasuringPoint();
          model.Id =current.IdPoint;
          //model.SensorNumer ;
          this.http.put(`rms/MeasuringPoints/point/${current.IdPoint}`, model).subscribe(p => {
           // this.alert.info(`#${this.PointId} ${this.oldSensor.value} -> ${this.newSensor.value} : Sensor number change`, { autoClose: false});
          });
        }
  }
  onCloseModal() {
    this.getOptions();
  }
}

declare global {
  interface Number {
    toFixedNoRounding: (n) => string;
  }
}


Number.prototype.toFixedNoRounding = function (n): string {
  const reg = new RegExp("^-?\\d+(?:\\.\\d{0," + n + "})?", "g");
  const a = this.toString().match(reg)[0];
  const dot = a.indexOf(".");
  if (dot === -1) { // integer, insert decimal dot and pad up zeros
    return a + "." + "0".repeat(n);
  }
  const b = n - (a.length - dot) + 1;
  return b > 0 ? (a + "0".repeat(b)) : a;
}


