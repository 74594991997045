import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { Notify } from '../profile/shared/notify.model';
import { Device } from '../shared/device.model';
import { AxisEnume } from '../shared/enum/axis-enum';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  notifys: KeyValue<number, Notify[]>[] = new Array();
  selectedDevice: Device;

  getNotify(id: number): Notify[] {
    let current = new Date(Date.now());
    return this.notifys.find((p) => p.key === id).value.filter(p=>p.Start<current && p.End >current);
  }
  isNotify(id: number){
    let current = new Date(Date.now());
    return this.notifys && this.notifys.find((p) => p.key === id)  && this.notifys.find((p) => p.key === id).value.find(p=>p.Start<current && p.End >current);;
  }

  isChecked(id: number, axis: AxisEnume) {
    let current = new Date(Date.now());

    if (this.notifys)
      if (this.notifys.find((p) => p.key === id))
        if (this.notifys.find((p) => p.key === id).value)
          if (this.notifys.find((p) => p.key === id).value
            .find(p => p.Axis === axis && current > new Date(p.Start) && new Date(p.End) > current))
            return true;
    return false;
//return this.notifys && this.notifys.find((p) => p.key === id) && this.notifys.find((p) => p.key === id).value.find(p => p.Axis === axis && current > new Date(p.Start) && new Date(p.End) > current);
  }
}
