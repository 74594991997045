<button class="{{ cssClass }}"
        (click)="setSelected()">
<!-- [class.btn-outline-success]="isSuccess(current)"
[class.btn-outline-warning]="isWarning(current)"
[class.btn-outline-danger]="isDanger(current)"
[class.btn-outline-secondary]="isSecondary(current)"
[class.active]="isActive(current)"
[class.btn-danger]="ifAlertState(current)"
[class.btn-warning]="ifWarningState(current)"
[class.btn-light]="ifDefaultState(current)"
(click)="setSelected(current, element)"> -->
  <span>{{ currentValue | async }}</span>
</button>
