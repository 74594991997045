import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DatePickerComponent } from "@syncfusion/ej2-angular-calendars";
import { BsDatepickerConfig, BsDatepickerViewMode } from "ngx-bootstrap/datepicker";
import { AppService } from "src/app/services/app.service";
import { PeriodEnum } from "src/app/shared/enum/period-enume";

function daysInMonth(month, year) {
  let ddd = new Date(year, month, 0).getDate();

  return new Date(year, month, 0).getDate();
}

@Component({
  selector: "app-date-selector",
  templateUrl: "./date-selector.component.html",
  styles: [],
})
export class DateSelectorComponent implements OnInit {


  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, public app: AppService) {
    this.route.url.subscribe(url => {
      app.ActivePage.next(url[0].path);
    });

    this.app.selectedYear.next(this.dateValue.getFullYear());
    this.app.selectedMonth.next(this.dateValue.getMonth());
    this.app.selectedDay.next(this.dateValue.getDate());
  }

  ngOnInit(): void { }



  goTo() {
    //['d', selectedYear | async, (selectedMonth | async).value, selectedDay  | async]
    //year/:month/:day
    switch (this.app.period.value) {
      case PeriodEnum.Year: {
        this.router.navigate(["y", this.app.selectedYear.value], { relativeTo: this.route });
        break;
      }
      case PeriodEnum.Month: {
        this.router.navigate(["m", this.app.selectedYear.value, this.app.selectedMonth.value], { relativeTo: this.route });
        break;
      }
      case PeriodEnum.Day: {
        this.router.navigate(["d", this.app.selectedYear.value, this.app.selectedMonth.value, this.app.selectedDay.value], { relativeTo: this.route });
        break;
      }
    }
  }
  
  @ViewChild('date')
  public Date: DatePickerComponent;
  dateValue: Date = new Date();
  bsConfig: Partial<BsDatepickerConfig>;
  minMode: BsDatepickerViewMode = 'day';

  set DateValue(value: Date) {
    if (this.dateValue.toDateString() !== value.toDateString()) {
      this.dateValue = value;
      this.generateChart();
    }
  }
  get DateValue() {
    return this.dateValue;
  }
  get DateFormat() {
    switch (this.app.period.value) {
      case PeriodEnum.Day:
        return 'yyyy-MM-dd';

      case PeriodEnum.Month:
        return 'yyyy-MMM';
      case PeriodEnum.Year:
        return 'yyyy';
    }
  }

  generateChart() {
    this.app.selectedYear.next(this.dateValue.getFullYear());
    this.app.selectedMonth.next(this.dateValue.getMonth());
    this.app.selectedDay.next(this.dateValue.getDate());

    this.goTo();
  }
  ifDateVisible() {
    return this.app.period.value === PeriodEnum.Day || this.app.period.value === PeriodEnum.Month || this.app.period.value === PeriodEnum.Year;
  }
  previewDate() {
    let tempDate = new Date(this.dateValue.getFullYear(), this.dateValue.getMonth(), this.dateValue.getDate());
    switch (this.app.period.value) {
      case PeriodEnum.Day:
        tempDate.setDate(tempDate.getDate() - 1);
        break;
      case PeriodEnum.Month:
        tempDate.setMonth(tempDate.getMonth() - 1);
        break;
      case PeriodEnum.Year:
        tempDate.setFullYear(tempDate.getFullYear() - 1);
        break;
    }
    this.DateValue = tempDate;
  }
  nextDate() {
    let tempDate = new Date(this.dateValue.getFullYear(), this.dateValue.getMonth(), this.dateValue.getDate());
    switch (this.app.period.value) {
      case PeriodEnum.Day:
        tempDate.setDate(tempDate.getDate() + 1);
        break;
      case PeriodEnum.Month:
        tempDate.setMonth(tempDate.getMonth() + 1);
        break;
      case PeriodEnum.Year:
        tempDate.setFullYear(tempDate.getFullYear() + 1);
        break;
    }
    this.DateValue = tempDate;
  }

  dayClick() {
    this.app.period.next(PeriodEnum.Day);
    this.generateChart();
    this.minMode = 'day';
    this.bsConfig = Object.assign({}, {
      minMode: this.minMode
    });
  }

  monthClick() {
    this.app.period.next(PeriodEnum.Month);
    this.generateChart();
    this.minMode = 'month';
    this.bsConfig = Object.assign({}, {
      minMode: this.minMode
    });
  }

  yearClick() {
    this.app.period.next(PeriodEnum.Year);
    this.generateChart();
    this.minMode = 'year';
    this.bsConfig = Object.assign({}, {
      minMode: this.minMode
    });
  }

}
