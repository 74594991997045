 <div  [class.wrapper]="app.isAuthenticated | async"> <!--*ngIf="app.isAuthenticated | async"> -->


  <!-- Navbar -->
  <nav  *ngIf="app.isAuthenticated | async" class="main-header navbar navbar-expand navbar-white navbar-light app-nav-menu">

  </nav>
  <!-- /.navbar -->


  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-light-lightblue elevation-4" *ngIf="app.isAuthenticated | async">
    <!-- Brand Logo -->
    <a routerLink="/"
       class="brand-link">
      <!--<img src="favicon.ico"
           alt="DrSmart Logo"
           class="brand-image img-circle elevation-3"
           style="opacity: .8">-->
      <!--<app-logo class="brand-image img-circle elevation-3"
                style="opacity: .8"></app-logo>-->
      <div class="brand-image img-circle elevation-3">
        <svg width="30" height="30" viewBox="0 0 30 30">
          <rect x="8" y="10" width="3" height="10" fill="green" />
          <rect x="13" y="13" width="3" height="7" fill="orange" />
          <rect x="18" y="5" width="3" height="15" fill="red" />
        </svg>
      </div>
      <span class="brand-text text-darkblue">
        drSmart<sup>Vibro</sup>
      </span>
    </a>

    <!-- Sidebar -->
    <div *ngIf="app.isAuthenticated | async" class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img src="assets/img/user.png"
               class="img-circle elevation-2"
               alt="User Image">
        </div>
        <div class="info">
          <a
             class="d-block">{{  app.userName | async }}</a>
        </div>
      </div>

      <!-- Sidebar Menu -->
      <nav class="mt-2"  *ngIf="app.isAuthenticated | async">
        <ul class="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false">
          <!-- Add icons to the links using the .nav-icon class
                 with font-awesome or any other icon font library -->
                 <ng-template ngFor let-item [ngForOf]="app.podmiots | async">
                  <li class="nav-header"><strong class="text-darkred">{{ item.Name }}</strong></li>
                   <li *ngFor="let area of item.Areas" class="nav-item has-treeview menu-open" role="button" style="cursor: pointer;">
                     <span (click)="navClick(area.Id, area.PodmiotId)"
                        class="nav-link" [class.active]="area.Id === (app.selectedAreaId | async)">
                       <i class="nav-icon fas fa-warehouse"></i>
                       <p>
                         {{ area.Name }}
                       </p>
                      </span>
                   </li>
                </ng-template>
               </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>

  <!-- Content Wrapper. Contains page content -->
  <div  [class.content-wrapper]="app.isAuthenticated | async" >
    <!-- Content Header (Page header) -->
    <!-- <div class="content-header">
      <div class="container-fluid">
        <div  *ngIf="isAuthenticated | async" class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Wentylator 229</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">CSP Janikowo - Soda Ciężka</a></li>
              <li class="breadcrumb-item active">Wentylator 229</li>
            </ol>
          </div>
        </div>
      </div>
    </div> -->
    <!-- /.content-header -->
    <!-- Main content -->
        <div [class.wrapper]="app.isAuthenticated | async">
          <div id="maincontent" [class.container-fluid]="app.isAuthenticated | async"
               class="">

       

            <router-outlet>
              <!--<div class="loading-login" *ngIf="!(app.isAuthenticated | async)">
      <div class="container body-content loginPanel">
        <div class="container pt-4 pr-3 pl-3"
             style="background-color:black; opacity:0.7;">
          <div id="loginForm">
            <ul class="list-group">
              <li class="list-group-item text-white" style="background-color:black; opacity:0.7;">Kawa gotowa!</li>
              <li class="list-group-item text-white" style="background-color:black; opacity:0.7;">Loading....</li>
            </ul>
          </div>
        </div>
      </div>
    </div>-->
            </router-outlet>
            <div style="position: fixed; bottom:0px; right: 10px; z-index: 1200;">
              <alert></alert>
            </div>
          </div>
        </div>


    <!-- /.content -->
  </div>
  <!-- /.content-wrapper -->

  <!-- Control Sidebar -->
  <aside class="control-sidebar control-sidebar-light" *ngIf="app.isAuthenticated | async">
    <!-- Control sidebar content goes here -->
  </aside>
  <!-- /.control-sidebar -->

  <!-- Main Footer -->
  <footer class="main-footer" *ngIf="app.isAuthenticated | async">
    <div class="row">
      <div class="col text-left">
        <h5 style="color:darkblue; font-weight:bold; padding-top:13px;"
            class="pull-right">
          Dr Smart
        </h5>
      </div>
      <div class="col">
        <img width="100"
             class="img-fluid pt-2"
             src="assets/img/kolor.png"
             alt="EuroKiln" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p>
          <small>
            <strong>Copyright &copy; 2015{{ (year | async) | date:'\'-\'yyyy' }} <a href="http://www.zmp.com.pl">ZMP</a>.</strong>
            <br />
            <span>All rights reserved.</span>
          </small>
        </p>
      </div>
      <div class="col d-none d-sm-block ">
        <div class="text-right">
          <b>Version:</b> 0.9.50
        </div>
      </div>
    </div>
  </footer>
</div>
<!-- ./wrapper -->

