<div class="row">
  <div class="col">

    <div class="btn-toolbar">
      <div class="btn-group rounded"
           [class.elevation-2]="(settings.active | async) == 'area'"
           role="group">
        <a [routerLink]="[{ outlets: { primary: ['areas'], details: ['area'] } }]"
           class="btn btn-light border">
          <span *ngIf="settings.selectedArea | async; else elseArea">
            {{ (settings.selectedArea | async).Name }}
          </span>
          <ng-template #elseArea>
            <span>Areas</span>
          </ng-template>
        </a>
        <!-- <button type="button" class="btn btn-light border">
          <i class="fas fa-times text-danger"></i>
        </button> -->
        <div class="btn-group"
             *ngIf="settings.orderAreas | async"
             role="group">
          <button id="btnGroupDrop1"
                  type="button"
                  class="btn btn-light dropdown-toggle border"
                  data-toggle="dropdown"
                  aria-expanded="false">
          </button>
          <div class="dropdown-menu">
            <button class="dropdown-item"
                    (click)="settings.changeArea(null)">All</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item"
                    *ngFor="let area of (settings.orderAreas | async)"
                    (click)="settings.changeArea(area)">{{ area.Name }}</button>
          </div>
        </div>
      </div>
      <div class="btn-group rounded ml-2"
           [class.elevation-2]="(settings.active | async) == 'device'"
           role="group">
        <a [routerLink]="[{ outlets: { primary: ['devices'], details: ['device'] } }]"
           class="btn btn-light border">
          <span *ngIf="settings.selectedDevice | async; else elseDevice">
            {{ (settings.selectedDevice | async).Name }}
          </span>
          <ng-template #elseDevice>
            <span>Devices</span>
          </ng-template>
        </a>

        <div class="btn-group"
             *ngIf="settings.orderDevices | async"
             role="group">
          <button id="btnGroupDrop1"
                  type="button"
                  class="btn btn-light dropdown-toggle border"
                  data-toggle="dropdown"
                  aria-expanded="false">
          </button>
          <div class="dropdown-menu">
            <button class="dropdown-item"
                    (click)="settings.changeDevice(null)">All</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item"
                    *ngFor="let device of (settings.orderDevices | async)"
                    (click)="settings.changeDevice(device)">{{ device.Name }}</button>
          </div>
        </div>
      </div>
      <div class="btn-group rounded ml-2"
           [class.elevation-2]="(settings.active | async) == 'point'"
           role="group">
        <a [routerLink]="[{ outlets: { primary: ['points'], details: ['point'] } }]"
           class="btn btn-light border">
          <span *ngIf="settings.selectedPoint | async; else elsePoint">
            {{ (settings.selectedPoint | async).Name }}
          </span>
          <ng-template #elsePoint>
            <span>Points</span>
          </ng-template>
        </a>

        <div class="btn-group"
             *ngIf="settings.orderPoints | async"
             role="group">
          <button id="btnGroupDrop1"
                  type="button"
                  class="btn btn-light dropdown-toggle border"
                  data-toggle="dropdown"
                  aria-expanded="false">
          </button>
          <div class="dropdown-menu">
            <button class="dropdown-item"
                    (click)="settings.changePoint(null)">All</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item"
                    *ngFor="let point of (settings.orderPoints | async)"
                    (click)="settings.changePoint(point)">{{ point.Name }}</button>
          </div>
        </div>
      </div>
      <div class="btn-group rounded ml-2"
           [class.elevation-2]="(settings.active | async) == 'sensor'"
           role="group">
        <a [routerLink]="[{ outlets: { primary: ['sensors'], details: ['sensor'] } }]"
           class="btn btn-light border">
          <span *ngIf="settings.selectedSensor | async; else elseSensor">
            {{ (settings.selectedSensor | async).Number }}
          </span>
          <ng-template #elseSensor>
            <span>Sensors</span>
          </ng-template>
        </a>

        <div class="btn-group"
             *ngIf="settings.orderSensors | async"
             role="group">
          <button id="btnGroupDrop1"
                  type="button"
                  class="btn btn-light dropdown-toggle border"
                  data-toggle="dropdown"
                  aria-expanded="false">
          </button>
          <div class="dropdown-menu">
            <button class="dropdown-item"
                    (click)="settings.changeSensor(null)">All</button>
            <div class="dropdown-divider"></div>
            <button *ngFor="let sensor of (settings.orderSensors | async)"
                    class="dropdown-item"
                    (click)="settings.changeSensor(sensor)">{{ sensor.Number }}</button>
          </div>
        </div>
      </div>

    </div>
    <div class="col">


    </div>
  </div>
</div>
