<div class="pt-3"
     *ngFor="let podmiot of app.podmiots | async">
  <h4 class="page-header text-darkred ml-2"><strong> {{ podmiot.Name }}</strong></h4>
  <div class="row">
    <div class="col-12 col-sm-4 col-md-4 col-xl-3 pl-3 pr-1"
         *ngFor="let area of podmiot.Areas">
      <div class="card">
        <div class="card-header  card-header-defaults">
          <h5 class="card-title">
            <strong class="text-darkblue">{{ area.Name }}</strong>
          </h5>

          <div class="card-tools">
            <!-- *ngIf="podmiot.Name == 'ZMP'"> -->

            <span>
              <app-notification-icon [period]='lastPeriod'
                                     [area]="area" [icon]="false"></app-notification-icon>
            </span>
            <!-- <a *ngIf="isZmp | async"
               [routerLink]="['/supervision', area.Id, area.PodmiotId]"
               class="btn btn-sm btn-tool">
              <i class="fas fa-angle-double-right"></i>
            </a> -->
          </div>
        </div>
        <div class="card-body pl-2 pr-2">

          <app-condition [area]="area"></app-condition>
          <br />
          <!--<p class="card-text">With supporting text below as a natural lead-in to additional content.</p>-->
          <a [routerLink]="['/area', area.Id, area.PodmiotId]"
             class="btn btn-primary">
            go to
          </a>

          <!-- <a *ngIf="isZmp | async"
             [routerLink]="['/supervision', area.Id, area.PodmiotId]"
             class="btn btn-info bg-purple border-white float-right">
            <i class="fas fa-angle-double-right"></i>
          </a> -->
        </div>
      </div>
    </div>
  </div>
</div>
