import { MeasuringPoint } from "./measuring-point.model";
import { IdBase } from "./ID.base";
import { Axis } from "@syncfusion/ej2-angular-charts";

export class Config implements IdBase {
  Id: number;
  Name: string;
  IdGroup: number;
  //'YYYY-MM-DD[T]HH:mm:ss.SSSZ'
  To: Date;
  //'YYYY-MM-DD[T]HH:mm:ss.SSSZ'
  From: Date;
  ConfigNumber: number;
  TimeoutWifi: number;
  TimeoutServer: number;
  ConnectionDelay: number;
  ConnectionLimit: number;
  AvarageNumber: number;
  CorRms: number;
  OffRms: number;
  CorAmp: number;
  // UserTresEnable: number;
  // StatTresEnable: number;
  // TresStatW: number;
  // TresStatA: number;
  Server: string;

  SamplingFrequency: number;
  Kxodr: number;
  Kxadp: boolean;
  Kxav: number;

  SampleSize: number;
  VelEn: boolean;
  TwfEn: boolean;
  FftEn: boolean;

  MesAxis: Axis;


  SampleSizeRms: number;
  SpiEn: boolean;
  FftCutEn: boolean;

  MeasuringPoints: MeasuringPoint[];

  GetEditUrl: string;

  constructor() {
    this.Id = 0;
    this.IdGroup = 0;
    this.To = null;

    this.From = new Date();
    this.ConfigNumber = 1;
    this.TimeoutWifi = 10000;
    this.TimeoutServer = 1000;
    this.ConnectionDelay = 1000;
    this.ConnectionLimit = 2;
    this.AvarageNumber = 6;
    this.CorRms = 1;
    this.OffRms = 0.2;
    this.CorAmp = 0.875;
    // this.UserTresEnable = 1;
    // this.StatTresEnable = 1;
    //this.TresStatW: number;
    // this.TresStatA = 10;
    this.Server = 'http://rms.eurokiln.com/api/raw';
    this.GetEditUrl = 'config/edit';
  }

}
