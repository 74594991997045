import { Component } from '@angular/core';
import { Connection } from '../../shared/connection.model';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseItemsComponent } from '../../shared/base-items/base-items.component';
import { ConnectionService } from '../../services/connection/connection.service';
import { AuthorizeService } from 'src/api-authorization/authorize.service';

@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styles: []
})
export class ConnectionsComponent extends BaseItemsComponent<Connection> {


  constructor(service: ConnectionService, route: ActivatedRoute, router: Router,
    auth: AuthorizeService) {
    super(Connection, service, route, router, auth);
    this.property = [
      { PropertyName: "IdArea", Display: "Area" },
      { PropertyName: "SsId", Display: "SSID" }
    ];
  }
}
