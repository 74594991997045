import { Component, OnInit } from '@angular/core';
import { Area } from '../shared/area.model';
import { Device } from '../shared/device.model';
import { MeasuringPoint } from '../shared/measuring-point.model';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styles: [
  ]
})
export class SettingsComponent implements OnInit {
 // client: Client;
  area: Area;
  device: Device;
  point: MeasuringPoint;

  constructor() { }

  ngOnInit(): void {
  }
  
  selectedArea(area: Area) {
    this.area = area;
  }
  selectedDevice(device: Device) {
    this.device = device;
  }
  selectedPoint(point: MeasuringPoint) {
    this.point = point;
  }
}
