import { Component, Input, OnInit, Inject } from '@angular/core';
import { PeriodEnum } from '../shared/enum/period-enume';
import { ActivatedRoute } from "@angular/router";
import { ChartNews } from "../shared/chart-news.model";
import { NewsEnum } from "../shared/enum/news-enum";
import { BehaviorSubject, Observable } from "rxjs";
import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AuthorizeService } from "../../api-authorization/authorize.service";
import { map, filter } from 'rxjs/operators';
import { GongService } from '../services/gong.service';
import { Area } from "../shared/area.model";

@Component({
  selector: 'app-notification-icon, [app-notification-icon]',
  templateUrl: './notification-icon.component.html',
  styles: [
  ]
})
export class NotificationIconComponent implements OnInit {
  public userRoles: Observable<string[]>;
  public isAdminRot: BehaviorSubject<boolean | null> = new BehaviorSubject(null);
  public isZmp: BehaviorSubject<boolean | null> = new BehaviorSubject(null);


  id: Observable<number>;
  url: Observable<string>;



  @Input()
  period: PeriodEnum;

  //@Input()
  //area: number;

  $area: Area;
  @Input()
  set area(val: Area) {

    if (val && this.$area !== val) {
      this.$area = val;

      this.gong.get(val.Id, PeriodEnum.Last, NewsEnum.Alert).subscribe(msg => {
        if (msg) {
          this.danger.next(msg["Value"]);
        }
      });
      this.gong.get(val.Id, PeriodEnum.Last, NewsEnum.Warning).subscribe(msg => {
        if (msg) {
          this.warning.next(msg["Value"]);
        }
      });
      this.gong.get(val.Id, PeriodEnum.Last, NewsEnum.Msg).subscribe(msg => {
        if (msg) {
          this.msg.next(msg["Value"]);
        }
      });

    }

  }
  get area(): Area {
    return this.$area;
  }

  @Input()
  device: number;

  @Input()
  icon: boolean;

  @Input()
  pill: boolean;

  @Input()
  card: boolean;


  danger: BehaviorSubject<number | null> = new BehaviorSubject(null);
  warning: BehaviorSubject<number | null> = new BehaviorSubject(null);
  msg: BehaviorSubject<number | null> = new BehaviorSubject(null);


  constructor(private http: HttpClient,
    authorizeService: AuthorizeService,
    private route: ActivatedRoute, private gong: GongService) {
    this.userRoles = authorizeService.getUser().pipe(filter(u => u !== null), map(u => u.role));
    this.userRoles.subscribe(roles => {
      if (roles) {
        this.isAdminRot.next(roles.includes("AdminRot"));
        this.isZmp.next(roles.includes("ZMP"));
      }
    });

    this.id = this.route.params.pipe(map(p => +p.id));
    this.url = this.route.url.pipe(map(segments => segments.join('')));
    // route.data includes both `data` and `resolve`
    const user = this.route.data.pipe(map(d => d.user));

    this.id.subscribe(id => {
      this.danger.next(null);
      this.warning.next(null);
      this.msg.next(null);
      //if (id > 0) {
      //  this.area = id;
      //}
    });
  }

  ngOnInit(): void {
  }

  isInfinity(num: number) {
    if (num > 999) {
      return "∞";
    } else {
      return num;
    }
  }

}
