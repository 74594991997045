import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Config } from 'src/app/shared/config.model';
import { StateEnume } from 'src/app/shared/enum/state-enum';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config/config.service';
import { BehaviorSubject } from 'rxjs';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styles: []
})
export class ConfigComponent implements OnInit {
  @Input() item: Config;
  @Input() state: StateEnume;

  @Output() changeConf = new EventEmitter();
  public isTester: BehaviorSubject<boolean | null> = new BehaviorSubject(null);

  constructor(private service: ConfigService, private route: ActivatedRoute,
    private router: Router,
    auth: AuthorizeService) {
    auth.getUser().pipe(map(u => u.role)).subscribe(roles => {
      if (roles) {
        // this.isAdminRot.next(roles.includes("AdminRot"));
        // this.isZmp.next(roles.includes("ZMP"));
        this.isTester.next(roles.includes("Tester"));
      } else {
        // this.isAdminRot.next(false);
        // this.isZmp.next(false);
        this.isTester.next(false);
      }
    });
  }

  ngOnInit() {
    if (!this.state) {
      this.state = StateEnume.Details;
      const id: number = +this.route.snapshot.paramMap.get('id');
      if (id && id !== 0) {
        this.service.get<Config>(id)
          .subscribe(
            result => {
              this.item = result;
              this.state = StateEnume.Edit;
            });
      }
    }
  }

  get isDetails() {
    return this.state === StateEnume.Details;
  }

  onTwfEn() {
    this.item.TwfEn = !this.item.TwfEn;
  }

  onFftEn() {
    this.item.FftEn = !this.item.FftEn;
  }

  onVelEn() {
    this.item.VelEn = !this.item.VelEn;
  }
  onKxadp() {
    this.item.Kxadp = !this.item.Kxadp;
  }
  onSpiEn() {
    this.item.SpiEn = ! this.item.SpiEn;
  }
  onFftCutEn() {
    this.item.FftCutEn = !this.item.FftCutEn;
  }

  submit(f: NgForm) {
    if (this.state === StateEnume.New) {
      this.service.add<Config>(this.item)
        .subscribe(
          result => {
            console.log(result);
            //this.config = result.json();
            this.state = StateEnume.Details;
            this.item = result;
          });
    }
    if (this.state === StateEnume.Edit || this.state === StateEnume.Details) {
      this.service.update<Config>(this.item, this.item.Id)
        .subscribe(
          result => {
            console.log(result);
            //this.config = result.json();
            this.state = StateEnume.Details;
            this.item = result;
            this.changeConf.emit(this.item);
          });
    }
  }
}
