<div class="row">
  <div class="col">
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th *ngFor="let prop of property" scope="col" [sortable]="prop.PropertyName" (sort)="onSort($event)" [direction]="prop.PropertyName == 'Battery'? 'asc':''">{{
              prop.Display }}</th>
            <!--<th scope="col">-->
              <!-- <button class="btn btn-success btn-sm pull-right"
                          (click)="createConfig()">
                    <i class="fas fa-plus"></i>
                  </button> -->
                  <!--Change sensor
            </th>-->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let current of pagination; let i = index" [class.active]="i == selectedRow"
            (click)="setSelected(current, i)">
            <td *ngFor="let prop of property" scope="col">
              <div [ngSwitch]="prop.PropertyName">
                <span *ngSwitchCase="'ETR'">
                  <span>{{ getETR(current) }}</span>
                </span>
                <span *ngSwitchCase="'Battery'">
                  <app-battery [HideValue]="false" [Battery]="current[prop.PropertyName]"></app-battery>
                </span>
                <span *ngSwitchCase="'CoverageStrength'">
                  <svg-signal class="ml-1" [size]="2"
                              [strength]="current[prop.PropertyName]"></svg-signal>
                </span>
                <span *ngSwitchCase="'Change'">
                  <button class="btn btn-primary" (click)="exchange(current)">
                    <i class="fas fa-exchange-alt"></i>
                  </button>
                  <button  class="btn btn-danger ml-1" (click)="remove(current)">
                    <i class="fas fa-times"></i>
                  </button>

                </span>
                <span *ngSwitchDefault>
                  <span>{{ current[prop.PropertyName] }}</span>
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-between p-2" *ngIf="collectionSize > pageSize">
      <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
        (pageChange)="refreshCountries()">
      </ngb-pagination>

      <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshCountries()">
        <option *ngFor="let element of pageList" [ngValue]="element">{{ element }}</option>
      </select>
    </div>

  </div>
</div>
