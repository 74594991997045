import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ekg',
  templateUrl: './ekg.component.svg',
  styles: [
  ]
})
export class EkgComponent implements OnInit {
  $fill;

  @Input()
  set fill(color) {
    if (color) {
      this.$fill = color;
    } else {
      this.$fill = "#000000";
    }
  }

  get fill(){
  return this.$fill;
}

  constructor() { }

  ngOnInit(): void {
  }

}
