<div class="row">
  <div class="col-6">
    <div class="card">
      <div class="card-body">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Last:</span>
            </div>
            <div class="form-control">yyyy-MM-dd / HH:mm</div>
          </div>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Temp:</span>
            </div>
            <div class="form-control">25 C</div>
          </div>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="card">
      <div class="card-body">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Temp</span>
            </div>
            <div class="form-control">25 C</div>
          </div>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Temp</span>
            </div>
            <div class="form-control">25 C</div>
          </div>
      </div>
    </div>
  </div>
</div>

<ejs-chart id='chart-container' [primaryXAxis]='xAxis' [primaryYAxis]='yAxis' [title]='chartTitle'
  [legendSettings]='legend' [tooltip]='tooltipSettings'>
  <e-series-collection>
    <e-series type='Line' [dataSource]='data' xName='frq' yName='amp' name='FFT' [marker]='markerSettings'></e-series>
  </e-series-collection>
</ejs-chart>

<div class="row">
  <div class="col-4">
    <div class="card">
      <div class="card-header">
        Status
      </div>
      <div class="card-body">

      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="card">
      <div class="card-header">
        Zmiana
      </div>
      <div class="card-body">

      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="card">
      <div class="card-header">
        Konfiguracja
      </div>
      <div class="card-body">

      </div>
    </div>
  </div>
</div>