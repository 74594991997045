export class AppConstants {

}
export const PagesPaths = {
  Supervision: "supervision",
  Client: "client",
  Alert: "alert",
  Warning: "warning",
  Profile: "profile"
}
