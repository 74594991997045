
<h4 class="text-bold"
    [class.text-danger]="app.isActiveAlert"
    [class.text-warning]="app.isActiveWarning"
    [class.text-purple]="app.isActiveSupervision"
    [innerHTML]="(app.title  | async)"></h4>
<div *ngIf="!app.isActiveClient">
  <span *ngIf="ifDateVisible()"
        class="btn btn-info mr-1"
        (click)="previewDate()">
    <span class="fa fa-chevron-left"></span>
  </span>
  <span *ngIf="ifDateVisible()"
        type="text"
        placeholder="yyyy-MM-dd"
        class="btn btn-info text-white"
        #dp="bsDatepicker"
        [bsConfig]="bsConfig"
        bsDatepicker
        [(bsValue)]="DateValue">{{ DateValue | date: DateFormat }}</span>
  <span *ngIf="ifDateVisible()"
        class="btn btn-info ml-1"
        (click)="nextDate()">
    <span class="fa fa-chevron-right"></span>
  </span>

  <span class="btn btn-primary ml-1"
        *ngIf="(app.period | async)!=1"
        (click)="dayClick()">
    D
  </span>
  <span class="btn btn-primary ml-1"
        *ngIf="(app.period | async)!=3"
        (click)="monthClick()">
    M
  </span>
  <span class="btn btn-primary ml-1"
        *ngIf="(app.period | async)!=4"
        (click)="yearClick()">
    Y
  </span>
  <hr>
</div>
<h5 *ngIf="(app.state | async) === 5 || (app.state | async)  === 6;"><span>{{ app.titleSub | async }}</span><strong
          *ngIf="(app.period | async) === 5"> {{ app.titleSubDt | async }}</strong></h5>
<router-outlet>
</router-outlet>
