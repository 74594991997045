<div class="card  card-danger card-outline">
  <div class="card-header with-border">
    <h4 class="card-title">
      <strong> {{ device.Name }}</strong>
    </h4>
    <div *ngIf="type==2" class="card-tools">
      <span class="btn btn-tool mr-1"
            data-toggle="tooltip"
            data-placement="top"
            title="Unselect all alert in Device."
            (click)="saveAll(device, -1)">
        <i class="fas fa-minus"></i>
      </span>
      <span class="btn btn-tool text-warning mr-1"
            data-toggle="tooltip"
            data-placement="top"
            title="Mark all as warning."
            (click)="saveAll(device, 2)">
        <i class="fas fa-check"></i>
      </span>
      <span class="btn btn-tool text-danger mr-1"
            data-toggle="tooltip"
            data-placement="top"
            title="Mark all as alert."
            (click)="saveAll(device, 1)">
        <i class="fas fa-check"></i>
      </span>
    </div>
  </div>
  <div class="card-body">
    <dev-heading [type]="type"></dev-heading>
    <dev-row *ngFor="let point  of rows | async"
             [row]="point" [device]="device" [type]="type"></dev-row>
  </div>
  <div class="card-footer">
    <span class="float-right"
          style="cursor: pointer;">
      <i class="fa fa-history text-info"></i>
    </span>
  </div>
</div>
