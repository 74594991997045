import { ThrowStmt } from '@angular/compiler';
import { Injectable } from '@angular/core';
import * as signalR from '@aspnet/signalr'
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  private hubConnection: signalR.HubConnection;

  private maxReconnect = 10;
  public reconnect = 0;

  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl('/real')
      .build();

    this.hubConnection
      .start()
      .then(() => {
        this.reconnect = 0;
        console.log('Connection started');
      })
      .catch(err => {
        console.log('Error while starting connection: ' + err);
        this.reconnect = this.reconnect + 1;
        if (this.reconnect < this.maxReconnect) {
          console.log(this.reconnect + '. Reconnect started.');
          this.addRefreshListener();
        }
      });

  }

  public addRefreshListener = () => {
    this.hubConnection.on('refreshapp',
      areaId => {
        if (areaId && areaId === this.app.selectedAreaId.value)
          window.location.reload();
      });
  }

  constructor(private app: AppService) { }
}
