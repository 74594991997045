<h4 class="text-darkred"
    style="margin-bottom: 5px;">
  <i class="far fa-envelope"></i><strong>&nbsp;Notification emission</strong>
</h4>
<div class="card card-outline">
  <div class="card-body">
    <mat-slide-toggle [color]="color"
    [(ngModel)]="alertChecked">Report (7, 14, 21)</mat-slide-toggle>
    <!-- <br/>
    <mat-slide-toggle [color]="color">...</mat-slide-toggle> -->
  </div>
</div>
