<h4 class="text-darkred"
    style="margin-bottom: 5px;">
  <i class="far fa-envelope"></i><strong>&nbsp;Custom notification</strong>
</h4>

<div id="customAlertPartView">

  <!--<div class="card card-solid">
    <div class="card-header with-border">
      <h4 class="card-title"></h4>
      <div class="card-tools">
        <a href="/CustomSms/Index/1"
           class="btn btn-primary mr-3">
          <i class="fa fa-edit"></i>
        </a>
      </div>
    </div>
    <div class="card-body">
      <ul class="list-group">
        <li class="list-group-item">
          <strong class="text-nowrap">RPM</strong>
          <span class="btn btn-light btn-sm">
            <span class="ml10">1.00</span>
          </span>
          <span class="btn btn-light btn-sm"><i class="fa fa-level-up"
               aria-hidden="true"></i></span>
          <span class="btn btn-light btn-sm"><span>∞</span></span>
          <a class="btn btn-danger float-right text-white"
             style="margin-right: -5px;"
             href="/Settings/SetValue/1?idalert=934"
             data-ajax-success="SetValueOnSuccess"
             data-ajax-method="DELETE"
             data-ajax="true"><i class="far fa-trash-alt"></i></a>
        </li>
        <li class="list-group-item">
          <strong class="text-nowrap">RPM</strong>
          <span class="btn btn-light btn-sm">
            <span class="ml10">1.00</span>
          </span>
          <span class="btn btn-light btn-sm"><i class="fa fa-level-down"
               aria-hidden="true"></i></span>
          <span class="btn btn-light btn-sm"><span>∞</span></span>
          <a class="btn btn-danger float-right text-white"
             style="margin-right: -5px;"
             href="/Settings/SetValue/1?idalert=937"
             data-ajax-success="SetValueOnSuccess"
             data-ajax-method="DELETE"
             data-ajax="true"><i class="far fa-trash-alt"></i></a>
        </li>
      </ul>
    </div>
    <div class="card-footer">
      <a href="/Settings/Timeline/1?type=CustomSms"
         class="float-right">
        <i class="fa fa-history fa-2x text-info"></i>
      </a>
    </div>
  </div>-->

  <dev-standard *ngFor="let device of (app.devices | async)" [device]="device"  [type]="type"></dev-standard>
</div>
