import { KeyValue } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit, Input } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AppService } from "src/app/services/app.service";
import { Device } from "src/app/shared/device.model";
import { AxisEnume } from "src/app/shared/enum/axis-enum";
import { NewsEnum } from "src/app/shared/enum/news-enum";
import { MeasuringPoint } from "src/app/shared/measuring-point.model";
import { AlertService } from "src/app/shared/_alert";
import { Notify } from "../../shared/notify.model";
import { TypeDevice } from "../../shared/type-device.model";
import { DeviceViewService } from "../device-view.service";
import { Row } from "../row/row.model";

@Component({
  selector: "dev-standard",
  templateUrl: "./standard.component.html",
  styles: [],
})
export class StandardComponent implements OnInit {
  @Input()
  type: TypeDevice;
  rows: BehaviorSubject<Row[] | null> = new BehaviorSubject(null);

  $device: Device;
  @Input()
  set device(dev: Device) {
    this.$device = dev;

    this.http.get<Row[]>(`rms/rms/point/${dev.Id}`)
      .subscribe((points) => {
        this.rows.next(points);
      });
  }
  get device() {
    return this.$device;
  }
  constructor(
    private http: HttpClient,
    public app: AppService,
    private alert: AlertService,
    public view: DeviceViewService
  ) { }

  ngOnInit(): void { }

  saveAll(device: Device, genre: NewsEnum) {
    let notify = new Notify();
    notify.Axis = 0;
    notify.Genre = genre;
    notify.IdPoint = 0;
    let model = { Key: device.Id, Value: notify };
    console.log(model);
    this.view.add(model).subscribe(points => {
      if (model.Value.Genre === NewsEnum.Alert)
        this.alert.error(`${device.Name}: Alert notifications enabled`);
      if (model.Value.Genre === NewsEnum.Warning)
        this.alert.warn(`${device.Name}: Warning and Alert notifications enabled`);
      if (model.Value.Genre === NewsEnum.Lack)
        this.alert.info(`${device.Name}: All notifications disabled`);
      if (points) {
        this.rows.next(points);
      } else {
        this.http.get<Row[]>(`rms/rms/point/${device.Id}`)
          .subscribe((points) => {
            this.rows.next(null);
            this.rows.next(points);
          });
      }
    });


  }
  // private handleError<T>(operation = "operation", result?: T) {
  //   return (error: any): Observable<T> => {
  //     // TODO: send the error to remote logging infrastructure
  //     console.error(error); // log to console instead

  //     // // TODO: better job of transforming error for user consumption
  //     this.alert.error(`${operation} failed: ${error.message}`);

  //     // Let the app keep running by returning an empty result.
  //     return of(result as T);
  //   };
  // }
}
