<h1>Counter</h1>

<p>This is a simple example of an Angular component.</p>

<p aria-live="polite">Current count: <strong>{{ currentCount }}</strong></p>

<button class="btn btn-primary" (click)="incrementCounter()">Increment</button>


<h1>Angular 8 Alerts</h1>
<button class="btn btn-success m-1" (click)="alertService.success('Success!!', options)">Success</button>
<button class="btn btn-danger m-1" (click)="alertService.error('Error :(', options)">Error</button>
<button class="btn btn-info m-1" (click)="alertService.info('Some info....', options)">Info</button>
<button class="btn btn-warning m-1" (click)="alertService.warn('Warning: ...', options)">Warn</button>
<button class="btn btn-outline-dark m-1" (click)="alertService.clear()">Clear</button>
<div class="form-group mt-2">
    <div class="form-check">
        <input type="checkbox" name="autoClose" id="autoClose" class="form-check-input" [(ngModel)]="options.autoClose">
        <label for="autoClose">Auto close alert after three seconds</label>
    </div>
    <div class="form-check">
        <input type="checkbox" name="keepAfterRouteChange" id="keepAfterRouteChange" class="form-check-input" [(ngModel)]="options.keepAfterRouteChange">
        <label for="keepAfterRouteChange">Keep displaying after one route change</label>
    </div>
</div>
