import { OnInit, Directive, ViewChildren, QueryList, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StateEnume } from '../enum/state-enum';
import { DataService } from 'src/app/services/data.service';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { SortableHeader, SortEvent } from '../table/sortable.directive';
import { PropertyDisplay } from '../interface/property-display';
import { BooleanLiteral } from 'typescript';


const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

@Directive()
export class BaseItemsComponent<T> implements OnInit {
  property: PropertyDisplay[] = [
    { PropertyName: "Name", Display: "Name" }
  ];

  @Output()
  selectedEvent = new EventEmitter<T>();

  @ViewChildren(SortableHeader)
  headers: QueryList<SortableHeader>;
  DataSources: BehaviorSubject<T[] | null> = new BehaviorSubject(null);
  IsDataSources: Boolean;


  items: T[];
  state: StateEnume;
  selectedRow: number;
  selectedItem: T;
  sortable: T[];


  page: number = 1;
  pageSize: number;
  collectionSize: number;
  pagination: T[];

  pageList = [100, 200, 500];


  filter = null;
  filterPropert = null;


  public userRoles: Observable<string[]>;
  public isAdminRot: BehaviorSubject<boolean | null> = new BehaviorSubject(null);
  public isZmp: BehaviorSubject<boolean | null> = new BehaviorSubject(null);
  public isTester: BehaviorSubject<boolean | null> = new BehaviorSubject(null);


  constructor(private testType: new () => T, private service: DataService,
    private route: ActivatedRoute,
    private router: Router,
    authorizeService: AuthorizeService) {
    this.userRoles = authorizeService.getUser().pipe(filter(u => u !== null), map(u => u.role));
    this.userRoles.subscribe(roles => {
      if (roles) {
        this.isAdminRot.next(roles.includes("AdminRot"));
        this.isZmp.next(roles.includes("ZMP"));
        this.isTester.next(roles.includes("Tester"));
      } else {
        this.isAdminRot.next(false);
        this.isZmp.next(false);
        this.isTester.next(false);
      }
    });
    this.pageSize = this.pageList[0];
  }
  hasChange(event: T) {
    if (this.IsDataSources) {
      this.DataSources.subscribe(
        result => {
          if (result) {
            console.log(result);
            this.items = result;
            this.sortable = this.items;//this.items;
            this.collectionSize = this.sortable.length;
            this.refreshCountries();
          }
        });
    } else {
      this.service.getAll<T>()
        .subscribe(
          result => {
            console.log(result);
            this.items = result;
            this.sortable = this.filter ? this.items.filter(item => item[this.filterPropert] == this.filter) : this.items;// this.items;
            this.collectionSize = result.length;
            this.refreshCountries();
            //this.selectedItem = event;
            if (event) {
              this.setSelected(event, this.sortable.findIndex(element => element["Id"] === event["Id"]))
            }
            this.onChange(event);
          });
    }
  }

  ngOnInit() {
    this.state = StateEnume.Details;
    if (this.IsDataSources) {
      this.DataSources.subscribe(
        result => {
          if (result) {
            console.log(result);
            this.items = result;
            this.sortable = this.items;//this.items;
            this.collectionSize = this.sortable.length;
            this.refreshCountries();
          }
        });
    } else {
      this.service.getAll<T>()
        .subscribe(
          result => {
            console.log(result);
            this.items = result;
            this.sortable = this.filter ? this.items.filter(item => item[this.filterPropert] == this.filter) : this.items;//this.items;
            this.collectionSize = this.sortable.length;
            this.refreshCountries();
          });
    }
  }

  refreshCountries() {
    this.pagination = this.sortable
      .map((element, i) => ({ id: i + 1, ...element }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  onSort({ column, direction }: SortEvent) {
    if (this.headers) {
      // resetting other headers
      this.headers.forEach(header => {
        if (header.sortable !== column) {
          header.direction = '';
        }
      });


      // sorting countries
      if (direction === '' || column === '') {
        this.sortable = this.filter ? this.items.filter(item => item[this.filterPropert] == this.filter) : this.items;
      } else {
        this.sortable = [...(this.filter ? this.items.filter(item => item[this.filterPropert] == this.filter) : this.items)].sort((a, b) => {
          const res = compare(a[column], b[column]);
          return direction === 'asc' ? res : -res;
        });
      }
      this.refreshCountries();
      this.selectedItem = null;
      this.selectedRow = null;
      this.selectedEvent.emit(this.selectedItem);
    }
  }

  create(): T {
    return new this.testType();
  }


  onChange(item: T) {

    const index = this.items.findIndex(element => element["Id"] === this.selectedItem["Id"]); //this.items.indexOf(this.selectedItem);

    if (~index) {
      this.items[index] = item;
    }

    this.selectedItem = item;
    this.selectedEvent.emit(this.selectedItem);
  }


  setSelected(item: T, index: number) {
    this.selectedItem = item;
    if (item["Id"])
      this.state = StateEnume.Details;
    else
      this.state = StateEnume.New;
    this.selectedRow = index;
    this.selectedEvent.emit(this.selectedItem);
  }

  createConfig() {
    this.selectedItem = this.create();

    this.items.splice(0, 0, this.selectedItem);
    //this.configs.push(this.selectedConfig);
    this.state = StateEnume.New;
  }
}
