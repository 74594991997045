import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DeviceKindService } from 'src/app/services/device/device-kind.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StateEnume } from 'src/app/shared/enum/state-enum';
import { NgForm } from '@angular/forms';
import { KindConfig } from 'src/app/shared/kind-config.model';
import { BehaviorSubject } from 'rxjs';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-device-kind',
  templateUrl: './device-kind.component.html',
  styles: []
})
export class DeviceKindComponent  implements OnInit {
  @Input() item: KindConfig;
  @Input() state: StateEnume;
  
  @Output() changeKind = new EventEmitter();
  public isTester: BehaviorSubject<boolean | null> = new BehaviorSubject(null);

  constructor(private service: DeviceKindService, private route: ActivatedRoute,
    private router: Router,
    auth: AuthorizeService ) {
      auth.getUser().pipe(map(u => u.role)).subscribe(roles => {
        if (roles) {
          // this.isAdminRot.next(roles.includes("AdminRot"));
          // this.isZmp.next(roles.includes("ZMP"));
          this.isTester.next(roles.includes("Tester"));
        }else{          
          // this.isAdminRot.next(false);
          // this.isZmp.next(false);
          this.isTester.next(false);
        }
      }); }

  ngOnInit() {
    if (!this.state) {
      this.state = StateEnume.Details;
      let id: number = +this.route.snapshot.paramMap.get('id');
      if (id && id != 0) {
        this.service.get<KindConfig>(id)
          .subscribe(
            result => {
              this.item = result;
              this.state = StateEnume.Edit;
            });
      }
    }
  }

  get isDetails() {
    return this.state == StateEnume.Details;
  }

  submit(f: NgForm) {
    if (this.state === StateEnume.New) {
      this.service.add<KindConfig>(this.item)
        .subscribe(
          result => {
            console.log(result);
            //this.config = result.json();
            this.state = StateEnume.Details;
            this.item = result;
          });
    }
    if (this.state === StateEnume.Edit || this.state === StateEnume.Details) {
      this.service.update<KindConfig>(this.item, this.item.Id)
        .subscribe(
          result => {
            console.log(result);
            //this.config = result.json();
            this.state = StateEnume.Details;
            this.item = result;
          });
    }
  }
}
