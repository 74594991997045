import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SensorPointLog } from '../shared/sensor-point-log.model';
import { AlertService } from '../shared/_alert';
import { AppService } from './app.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class ViewsService extends DataService {


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true
  };

  //logServer = new Map<string, Date>();

  //chartNews: BehaviorSubject<ChartNews[] | null> = new BehaviorSubject(null);



  constructor(private http1: HttpClient, alert: AlertService, private app: AppService) {
    super('api/select', http1, alert);
    this.app.selectedPodmiotId.subscribe(podmiotId=>{
      this.getAll();
    });

  }

  /** GET  by id. Will 404 if id not found */
  getAll(): any {

    let url = "rms/view/";
    if (this.app.selectedAreaId == null || this.app.selectedAreaId.value == null) {
      url += "0";
    } else {
      url += this.app.selectedAreaId.value;
    }
    return this.http1.get(url, this.httpOptions);
  }
}
