import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';
import { Podmiot } from "../../shared/podmiot.model";

@Component({
  selector: 'app-list-of-area',
  templateUrl: './list-of-area.component.html',
  styles: [
  ]
})
export class ListOfAreaComponent implements OnInit {
  entities: Podmiot[];

  constructor(srv: ProfileService) {

  }

  ngOnInit(): void {
  }

}
