<div class="card">
  <div class="card-header">
    Point
    <a class="float-right"
       [routerLink]="['/settings', { outlets: { primary: ['sensors'], details: ['sensor'] } }]">
      Go to sensor
    </a>
  </div>
  <div class="card-body">
    <form #f="ngForm"
          *ngIf="item"
          (ngSubmit)="submit(f)">
      <input type="hidden"
             [ngModel]="item.Id"
             #Id="ngModel"
             name="Id"
             id="Id"
             name="Id" />
      <div class="row">
        <div class="col-md-12 col-lg-6 col-xl-3">
          <div class="form-group">
            <label for="IdDevice">Device</label>
            <select class="form-control"
                    [(ngModel)]="item.IdDevice"
                    #IdDevice="ngModel"
                    name="IdDevice"
                    id="IdDevice">
              <option [ngValue]="0"> - Select - </option>
              <option *ngFor="let current of devices"
                      [ngValue]="current.Id">
                {{ current.Id }} - {{ current.Name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-12 col-lg-6 col-xl-3">
          <div class="form-group">
            <label for="Name">Name</label>
            <input class="form-control"
                   [(ngModel)]="item.Name"
                   #Name="ngModel"
                   name="Name"
                   id="Name"
                   aria-describedby="NameHelp">
            <!-- <small id="SensorNumerHelp" class="form-text text-muted">Numer konfiguracji - przekazywany
            do
            czujnika</small> -->

          </div>
        </div>
        <div class="col-md-12 col-lg-6 col-xl-4">
          <div class="form-group">
            <label for="SensorNumer">Sensor No.</label>
            <!-- <input class="form-control"
                   [(ngModel)]="item.SensorNumer"
                   #SensorNumer="ngModel"
                   name="SensorNumer"
                   id="SensorNumer"
                   placeholder="No."> -->

            <div class="input-group">
              <div class="input-group-prepend"
                   [class.d-none]="isEditNumber">
                <span class="input-group-text bg-white"
                      [class.d-none]="numberStatus">
                  <i class="fa fa-spinner"
                     aria-hidden="true"
                     [class.d-none]="!checkNumberStatus"></i>
                  <i class="fa fa-exclamation text-red"
                     aria-hidden="true"
                     [class.d-none]="checkNumberStatus"></i>
                </span>
                <span class="input-group-text bg-white"
                      [class.d-none]="!numberStatus">
                  <i class="fa fa-check text-green"
                     aria-hidden="true"></i>
                </span>
              </div>
              <input class="form-control"
                     [(ngModel)]="item.SensorNumer"
                     #SensorNumer="ngModel"
                     name="SensorNumer"
                     id="SensorNumer"
                     placeholder="No."
                     (ngModelChange)="noSensChanged($event)"
                     [readonly]="!isEditNumber">
              <div class="input-group-append">
                <button class="btn btn-outline-info"
                        type="button"
                        [class.d-none]="isEditNumber"
                        (click)="editNumber(1)"><i class="far fa-edit"></i></button>
                <button class="btn btn-outline-warning"
                        type="button"
                        [class.d-none]="!isEditNumber"
                        (click)="editNumber(2)"><i class="fas fa-slash"></i></button>
                <button class="btn btn-outline-success"
                        type="button"
                        [class.d-none]="!isEditNumber"
                        (click)="editNumber(3)"><i class="far fa-save"></i></button>

                <!-- <button type="button"
                        class="btn btn-outline-secondary">Action</button> -->
                <button type="button"
                        class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                  <span class="sr-only">Toggle Dropdown</span>
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item"
                     [routerLink]="['/settings/sensors']">Add new sensor</a>
                  <!-- <a class="dropdown-item"
                  [routerLink]="['/settings/sensor/edit', SensorNumer]">Edit sensor</a> -->
                  <!-- <a class="dropdown-item"
                     href="#">Something else here</a>
                  <div role="separator"
                       class="dropdown-divider"></div>
                  <a class="dropdown-item"
                     href="#">Separated link</a> -->
                </div>
              </div>
            </div>

            <small id="SensorNumerHelp"
                   class="form-text text-muted"
                   [innerHTML]="sensorNumberDetails">
            </small>
          </div>
        </div>

        <div class="col-md-12 col-lg-6 col-xl-3">
          <div class="form-group">
            <label for="Genre">Limit source</label>
            <select class="form-control"
                    [(ngModel)]="item.Genre"
                    #Genre="ngModel"
                    name="Genre"
                    (ngModelChange)="genreChanged($event)"
                    id="Genre">
              <option [ngValue]="1">Standardized</option>
              <option [ngValue]="2">Custom</option>
              <option [ngValue]="3">Calculated</option>
            </select>
            <small id="GenreHelp"
                   class="form-text"
                   [innerHTML]="genreDetails">
            </small>
          </div>
        </div>

        <div class="col-md-12 col-lg-6 col-xl-2">
          <div class="form-group">
            <label for="Axial">Axial</label>
            <select class="form-control"
                    [(ngModel)]="item.Axial"
                    #Axial="ngModel"
                    name="Axial"
                    id="Axial">
              <option [ngValue]="0"> - Select - </option>
              <option [ngValue]="1">X</option>
              <option [ngValue]="2">Y</option>
              <option [ngValue]="3">Z</option>
            </select>
          </div>
        </div>
        <div class="col-md-12 col-lg-6 col-xl-3">
          <div class="form-group">
            <label for="Horizontal">Horizontal</label>
            <select class="form-control"
                    [(ngModel)]="item.Horizontal"
                    #Horizontal="ngModel"
                    name="Horizontal"
                    id="Horizontal">
              <option [ngValue]="0"> - Select - </option>
              <option [ngValue]="1">X</option>
              <option [ngValue]="2">Y</option>
              <option [ngValue]="3">Z</option>
            </select>
          </div>
        </div>

        <div class="col-md-12 col-lg-6 col-xl-3">
          <div class="form-group">
            <label for="IdConfig">Config</label>
            <div class="input-group">
              <select class="form-control"
                      [(ngModel)]="item.IdConfig"
                      #IdConfig="ngModel"
                      name="IdConfig"
                      id="IdConfig"
                      aria-describedby="SensorNumerHelp">
                <option [ngValue]="0"> - Select - </option>
                <option *ngFor="let current of configs"
                        [ngValue]="current.Id">
                  {{ current.Id }} - {{ current.Name }}
                </option>
              </select>
              <div class="input-group-append">
                <a class="btn btn-outline-secondary"
                   routerLink="/settings/configs">Go to</a>
              </div>
            </div>
            <small id="IdConfigHelp"
                   *ngIf="isDetails "
                   class="form-text text-muted">
              Zmiana konfiguracji nie dozwolona! <br />
              Jeżeli chcesz przetestować czujnik z nową konfiguracją dodaj nowy punkt pomiarowy z inną
              konfiguracją i przypisz do niego czujnik.
            </small>
          </div>
        </div>
      </div>

      <div *ngIf="isTester | async"
           class="form-group">
        <label for="detail">Json item (preview)</label>
        <textarea class="form-control"
                  disabled
                  rows="10">
                        {{ f.value | json }}
                    </textarea>
      </div>
      <button type="submit"
              class="btn btn-success float-right"
              [disabled]="!f.valid || !numberStatus"
              *ngIf="f.touched">
        <i class="far fa-save"></i> Zapisz
      </button>
    </form>
  </div>
</div>
