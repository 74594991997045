<div class="card">
    <div class="card-header">
        Device kind
    </div>
    <div class="card-body">
        <form #f="ngForm"
              (ngSubmit)="submit(f)">
            <input type="hidden"
                   [ngModel]="item.Id"
                   #Id="ngModel"
                   name="Id"
                   id="Id"
                   name="Id" />
            <input type="hidden"
                   [ngModel]="item.IdGroup"
                   #IdGroup="ngModel"
                   name="IdGroup"
                   id="IdGroup"
                   name="IdGroup" />
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label for="Name">Name</label>
                        <input class="form-control"
                               [(ngModel)]="item.Name"
                               #Name="ngModel"
                               name="Name"
                               id="Name">
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="From">From:</label>
                        <input type="datetime"
                               class="form-control"
                               [ngModel]="item.From | date:'yyyy-MM-dd / HH:mm'"
                               #From="ngModel"
                               name="From"
                               id="From"
                               disabled
                               aria-describedby="FromHelp">
                        <!-- <small id="FromHelp" class="form-text text-muted">....</small> -->
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="To">To:</label>
                        <input type="datetime"
                               class="form-control"
                               [ngModel]="item.To | date:'yyyy-MM-dd / HH:mm'"
                               #To="ngModel"
                               name="To"
                               id="To"
                               disabled
                               aria-describedby="ToHelp">
                        <!-- <small id="ToHelp" class="form-text text-muted">....</small> -->
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <div class="form-group">
                        <label for="Grange">Grange</label>
                        <select class="form-control"
                                [(ngModel)]="item.Grange"
                                #Grange="ngModel"
                                name="Grange"
                                id="Grange">
                          <option [ngValue]="2">2</option>
                          <option [ngValue]="4">4</option>
                          <option [ngValue]="8">8</option>
                          <option [ngValue]="16">16</option>
                        </select>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="HighPassFilter">High Pass Filter</label>
                        <select class="form-control"
                                [(ngModel)]="item.HighPassFilter"
                                #HighPassFilter="ngModel"
                                name="HighPassFilter"
                                id="HighPassFilter">
                          <option [ngValue]="0">0</option>
                          <option [ngValue]="1">1</option>
                          <option [ngValue]="125">1.25</option>
                          <option [ngValue]="160">1.6</option>
                          <option [ngValue]="2">2</option>
                          <option [ngValue]="250">2.5</option>
                          <option [ngValue]="8">8</option>
                          <option [ngValue]="9">9</option>
                          <option [ngValue]="10">10</option>
                          <option [ngValue]="11">11</option>
                          <option [ngValue]="12">12</option>
                          <option [ngValue]="14">14</option>
                          <option [ngValue]="16">16</option>
                          <option [ngValue]="20">20</option>
                          <option [ngValue]="24">24</option>
                        </select>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="LowPassFilter">Low Pass Filter</label>

                        <select class="form-control"
                                [(ngModel)]="item.LowPassFilter"
                                #LowPassFilter="ngModel"
                                name="LowPassFilter"
                                id="LowPassFilter">
                          <option [ngValue]="0">0</option>
                          <option [ngValue]="800">800</option>
                          <option [ngValue]="850">850</option>
                          <option [ngValue]="900">900</option>
                          <option [ngValue]="950">950</option>
                          <option [ngValue]="1000">1000</option>
                          <option [ngValue]="1050">1050</option>
                          <option [ngValue]="1100">1100</option>
                          <option [ngValue]="1150">1150</option>
                          <option [ngValue]="1200">1200</option>
                        </select>
                    </div>
                </div>
                <!-- <div class="col">
                    <div class="form-group">
                        <label for="TresUserW">Tres User W</label>
                        <input type="number"
                               class="form-control"
                               [(ngModel)]="item.TresUserW"
                               #TresUserW="ngModel"
                               name="TresUserW"
                               id="TresUserW"
                               placeholder=""
                               aria-describedby="TresUserWHelp">
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="TresUserA">Tres User A</label>
                        <input type="number"
                               class="form-control"
                               [(ngModel)]="item.TresUserA"
                               #TresUserA="ngModel"
                               name="TresUserA"
                               id="TresUserA"
                               placeholder=""
                               aria-describedby="TresUserAHelp">
                    </div>
                </div> -->
            </div>

            <div *ngIf="isTester | async" class="form-group">
                <label for="detail">Json item (preview)</label>
                <textarea class="form-control"
                          disabled
                          rows="10">
                        {{ f.value | json }}
                    </textarea>
            </div>
            <!-- <button type="submit"
                    class="btn btn-success float-right"
                    [disabled]="!f.valid"
                    *ngIf="!isDetails"><i class="far fa-save"></i> Zapisz</button> -->
            <button type="submit"
                    class="btn btn-success float-right"
                    [disabled]="!f.valid"
                    *ngIf="f.dirty"><i class="far fa-save"></i> Zapisz</button>
        </form>
    </div>
</div>
