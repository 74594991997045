import { HttpClient, HttpHeaders } from '@angular/common/http';

import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AlertService } from '../shared/_alert';
 


export class DataService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true 
  };

  constructor(private url: string, private http: HttpClient, private alert: AlertService) {

  }

 /** GET config from the server */
 getAll<T> (): Observable<T[]> {
  return this.http.get<T[]>(this.url, this.httpOptions)
    .pipe(
      // tap(_ => this.log('fetched config')),
      catchError(this.handleError<T[]>('getAll', []))
    );
}

/** GET by id. Return `undefined` when id not found */
getNo404<T>(id: number): Observable<T> {
  const url = `${this.url}/?id=${id}`;
  return this.http.get<T[]>(url, this.httpOptions)
    .pipe(
      map(config => config[0]), // returns a {0|1} element array
      // tap(h => {
      //   const outcome = h ? `fetched` : `did not find`;
      //   this.log(`${outcome} id=${id}`);
      // }),
      catchError(this.handleError<T>(`get id=${id}`))
    );
}

/** GET config by id. Will 404 if id not found */
get<T>(id: number): Observable<T> {
  const url = `${this.url}/${id}`;
  return this.http.get<T>(url, this.httpOptions).pipe(
    // tap(_ => this.log(`fetched  id=${id}`)),
    catchError(this.handleError<T>(`get id=${id}`))
  );
}
/** GET config by id. Will 404 if id not found */
get2<T>(id: number, id2:string): Observable<T> {
  const url = `${this.url}/${id}/${id2}`;
  return this.http.get<T>(url, this.httpOptions).pipe(
    // tap(_ => this.log(`fetched  id=${id} id2=${id2}`)),
    catchError(this.handleError<T>(`get id=${id} id2=${id2}`))
  );
}

/* GET configs whose name contains search term */
search<T>(term: string): Observable<T[]> {
  if (!term.trim()) {
    // if not search term, return emptyconfig array.
    return of([]);
  }
  return this.http.get<T[]>(`${this.url}/?name=${term}`, this.httpOptions).pipe(
    // tap(x => x.length ?
    //    this.log(`found configs matching "${term}"`) :
    //    this.log(`no configs matching "${term}"`)),
    catchError(this.handleError<T[]>('search', []))
  );
}

/* GET configs whose name contains search term */
active<T>(name: string, active: boolean): Observable<T[]> {
  return this.http.get<T[]>(`${this.url}/${name}/${active}`, this.httpOptions).pipe(
    // tap(x => x.length ?
    //    this.log(`found configs matching "${term}"`) :
    //    this.log(`no configs matching "${term}"`)),
    catchError(this.handleError<T[]>('active', []))
  );
}


  //////// Save methods //////////

  /** POST: add a new  to the server */
  add<T> (resource: T): Observable<T> {
    return this.http.post<T>(this.url, resource, this.httpOptions).pipe(
      //tap((newT: T) => this.alert.success("New record saved.")),
      catchError(this.handleError<T>('add'))
    );
  }

  /** DELETE: delete the Config from the server */
  delete<T> (id:  number): Observable<T> {//resource: T | number): Observable<T> {
    //const id = typeof resource === 'number' ? resource : resource.id;
    const url = `${this.url}/${id}`;

    return this.http.delete<T>(url, this.httpOptions).pipe(
      //tap(_ => this.log(`deleted  id=${id}`)),
      catchError(this.handleError<T>('delete'))
    );
  }

  /** PUT: update the Config on the server */
  update<T>(resource: T, id: number): Observable<T> {
    const url = `${this.url}/${id}`;
    return this.http.put(url, resource, this.httpOptions).pipe(
      tap(_ => this.alert.success("Changes have been saved.")),
      catchError(this.handleError<any>('update record'))
    );
  }


  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      
      // // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a ConfigService message with the MessageService */
  private log(message: string) {
    //this.messageService.add(`ConfigService: ${message}`);
    
    this.alert.info(message);
  }
}



// import { Injectable, PipeTransform } from '@angular/core';

// import { BehaviorSubject,  Subject } from 'rxjs';

// // import { Country } from './country';
// // import { COUNTRIES } from './countries';
// import { DecimalPipe } from '@angular/common';
// import { debounceTime, delay, switchMap } from 'rxjs/operators';
// import { SortColumn, SortDirection } from '../shared/table/sortable.directive';


// interface SearchResult<T> {
//   countries: T[];
//   total: number;
// }

// interface State {
//   page: number;
//   pageSize: number;
//   searchTerm: string;
//   sortColumn: SortColumn;
//   sortDirection: SortDirection;
// }

// const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

// function sort<T>(countries: T[], column: SortColumn, direction: string): T[]  {
//   if (direction === '' || column === '') {
//     return countries;
//   } else {
//     return [...countries].sort((a, b) => {
//       const res = compare(a[column], b[column]);
//       return direction === 'asc' ? res : -res;
//     });
//   }
// }


// // function matches<T>(country: T,prop: keyof T, term: string, pipe: PipeTransform) {
// //   return country.name.toLowerCase().includes(term.toLowerCase())
// //     || pipe.transform(country.area).includes(term)
// //     || pipe.transform(country.population).includes(term);
// // }

// export function search<E>(query:string='', entities:E[], exclude:string[]=[]) {
//   const { isArray } = Array
//   type EKey = keyof E;
//   query = query.toLowerCase();

//   let keys : EKey[] = []
//   if (entities.length > 0) {
//     keys = excludeKeys<E>(entities[0], exclude)
//   }

//   return entities.filter(function (e:E) {
//     return keys.some((key =>{
//       const value = e[key];
//       if (isArray(value)) {
//         return value.some(v => {
//           return v.toLowerCase().includes(search);
//         });
//       }
//       else if (!isArray(value)) {
//         return new String(value).toLowerCase().includes(query);
//       }
//     })
//   });
//  }

// export function excludeKeys<E>(entity: E, exclude: string[]) {
//   const keys: string[] = Object.keys(entity);
//    return <(keyof E)[]>keys.filter((key) => {
//      return exclude.indexOf(key) < 0;
//     });
//  }

// @Injectable({ providedIn: 'root' })
// export class CountryService<T> {
//   private _loading$ = new BehaviorSubject<boolean>(true);
//   private _search$ = new Subject<void>();
//   private _countries$ = new BehaviorSubject<T[]>([]);
//   private _total$ = new BehaviorSubject<number>(0);

//   private _state: State = {
//     page: 1,
//     pageSize: 4,
//     searchTerm: '',
//     sortColumn: '',
//     sortDirection: ''
//   };

//   constructor(private pipe: DecimalPipe) {
//     this._search$.pipe(
//       tap(() => this._loading$.next(true)),
//       debounceTime(200),
//       switchMap(() => this._search()),
//       delay(200),
//       tap(() => this._loading$.next(false))
//     ).subscribe(result => {
//       this._countries$.next(result.countries);
//       this._total$.next(result.total);
//     });

//     this._search$.next();
//   }

//   get countries$() { return this._countries$.asObservable(); }
//   get total$() { return this._total$.asObservable(); }
//   get loading$() { return this._loading$.asObservable(); }
//   get page() { return this._state.page; }
//   get pageSize() { return this._state.pageSize; }
//   get searchTerm() { return this._state.searchTerm; }

//   set page(page: number) { this._set({ page }); }
//   set pageSize(pageSize: number) { this._set({ pageSize }); }
//   set searchTerm(searchTerm: string) { this._set({ searchTerm }); }
//   set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
//   set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

//   private _set(patch: Partial<State>) {
//     Object.assign(this._state, patch);
//     this._search$.next();
//   }

//   private _search(): Observable<SearchResult<T>> {
//     const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

//     // 1. sort
//     let countries = sort<T>(COUNTRIES, sortColumn, sortDirection);

//     // 2. filter
//     countries = search(searchTerm,countries,[""]);//.filter(country => matches(country, searchTerm, this.pipe));
//     const total = countries.length;

//     // 3. paginate
//     countries = countries.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
//     return of({ countries, total });
//   }
// }
