import { formatDate, KeyValue } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ChartNews } from '../shared/chart-news.model';
import { PeriodEnum } from '../shared/enum/period-enume';
import { AlertService } from '../shared/_alert';
import { NewsEnum } from "../shared/enum/news-enum";
import { AxisEnume } from "../shared/enum/axis-enum";

@Injectable({
  providedIn: 'root'
})
export class GongService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true
  };

  //logServer = new Map<string, Date>();

  //chartNews: BehaviorSubject<ChartNews[] | null> = new BehaviorSubject(null);


  url: string = 'api/gong';
  constructor(private http: HttpClient, private alert: AlertService) { }

  /** GET  by id. Will 404 if id not found */
  get(area: number, period: PeriodEnum, news: NewsEnum, dt: Date = null): Observable<any> {
    const url = `${this.url}/${period}/${area}/${news}/${(formatDate((dt ? dt : new Date()), 'yyyy-MM-dd', 'en-US'))}`;

    return this.http.get<any>(url, this.httpOptions).pipe(
      //this.log(`fetched  id=${id}`)
      catchError(this.handleError<any>(`get id=${area} period=${period} date=${(formatDate((dt ? dt : new Date()), 'yyyy-MM-dd', 'en-US'))}`))
    );
  }
  /** GET  by id. Will 404 if id not found */
  getDetails(area: number, period: PeriodEnum, pointId: number, news: NewsEnum, unit: string, axis: AxisEnume, dt: Date = null): Observable<ChartNews[]> {
    var dtEle = new Date();
    const url = `${this.url}/${period}/${area}/${news}/${(formatDate((dt ? dt : dtEle), 'yyyy-MM-dd', 'en-US'))}/${pointId}/${unit}/${axis}`;
    //if (!this.logServer.has(url)) {
    //  this.logServer.set(url, dtEle);
    //} else {
    // if( this.logServer.get(url);
    //}
    return this.http.get<any[]>(url, this.httpOptions).pipe(
      //this.log(`fetched  id=${id}`)
      catchError(this.handleError<any[]>(`get id=${area} period=${period} date=${(formatDate((dt ? dt : new Date()), 'yyyy-MM-dd', 'en-US'))}`))
    );
  }


  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a ConfigService message with the MessageService */
  private log(message: string) {
    //this.messageService.add(`ConfigService: ${message}`);

    this.alert.info(message);
  }
}
