import { MeasuringConfig } from "./measuring-config.model";
import { KindConfig } from "./kind-config.model";
import { IdBase } from "./ID.base";
import { UnifiedLimit } from "./unified-limit.model";

export class Device implements IdBase {
  Id: number;
  Name: string;
  IdMeasurConfig: number;
  IdKindConfig: number;
  Icon: string;
  IdArea: number;
  IdUnifiedLimit: number;
  Visible: Boolean = true;

  KindConfig: KindConfig;
  //MeasuringPoint: MeasuringPoint[];
  MeasurConfig: MeasuringConfig;
  // Area:Area
  Unified: UnifiedLimit;

  GetEditUrl: string;
  constructor() {
    this.Id = 0;
    this.IdMeasurConfig = 0;
    this.IdKindConfig = 0;
    this.IdArea = 0;
    this.IdUnifiedLimit= 0;
    this.Name = '';
    this.GetEditUrl = 'device/edit';
  }


}
