<div class="row mb-1">
  <div class="col-3 col-md-2">
    <span class="small"><span *ngIf="app.isTester | async">#{{ row.Id }} - </span>{{ row.Name }}</span>
    <app-sensor-log *ngIf="ifDevDefault()" [point]="row"></app-sensor-log>
  </div>
  <div class="col-9 col-md-10">
    <div class="row">
      <div class="pl-1 pr-0 pr-sm-2"
           [class.col-3]="ifDevDefault()"
           [class.col-4]="!ifDevDefault()"
           style="display: inline-block"
           *ngFor="let a of axes">
        <app-cell *ngIf="ifDevDefault()" [axis]="a" [device]="device" [point]="row" [unit]="unit" [limit]="limit" [data]="data$ | async"
                  (selected)="selectedChange($event)"
                  (change)="onChange($event)"></app-cell>
        <app-cell-button *ngIf="ifDevBasic()" [axis]="a" [device]="device" [point]="row"></app-cell-button>
        <app-cell-button-modal *ngIf="ifDevCustom()" [axis]="a" [device]="device" [point]="row"></app-cell-button-modal>
        <app-cell-alert *ngIf="ifAOrW()" [type]="type" [axis]="a" [device]="device" [point]="row" [unit]="unit"></app-cell-alert>
        
      </div>
      <div  *ngIf="ifDevDefault()" class="pl-1 pr-0 pr-sm-2 col-3"
            style="display: inline-block">
        <app-cell [device]="device" [point]="row" [unit]="unit"  [limit]="limit" [data]="data$ | async"
                  (selected)="selectedChange($event)"></app-cell>
      </div>
    </div>
  </div>
</div>
