import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { SensorLogService } from 'src/app/services/sensor-log/sensor-log.service';
import { SensorLog } from 'src/app/shared/sensor-log.model';
import { Row } from '../row.model';

@Component({
  selector: 'app-sensor-log',
  templateUrl: './sensor-log.component.html',
  styles: []
})
export class SensorLogComponent implements OnInit {
  @Input() point: Row;

  public isTester: BehaviorSubject<boolean | null> = new BehaviorSubject(null);
  public isAdminRot: BehaviorSubject<boolean | null> = new BehaviorSubject(null);
  public sensLog: Observable<SensorLog | null>;


  constructor(private service: SensorLogService, auth: AuthorizeService) {
    auth.getUser().pipe(map(u => u.role)).subscribe(roles => {
      if (roles) {
        this.isAdminRot.next(roles.includes("AdminRot"));
        // this.isZmp.next(roles.includes("ZMP"));
        this.isTester.next(roles.includes("Tester"));
      } else {
        this.isAdminRot.next(false);
        // this.isZmp.next(false);
        this.isTester.next(false);
      }
    });
  }


  getCoverage(strength: number) {
    if (strength > -65) {
      return "very good";
    } else if (strength > -76) {
      return "moderate";
    } else if (strength > -86) {
      return "poor";
    } else {
      return "no signal";
    }
  }

  ngOnInit() {
    if (this.point && this.point.Id > 0) {
      this.sensLog = this.service.get2<SensorLog>(this.point.Id, this.point.SensorNumer);
      // this.battery = this.sensLog.pipe(map(p => p.Battery));
    }

  }
}
