import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { CustomNotify } from 'src/app/shared/custom-notify';
import { Device } from 'src/app/shared/device.model';
import { AxisEnume, AxisEnumeDisplay } from 'src/app/shared/enum/axis-enum';
import { Row } from '../row/row.model';
import { NgForm } from '@angular/forms';
import { NewsEnum } from 'src/app/shared/enum/news-enum';

@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
  styles: [
  ]
})
export class ModalAlertComponent implements OnInit {
  @Input()
  device: Device;

  @Input()
  point: Row;

  @Input()
  axis: AxisEnume;

  @Input()
  notify: CustomNotify[];

  title: string;

  selected: BehaviorSubject<CustomNotify> = new BehaviorSubject(null);
  disabledSave: BehaviorSubject<boolean> = new BehaviorSubject(true);
  // isActive: BehaviorSubject<boolean> = new BehaviorSubject(false);
  current: CustomNotify;
  constructor(public activeModal: NgbActiveModal, private http: HttpClient,) {

    // if (this.device && this.point && this.axis)
    //   this.title = `${this.device.Name} - ${this.point.Name} - ${AxisEnumeDisplay[this.axis]}`;
  }

  ngOnInit(): void {
    this.current = new CustomNotify();
    if (this.device && this.point && this.axis)
      this.title = `${this.device.Name} : ${this.point.Name} - ${AxisEnumeDisplay[this.axis]}`;
  }
  //OnSelected(noti: CustomNotify) {
  //  this.selected.next(noti);
  // // this.current = noti;
  //  //this.isActive.next(true);
  //}
  //isActive(noti: CustomNotify) {
  //  return this.selected.value && this.selected.value.Id == noti.Id;
  //}
  isRepeated(ele: boolean) {
    this.current.IsRepeated = ele;
  }
  isIncrease(ele: boolean) {
    this.current.Increase = ele;
  }

  remove(not: CustomNotify) {
    this.http
      .delete<CustomNotify>(`api/Custom/${not.Id}`).subscribe((custom) => {
        this.notify.forEach((element, index) => {
          if (element.Id == custom.Id) this.notify.splice(index, 1);
        });

      });
  }

  save() {
    let not = new CustomNotify();
    not.IdPoint = this.point.Id;
    not.Axis = this.axis;
    not.Create = new Date();
    not.Genre = NewsEnum.Custom;
    not.Value = this.current.Value;
    not.IsRepeated = this.current.IsRepeated;
    not.Increase = this.current.Increase;
    if (not.Value) {
      this.http
        .post<CustomNotify>(`api/Custom`, not)
        .subscribe((noti) => {
          if (noti) {
            this.notify.push(noti);
          }
        });
    }
    this.current = new CustomNotify();
  }
}
