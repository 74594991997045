import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { Device } from 'src/app/shared/device.model';
import { AxisEnume, AxisEnumeDisplay } from 'src/app/shared/enum/axis-enum';
import { NewsEnum } from 'src/app/shared/enum/news-enum';
import { AlertService } from 'src/app/shared/_alert';
import { ModalAlertComponent } from '../modal-alert/modal-alert.component';
import { Row } from '../row/row.model';
import { MeasuringPoint } from "../../../shared/measuring-point.model";
import { CustomNotify } from 'src/app/shared/custom-notify';

@Component({
  selector: 'app-cell-button-modal',
  templateUrl: './cell-button-modal.component.html',
  styles: [
  ]
})
export class CellButtonModalComponent implements OnInit {
  @Input()
  device: Device;

  @Input()
  point: MeasuringPoint | Row;

  @Input()
  axis: AxisEnume;

  notify: CustomNotify[];
  notifyFirst: CustomNotify;
  lastState: BehaviorSubject<NewsEnum | null> = new BehaviorSubject(
    NewsEnum.Lack
  );
  direction: BehaviorSubject<boolean> = new BehaviorSubject(false);
  times: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private http: HttpClient,
    //public app: AppService,
    // public srv: ProfileService,
    private alert: AlertService,
    private modalService: NgbModal) {
    this.notify = [];


  }

  ngOnInit(): void {
    this.http
    .get<CustomNotify[]>(`api/Custom/${this.point.Id}/${this.axis}`)
    .subscribe((noti) => {
      if(noti){
        this.notify = noti;
        if (this.notify.length == 1) {
          this.notifyFirst = this.notify[0];
          this.direction.next(this.notifyFirst.Increase);
          this.times.next(this.notifyFirst.IsRepeated);
        }
      }else{
        this.notify = [];
        this.notifyFirst = null;
      }
    });
    // let randomTemp = Math.random();
    // if (randomTemp > 0.3) {
    //   let temp = new CustomNotify();
    //   temp.Axis = this.axis;
    //   temp.Create = new Date();
    //   temp.Genre = NewsEnum.Msg;
    //   temp.IdPoint = this.point.Id;
    //   temp.Increase = this.axis == AxisEnume.X;
    //   temp.IsRepeated = this.axis == AxisEnume.Y;
    //   temp.Value = 100 * Math.random();
    //   this.notify.push(temp);

    //   if (randomTemp > 0.6) {
    //     let temp = new CustomNotify();
    //     temp.Id = 1;
    //     temp.Axis = this.axis;
    //     temp.Create = new Date();
    //     temp.Genre = NewsEnum.Msg;
    //     temp.IdPoint = this.point.Id;
    //     temp.Increase = false;
    //     temp.IsRepeated = true;
    //     temp.Value = 100 * Math.random();
    //     this.notify.push(temp);

    //   }
    //   if (this.notify.length == 1) {
    //     this.notifyFirst = temp;
    //     this.direction.next(this.notifyFirst.Increase);
    //     this.times.next(this.notifyFirst.IsRepeated);
    //   }
    // } else {

    //   this.notify = [];
    // }
  }

  open() {
    const modalRef = this.modalService.open(ModalAlertComponent, { size: 'xl' });
    modalRef.componentInstance.device = this.device;
    modalRef.componentInstance.point = this.point;
    modalRef.componentInstance.axis = this.axis;
    modalRef.componentInstance.notify = this.notify;
  }

  cssClass(val: NewsEnum) {
    if (!val) return;

    const classes = ["btn", "btn-block"];

    const alertTypeClass = {
      [NewsEnum.Lack]: "btn-light",
      [NewsEnum.Warning]: "btn-warning",
      [NewsEnum.Alert]: "btn-danger",
      [NewsEnum.Msg]: "btn-info",
    };

    classes.push(alertTypeClass[val]);

    return classes.join(" ");
  }
  directionClick() {
    this.direction.next(!this.direction.value);

    this.alert.info(`${this.point.Name} / ${AxisEnumeDisplay[this.axis]}: Custom notifications enabled`);
  }
  timesClick() {
    this.times.next(!this.times.value);
    this.alert.info(`${this.point.Name} / ${AxisEnumeDisplay[this.axis]}: Custom notifications disabled`);
  }
}
