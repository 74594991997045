
import { Routes, RouterModule } from '@angular/router'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { AppModule } from './app.module'
import { HomeComponent } from './home/home.component'
import { AuthorizeGuard } from 'src/api-authorization/authorize.guard'
import { ConnectionComponent } from './settings/connections/connection/connection.component'
import { ConnectionsComponent } from './settings/connections/connections.component'
import { AreaComponent } from './settings/areas/area/area.component'
import { AreasComponent } from './settings/areas/areas.component'
import { DeviceKindComponent } from './settings/device-kinds/device-kind/device-kind.component'
import { ClientComponent } from './client/client.component'
import { DateSelectorComponent } from './default/date-selector/date-selector.component'
import { DefaultComponent } from './default/default.component'
import { DeviceHomeComponent } from './device-home/device-home.component'
import { ProfileComponent } from './profile/profile.component'
import { ConfigComponent } from './settings/configs/config/config.component'
import { ConfigsComponent } from './settings/configs/configs.component'
import { DeviceMeasurConfigComponent } from './settings/criticals/critical/critical.component'
import { DeviceMeasurConfigsComponent } from './settings/criticals/criticals.component'
import { DeviceKindsComponent } from './settings/device-kinds/device-kinds.component'
import { DeviceComponent } from './settings/devices/device/device.component'
import { DevicesComponent } from './settings/devices/devices.component'
import { PointComponent } from './settings/points/point/point.component'
import { PointsComponent } from './settings/points/points.component'
import { SensorComponent } from './settings/sensors/sensor/sensor.component'
import { SensorsComponent } from './settings/sensors/sensors.component'
import { SettingsComponent } from './settings/settings.component'
import { PeriodEnum } from './shared/enum/period-enume'
import { StateEnume } from './shared/enum/state-enum'
import { ProfileModule } from "./profile/profile.module";
import { SystemComponent } from './settings/system/system.component'

export const routes: Routes = [
  { path: "preferences", loadChildren: () => ProfileModule },
  {
    path: "area/:id/:podmiotid",
    component: DefaultComponent,
    canActivate: [AuthorizeGuard],
    children: [
      { path: "", redirectTo: "client", pathMatch: "full" },
      {
        path: "client",
        component: DateSelectorComponent,
        canActivate: [AuthorizeGuard],
        children: [
          { path: "", redirectTo: "l", pathMatch: "full" },
          {
            path: "l",
            component: ClientComponent,
            canActivate: [AuthorizeGuard],
            data: { period: PeriodEnum.Last },
          }
        ]
      },
      {
        path: "supervision",
        component: DateSelectorComponent,
        canActivate: [AuthorizeGuard],
        children: [
          { path: "", redirectTo: "l", pathMatch: "full" },
          {
            path: "l",
            component: ClientComponent,
            data: { permittedRole: "ZMP", state: StateEnume.Test, period: PeriodEnum.Last },
            canActivate: [AuthorizeGuard],
          }
        ]
      },
      {
        path: "alert",
        component: DateSelectorComponent,
        children: [
          { path: "", redirectTo: "l", pathMatch: "full" },
          {
            path: "l",
            component: ClientComponent,
            canActivate: [AuthorizeGuard],
            data: { period: PeriodEnum.Last },
          },
          {
            path: "y/:year",
            component: ClientComponent,
            canActivate: [AuthorizeGuard],
            data: { period: PeriodEnum.Year },
          },
          {
            path: "m/:year/:month",
            component: ClientComponent,
            canActivate: [AuthorizeGuard],
            data: { period: PeriodEnum.Month },
          },
          {
            path: "d/:year/:month/:day",
            component: ClientComponent,
            canActivate: [AuthorizeGuard],
            data: { period: PeriodEnum.Day },
          },
        ]
      },
      {
        path: "warning",
        component: DateSelectorComponent,
        children: [
          { path: "", redirectTo: "l", pathMatch: "full" },
          {
            path: "l",
            component: ClientComponent,
            canActivate: [AuthorizeGuard],
            data: { period: PeriodEnum.Last },
          },
          {
            path: "y/:year",
            component: ClientComponent,
            canActivate: [AuthorizeGuard],
            data: { period: PeriodEnum.Year },
          },
          {
            path: "m/:year/:month",
            component: ClientComponent,
            canActivate: [AuthorizeGuard],
            data: { period: PeriodEnum.Month },
          },
          {
            path: "d/:year/:month/:day",
            component: ClientComponent,
            canActivate: [AuthorizeGuard],
            data: { period: PeriodEnum.Day },
          },
        ]
      },
      { path: "msg/:period", component: ClientComponent },
    ],
  },
  { path: "sensor-config/:podmiotid", component: SystemComponent },
  {
    path: "settings",
    component: SettingsComponent,
    canActivate: [AuthorizeGuard],
    data: { permittedRole: "AdminRot" },
    children: [
      { path: "configs", component: ConfigsComponent },
      {
        path: "config",
        children: [
          { path: "", component: ConfigComponent },
          { path: "edit/:id", component: ConfigComponent },
        ],
      },
      { path: "sensors", component: SensorsComponent },
      {
        path: "sensor", outlet:"details",
        children: [
          { path: "", component: SensorComponent },
          { path: "edit/:id", component: SensorComponent },
        ],
      },
      { path: "points", component: PointsComponent },
      {
        path: "point", outlet:"details",
        children: [
          { path: "", component: PointComponent },
          { path: "edit/:id", component: PointComponent },
        ],
      },
      { path: "devices", component: DevicesComponent },
      {
        path: "device", outlet:"details",
        children: [
          { path: "", component: DeviceComponent },
          { path: "edit/:id", component: DeviceComponent },
        ],
      },
      {
        path: "criticalitys",
        component: DeviceMeasurConfigsComponent,
      },
      {
        path: "criticality",
        children: [
          { path: "", component: DeviceMeasurConfigComponent },
          { path: "edit/:id", component: DeviceMeasurConfigComponent },
        ],
      },
      { path: "kinds", component: DeviceKindsComponent , outlet:"", },
      {
        path: "kind",
        children: [
          { path: "", component: DeviceKindComponent },
          { path: "edit/:id", component: DeviceKindComponent },
        ],
      },
      { path: "areas", component: AreasComponent },
      {
        path: "area", outlet:"details",
        children: [
          { path: "", component: AreaComponent },
          { path: "edit/:id", component: AreaComponent },
        ],
      },
      { path: "connections", component: ConnectionsComponent },
      {
        path: "connection",
        children: [
          { path: "", component: ConnectionComponent },
          { path: "edit/:id", component: ConnectionComponent },
        ],
      },

      { path: "",  redirectTo: "/settings/(areas//details:area)", pathMatch: "full" },//component: AreasComponent, pathMatch: "full" },
      //{ path: 'chart', component: ObjectChartComponent, pathMatch: 'full' },
      //{ path: 'counter', component: CounterComponent },
      ////{ path: 'fetch-data', component: FetchDataComponent, canActivate: [AuthorizeGuard] },
      //{ path: 'object-card', component: ObjectCardComponent } //, canActivate: [AuthorizeGuard] }
    ],
  },
  {
    path: "",
    component: HomeComponent,
    pathMatch: "full",
    canActivate: [AuthorizeGuard],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
// export const routing: ModuleWithProviders<AppModule> = RouterModule.forRoot(routes)
