import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { RmsData } from '../shared/rms-data.model';
import { IPointEventArgs, AxisModel, LegendSettingsModel, ZoomSettingsModel, TooltipSettingsModel, ChartComponent, MajorGridLinesModel, AxisLineModel, FontModel, ITooltipRenderEventArgs, Series, MarkerSettingsModel, ChartAnnotationSettingsModel } from '@syncfusion/ej2-angular-charts';
import { DatePickerComponent } from '@syncfusion/ej2-angular-calendars';
import { PeriodEnum } from '../shared/enum/period-enume';
import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { BsDatepickerConfig, BsDatepickerViewMode } from 'ngx-bootstrap/datepicker';
import { Limit } from '../shared/limit.model';
import { RangeChart } from '../shared/range-chart.model';
import { ChartNews } from '../shared/chart-news.model';
import { BehaviorSubject } from 'rxjs';
import { RmsValueSqlViewng } from '../shared/rms-value-sql-viewng.model';
import { DatePipe } from '@angular/common'
import { AppService } from '../services/app.service';

const compare = (v1: number, v2: number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
@Component({
  selector: 'app-rms-chart',
  templateUrl: './rms-chart.component.html',
  styleUrls: ['./rms-chart.component.css']
})
export class RmsChartComponent implements OnInit {

  public currentPeriod: PeriodEnum = PeriodEnum.Day;
  public startPeriod: Date;
  public endPeriod: Date;
  private $selectedData: RmsData;
  private $limit: Limit[];

  @Output() chartSelect = new EventEmitter();

  @Input()
  set selectedData(val: RmsData) {
    this.$selectedData = val;
    //this.chartTitleRms = "Test";
    this.generateChart();
  }
  get selectedData(): RmsData {
    return this.$selectedData;
  }

  secondary = new Array<RangeChart>();
  first = new Array<RangeChart>();
  firstAsync: BehaviorSubject<RangeChart[]> = new BehaviorSubject<RangeChart[]>(null);

  @Input()
  set limits(val: Limit[]) {
    var direction = "asc";
    this.$limit = [...val].sort((a, b) => {
      const res = compare(a.Id, b.Id);
      return direction === 'asc' ? res : -res;
    }); //val;
  }
  dataSource: BehaviorSubject<RmsValueSqlViewng[] | RmsData[] | null> = new BehaviorSubject(null);
  annotationsList: BehaviorSubject<ChartNews[] | null> = new BehaviorSubject(null);
  currentUser;
  sLog: RmsData[];
  confRaw: Object[];
  bsConfig: Partial<BsDatepickerConfig>;
  minMode: BsDatepickerViewMode = 'month';

  //DatePicker
  @ViewChild('date')
  public Date: DatePickerComponent;
  dateValue: Date = new Date();
  public cssClass = 'e-custom-style';

  @ViewChild('chart')
  public chart: ChartComponent;

  //chart
  public chartTitleRms: string;
  public xAxisRms: AxisModel;
  public yAxisRms: AxisModel;
  public legend: LegendSettingsModel;
  public zoom: ZoomSettingsModel;
  public tooltipSettings: TooltipSettingsModel;
  public crosshair: any;
  public palette: string[];
  public majorGridLines: MajorGridLinesModel;
  public lineStyle: AxisLineModel;
  public labelStyle: FontModel;
  public annotations: ChartAnnotationSettingsModel[] = [];
  public annotationsAsync: BehaviorSubject<ChartAnnotationSettingsModel[] | null> = new BehaviorSubject(null);

  public marker = { visible: false };
  //Seria
  public dataRms: RmsData[];
  public dataRmsAgr: RmsValueSqlViewng[] | RmsData[];
  public markerHide: MarkerSettingsModel = { visible: true, height: 0, width: 0, fill: 'transparent', opacity: 0, border: { color: 'transparent', width: 0 } };

  skok: number;
  //FFT
  data: { amp: number; frq: number; }[];


  set DateValue(value: Date) {
    if (this.dateValue.toDateString() !== value.toDateString()) {
      this.dateValue = value;
      this.generateChart();
    }
    if (this.dateValue) {
      this.setPeriodDates();
    }
  }
  setPeriodDates() {
    switch (this.currentPeriod) {
      case PeriodEnum.Day:
        this.startPeriod = new Date(this.dateValue.getFullYear(), this.dateValue.getMonth(), this.dateValue.getDate());
        this.endPeriod =
          new Date(this.dateValue.getFullYear(), this.dateValue.getMonth(), this.dateValue.getDate() + 1);
        //this.endPeriod.setDate(this.endPeriod.getDate() + 1);
        break;
      case PeriodEnum.Month:
        this.startPeriod = new Date(this.dateValue.getFullYear(), this.dateValue.getMonth(), 1);
        this.endPeriod = new Date(this.dateValue.getFullYear(), this.dateValue.getMonth() + 1, 0);
        //end.setMonth(end.getMonth() + 1);
        break;
      case PeriodEnum.Year:
        this.startPeriod = new Date(this.dateValue.getFullYear(), 0, 1);
        this.endPeriod = new Date(this.dateValue.getFullYear() + 1, 0, 1);
        break;
    }
  }
  get DateValue() {
    return this.dateValue;
  }
  get DateFormat() {
    switch (this.currentPeriod) {
      case PeriodEnum.Day:
        return 'yyyy-MM-dd';

      case PeriodEnum.Month:
        return 'yyyy-MMM';
      case PeriodEnum.Year:
        return 'yyyy';
    }
  }

  constructor(private http: HttpClient,
    private authorize: AuthorizeService,
    public datepipe: DatePipe, public app: AppService) {
    this.authorize.getUser().subscribe(usr => {
      this.currentUser = usr;
    });
  }

  ngOnInit() {
    this.initAxisY();
    this.initStripLines();
    this.initAxisX();

    this.tooltipSettings = { enable: true, shared: true, format: '${series.name}: ${point.y}' };
    this.crosshair = { enable: true, lineType: 'Vertical' };
    this.zoom = {
      enableMouseWheelZooming: false,
      enablePinchZooming: true,
      enableSelectionZooming: true,
      mode: "X"
    };
    this.legend = {
      visible: true
    };
    //  this.tooltipSettings = { enable: true, shared: true, format: '${series.name} : ${point.x} : ${point.y}' };

    this.majorGridLines = { width: 0 };
    this.lineStyle = { width: 2, color: "#808080" };
    this.labelStyle = { color: "orange" }
    this.marker = { visible: true };
    this.palette = ["blue", "orange", "#6FAAB0", "#C4C24A"];
  }

  pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }

  private getYMaxValue() {
    const limit = this.$limit.find(p => p.End === null);
    const ret = (limit.Second + (limit.Second - limit.First));

    if (this.currentPeriod === PeriodEnum.Day || this.currentPeriod === PeriodEnum.Last) {
      if (this.dataRms && this.dataRms.length > 0) {
        const max = Math.max(...this.dataRms.map(o => o.Value));

        let values = [max, ret];

        return Math.max(...values);
        //if (max > ret)
        //  return max;
      }
    } else {
      if (this.dataRmsAgr && this.dataRmsAgr.length > 0) {
        if (this.dataRmsAgr as RmsValueSqlViewng[]) {
          var ele = this.dataRmsAgr as RmsValueSqlViewng[];
          const max = Math.max(...ele.map(o => o.Value));
          const max2 = Math.max(...ele.map(o => o.Max));
          let values = [max, max2, ret];
          //if (max > ret)
          //  return max;
          return Math.max(...values);
        }
      }
    }
    return ret;
  }

  private initAxisX() {
    if (this.startPeriod && this.endPeriod) {
      switch (this.currentPeriod) {
        case PeriodEnum.Day:
          this.xAxisRms = {
            title: 'Time',
            intervalType: 'Hours',
            valueType: 'DateTime',
            labelFormat: 'HH:mm',
            minimum: this.startPeriod,
            maximum: this.endPeriod
          };
          break;
        case PeriodEnum.Month:
          this.xAxisRms = {
            title: 'Time',
            intervalType: 'Days',
            valueType: 'DateTime',
            labelFormat: 'dd MMM',
            minimum: this.startPeriod,
            maximum: this.endPeriod
          };
          break;
        case PeriodEnum.Year:
          this.xAxisRms = {
            title: 'Time',
            intervalType: 'Months',
            valueType: 'DateTime',
            labelFormat: 'MMM dd',
            minimum: this.startPeriod,
            maximum: this.endPeriod
          };
          break;
      }
    }
  }

  private initAxisY() {
    const max = this.getYMaxValue();
    let temp = max / 6;
    let temp2 = 2;
    let interval = max > (6 * temp2) ? (Math.round(temp / 10) * 10) : temp2;
    interval = interval > 2 ? interval : 2;
    this.yAxisRms = {
      title: (this.$selectedData.Unit == 86 ? 'Velocity [mm/s]' : (this.$selectedData.Unit == 65 ? 'Acceleration [m/s2]' : '')),
      lineStyle: { width: 2, color: "#808080" },
      crosshairTooltip: { enable: true },
      interval: interval,
      maximum: (max + interval / 2),
      // stripLines: [
      //   { start: 4, end: 300, color: 'Red', visible: true, zIndex: 'Behind', opacity: 0.3 },
      //   { start: 3, end: 4, color: 'Orange', visible: true, zIndex: 'Behind', opacity: 0.2 }]
    };
  }
  private initStripLines() {
    const max = +this.yAxisRms.maximum + this.yAxisRms.interval;//+this.yAxisRms.maximum;//this.getYMaxValue();

    this.first = [];
    this.secondary = [];

    for (let i = 0; i < this.$limit.length; i++) {
      var dtStart = new Date(this.$limit[i].Start);
      var dtEnd = this.$limit[i].End == null ? null : new Date(this.$limit[i].End);

      //poczatek cześniej end wieksze od start
      if (dtStart < this.startPeriod && (dtEnd > this.startPeriod || dtEnd == null)) {
        let f = {
          SendDate: this.datepipe.transform(this.startPeriod, "yyyy-MM-dd HH:mm"),
          high: this.$limit[i].Second,
          low: this.$limit[i].First
        };
        this.first.push(f);
        let s = {
          SendDate: this.datepipe.transform(this.startPeriod, "yyyy-MM-dd HH:mm"),
          high: max,
          low: this.$limit[i].Second
        };
        this.secondary.push(s);


        if (dtEnd != null && dtEnd < this.endPeriod) {
          this.supplementingLimitData(this.first, f, dtEnd);
          this.supplementingLimitData(this.secondary, s, dtEnd);
          //konec w przedziale
          this.first.push({ SendDate: this.datepipe.transform(dtEnd, "yyyy-MM-dd HH:mm"), high: this.$limit[i].Second, low: this.$limit[i].First });
          this.secondary.push({ SendDate: this.datepipe.transform(dtEnd, "yyyy-MM-dd HH:mm"), high: max, low: this.$limit[i].Second });
        } else {
          this.supplementingLimitData(this.first, f, this.endPeriod);
          this.supplementingLimitData(this.secondary, s, this.endPeriod);
          //koniec poza przedziałem
          this.first.push({ SendDate: this.datepipe.transform(this.endPeriod, "yyyy-MM-dd HH:mm"), high: this.$limit[i].Second, low: this.$limit[i].First });
          this.secondary.push({ SendDate: this.datepipe.transform(this.endPeriod, "yyyy-MM-dd HH:mm"), high: max, low: this.$limit[i].Second });
        }
      }

      //poczatek w przedzile
      if (dtStart > this.startPeriod && dtStart < this.endPeriod) {
        let f = {
          SendDate: this.datepipe.transform(dtStart, "yyyy-MM-dd HH:mm"),
          high: this.$limit[i].Second,
          low: this.$limit[i].First
        };
        this.first.push(f);
        let s = {
          SendDate: this.datepipe.transform(dtStart, "yyyy-MM-dd HH:mm"),
          high: max,
          low: this.$limit[i].Second
        };
        this.secondary.push(s);

        if (dtEnd != null && dtEnd < this.endPeriod) {
          this.supplementingLimitData(this.first, f, dtEnd);
          this.supplementingLimitData(this.secondary, s, dtEnd);
          //koniec w przedziale
          this.first.push({ SendDate: this.datepipe.transform(dtEnd, "yyyy-MM-dd HH:mm"), high: this.$limit[i].Second, low: this.$limit[i].First });
          this.secondary.push({ SendDate: this.datepipe.transform(dtEnd, "yyyy-MM-dd HH:mm"), high: max, low: this.$limit[i].Second });
        } else {
          this.supplementingLimitData(this.first, f, this.endPeriod);
          this.supplementingLimitData(this.secondary, s, this.endPeriod);
          //koniec poza przedziałem
          this.first.push({ SendDate: this.datepipe.transform(this.endPeriod, "yyyy-MM-dd HH:mm"), high: this.$limit[i].Second, low: this.$limit[i].First });
          this.secondary.push({ SendDate: this.datepipe.transform(this.endPeriod, "yyyy-MM-dd HH:mm"), high: max, low: this.$limit[i].Second });
        }
      }
    }
    this.firstAsync.next(this.first);
    //console.log("First");
    //console.log(this.first);
    //console.log(this.secondary);
  }
  private supplementingLimitData(data: RangeChart[], limit: RangeChart, end: Date) {
    let start = new Date(limit.SendDate);
    let dif = end.getTime() - start.getTime();
    switch (this.currentPeriod) {
      case PeriodEnum.Day:
        //60*60*1000=3 600 000
        const hours = +(dif / (3600000)).toFixed(0);
        for (let i = 1; i < hours; i++) {
          data.push({
            SendDate: this.datepipe.transform(new Date(start.getFullYear(),
              start.getMonth(),
              start.getDate(),
              start.getHours() + i), "yyyy-MM-dd HH:mm"),
            high: limit.high,
            low: limit.low
          });
        }
        break;
      case PeriodEnum.Month:
        const day = +(dif / (3600000 * 24)).toFixed(0);
        for (let i = 1; i < day; i++) {
          if (i === 1) {
            const hours = +(dif / (3600000)).toFixed(0);
            for (let h = 1; h < hours; h++) {
              data.push({
                SendDate: this.datepipe.transform(new Date(start.getFullYear(),
                  start.getMonth(),
                  start.getDate(),
                  start.getHours() + h), "yyyy-MM-dd HH:mm"),
                high: limit.high,
                low: limit.low
              });
            }
          }
          data.push({
            SendDate: this.datepipe.transform(new Date(start.getFullYear(),
              start.getMonth(),
              start.getDate() + i), "yyyy-MM-dd HH:mm"),
            high: limit.high,
            low: limit.low
          });
        }
        break;
      case PeriodEnum.Year:
        const month = this.monthDiff(start, end);
        for (let i = 1; i < month; i++) {
          if (i === 1) {
            const day = +(dif / (3600000 * 24)).toFixed(0);
            for (let d = 1; d < day; d++) {
              if (d === 1) {
                const hours = +(dif / (3600000)).toFixed(0);
                for (let h = 1; h < hours; h++) {
                  data.push({
                    SendDate: this.datepipe.transform(new Date(start.getFullYear(),
                      start.getMonth(),
                      start.getDate(),
                      start.getHours() + h), "yyyy-MM-dd HH:mm"),
                    high: limit.high,
                    low: limit.low
                  });
                }
              }
              data.push({
                SendDate: this.datepipe.transform(new Date(start.getFullYear(),
                  start.getMonth(),
                  start.getDate() + d), "yyyy-MM-dd HH:mm"),
                high: limit.high,
                low: limit.low
              });
            }
          }
          data.push({
            SendDate: this.datepipe.transform(new Date(start.getFullYear(),
              start.getMonth() + i,
              1), "yyyy-MM-dd HH:mm"),
            high: limit.high,
            low: limit.low
          });
        }
        break;
    }
  }
  public monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }
  public tooltipRender(args: ITooltipRenderEventArgs): void {
    const series = (args.series as Series);
    if (series.name === "warning" || series.name === "alert") {//.seriesElement.classList[0] === 'chart-container_ej2_deselected') {
      args.cancel = true;
    } else if (series.name === "temp") {
      args.text = `${series.name}: ${(+args.point.y).toFixed(0)}`;
    } else if (series.name === "rms") {
      args.text = `${series.name}: ${(+args.point.y).toFixed(1)}`;
    }
  }


  previewDate() {
    let tempDate = new Date(this.dateValue.getFullYear(), this.dateValue.getMonth(), this.dateValue.getDate());
    switch (this.currentPeriod) {
      case PeriodEnum.Day:
        tempDate.setDate(tempDate.getDate() - 1);
        break;
      case PeriodEnum.Month:
        tempDate.setMonth(tempDate.getMonth() - 1);
        break;
      case PeriodEnum.Year:
        tempDate.setFullYear(tempDate.getFullYear() - 1);
        break;
    }
    this.DateValue = tempDate;
  }
  nextDate() {
    let tempDate = new Date(this.dateValue.getFullYear(), this.dateValue.getMonth(), this.dateValue.getDate());
    switch (this.currentPeriod) {
      case PeriodEnum.Day:
        tempDate.setDate(tempDate.getDate() + 1);
        break;
      case PeriodEnum.Month:
        tempDate.setMonth(tempDate.getMonth() + 1);
        break;
      case PeriodEnum.Year:
        tempDate.setFullYear(tempDate.getFullYear() + 1);
        break;
    }
    this.DateValue = tempDate;
  }

  dayClick() {
    this.currentPeriod = PeriodEnum.Day;
    this.setPeriodDates();

    this.generateChart();
    this.minMode = 'day';
    this.bsConfig = Object.assign({}, {
      minMode: this.minMode
    });
  }

  monthClick() {
    this.currentPeriod = PeriodEnum.Month;
    this.setPeriodDates();
    this.generateChart();
    this.minMode = 'month';
    this.bsConfig = Object.assign({}, {
      minMode: this.minMode
    });
  }

  yearClick() {
    this.currentPeriod = PeriodEnum.Year;
    this.setPeriodDates();
    this.generateChart();
    this.minMode = 'year';
    this.bsConfig = Object.assign({}, {
      minMode: this.minMode
    });
  }

  deposit(event: DatePickerComponent) {
    if (this.dateValue.toDateString() !== this.Date.value.toDateString()) {
      this.dateValue = this.Date.value;
      this.generateChart();
    }
  }


  pointClick(args: IPointEventArgs) {
    if (this.currentPeriod === PeriodEnum.Day) {
      this.selectedData = this.dataRms[args.pointIndex];
    }
    this.chartSelect.emit(this.selectedData);
  }
  getMinMaxName() {
    if (this.currentPeriod !== PeriodEnum.Day && this.currentPeriod !== PeriodEnum.Last) {
      return "rms (min ÷ max)";
    } else {
      return '';
    }
  }
  getTempMinMaxName() {
    if (this.currentPeriod !== PeriodEnum.Day && this.currentPeriod !== PeriodEnum.Last) {
      return "temp (min ÷ max)";
    } else {
      return '';
    }
  }
  getAnnotations() {
    return this.annotations;
  }
  getChartNews() {
    this.annotations = [];
    this.annotationsAsync.next(null);
    this.annotationsList.next(null);
    if (this.currentPeriod == PeriodEnum.Day) {
      this.http.get<ChartNews[]>(`rms/ChartNews/${this.$selectedData.IdPoint}/${this.currentPeriod}/${this.$selectedData.Axis}/${this.$selectedData.Unit}/${(formatDate(this.dateValue, 'yyyy-MM-dd', 'en-US'))}`)
        .subscribe(news => {
          news.forEach(r => {
            const ele: ChartAnnotationSettingsModel = {};
            if (r.Genre === 1) {
              ele.content = `<i class="${r.Cause === "[^]" ? "fa" : "far"} fa-bell text-danger fa-lg"> </i>`;
            }
            if (r.Genre === 2) {
              ele.content = `<i class="${r.Cause === "[^]" ? "fa" : "far"} fa-bell text-warning fa-lg"> </i>`;
            }
            if (r.Genre === 3) {
              ele.content = `<i class="${r.Cause === "[^]" ? "fa" : "far"} fa-bell text-primary fa-lg"> </i>`;
            }
            ele.region = "Series";
            ele.coordinateUnits = "Point";

            ele.x = this.datepipe.transform(r.TimeStamp, 'yyyy-MM-dd HH:mm:ss');
            ele.y = r.Value;
            ele.verticalAlignment = "Top";
            this.annotations.push(ele);
          });

          this.annotationsAsync.next(this.annotations);
          this.annotationsList.next(news);
        }, errorObj => console.error(errorObj));
    }
  }

  generateChart() {
    // this.annotations = [];
    // this.annotationsAsync.next(this.annotations);
    this.dataRmsAgr = [];
    this.dataSource.next(null);
    this.getChartNews();
    if (this.currentPeriod === PeriodEnum.Day || this.currentPeriod === PeriodEnum.Last) {
      //this.dataRms = [];
      this.dataRmsAgr = [];
      let url = `rms/rms/${this.$selectedData.IdPoint}/${this.currentPeriod}/${this.$selectedData.Axis}/${(formatDate(
        this.dateValue,
        'yyyy-MM-dd',
        'en-US'))}/${this.$selectedData.Unit.toString()}`;
      this.http.get<RmsData[] | null>(url).subscribe(rmss => {
        if (rmss) {
          this.dataRms = rmss;
          this.dataRmsAgr = rmss;
          this.dataSource.next(rmss);
          console.log(rmss);


          this.initAxisY();
          this.initStripLines();
          this.initAxisX();
        }
      }, errorObj => {
        console.error(errorObj);
      });
    } else {

      this.http.get<RmsValueSqlViewng[] | RmsData[] | null>(`rms/view/${this.$selectedData.IdPoint}/${this.currentPeriod}/${this.$selectedData.Axis}/${this.dateValue.getFullYear()}/${this.dateValue.getMonth() + 1}/${this.$selectedData.Unit}`).subscribe(rmss => {
        if (rmss) {
          this.dataRmsAgr = rmss;
          this.dataSource.next(rmss);
          this.initAxisY();
          this.initStripLines();
          this.initAxisX();
        }
      }, errorObj => console.error(errorObj));
    }
  }

}
