import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";

import { routing } from './profile.routing';

import { ProfileComponent } from './profile.component';
import { CustomAlertComponent } from './custom-alert/custom-alert.component';
import { ListOfAreaComponent } from './list-of-area/list-of-area.component';
import { ReportEmissionComponent } from './report-emission/report-emission.component';
import { GongAlertComponent } from './gong-alert/gong-alert.component';
import { ModalPointsComponent } from './modal-points/modal-points.component';
import { AlertModule } from '../shared/_alert';
import { RowViewComponent } from './device/row/row.component';
import { DeviceViewService } from './device/device-view.service';
import { HeadingComponent } from './device/heading/heading.component';
import { StandardComponent } from './device/standard/standard.component';
import { CellButtonComponent } from './device/cell-button/cell-button.component';
import { CellButtonModalComponent } from './device/cell-button-modal/cell-button-modal.component';
import { ModalAlertComponent } from './device/modal-alert/modal-alert.component';
import { SignalComponent } from './svg/signal/signal.component';
import { SensorLogComponent } from './device/row/sensor-log/sensor-log.component';
import { CellComponent } from './device/cell/cell.component';
import { CellAlertComponent } from './device/cell-alert/cell-alert.component';
import { FormsModule } from '@angular/forms';

import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { BatteryComponent } from './device/row/battery/battery.component';


@NgModule({
  declarations: [
    ProfileComponent,
    ReportEmissionComponent,
    CustomAlertComponent,
    ListOfAreaComponent,
    GongAlertComponent,
    ModalPointsComponent,
    StandardComponent,
    HeadingComponent,
    RowViewComponent,
    CellButtonComponent,
    CellButtonModalComponent,
    ModalAlertComponent,
    SignalComponent,
    SensorLogComponent,
    CellComponent,
    CellAlertComponent,
    BatteryComponent

  ],
  imports: [
    CommonModule,
    FormsModule,
    routing,
    AlertModule,
    MatSlideToggleModule
  ],
  exports: [
    RouterModule,
    HeadingComponent,
    RowViewComponent,
    SignalComponent,
    SensorLogComponent,
    BatteryComponent
  ],
  providers: [
    DeviceViewService]
})
export class ProfileModule { }
