import { IdBase } from "./ID.base";

export class MeasuringConfig implements IdBase {
  Id: number;
  IdGroup: number;
  To: Date;
  From: Date
  Criticality: string;
  Description: string;
  Interval: number;
  Number: number;


  GetEditUrl: string;
  
  constructor() {
    this.Id = 0;
    this.IdGroup = 0;
    this.From = new Date();
    this.To = null;
    this.Description = '';
    this.GetEditUrl = 'device-criticality/edit';
  }


}
