<span class="btn btn-info mr-1"
      (click)="previewDate()">
  <span class="fa fa-chevron-left"></span>
</span>
<span type="text"
      placeholder="yyyy-MM-dd"
      class="btn btn-info text-white"
      #dp="bsDatepicker"
      [bsConfig]="bsConfig"
      bsDatepicker
      [(bsValue)]="DateValue">{{ DateValue | date: DateFormat }}</span>
<span class="btn btn-info ml-1"
      (click)="nextDate()">
  <span class="fa fa-chevron-right"></span>
</span>

<span class="btn btn-primary ml-1"
      *ngIf="this.currentPeriod!=1"
      (click)="dayClick()">
  D
</span>
<span class="btn btn-primary ml-1"
      *ngIf="this.currentPeriod!=3"
      (click)="monthClick()">
  M
</span>
<span class="btn btn-primary ml-1"
      *ngIf="this.currentPeriod!=4"
      (click)="yearClick()">
  Y
</span>
<br />
<div>
  <ejs-chart #chart
             selectionMode="Point"
             [primaryXAxis]="xAxisRms"
             [primaryYAxis]="yAxisRms"
             [title]="chartTitleRms"
             [legendSettings]="legend"
             [crosshair]="crosshair"
             [tooltip]="tooltipSettings"
             [zoomSettings]="zoom"
             (pointClick)="pointClick($event)"
             [annotations]="annotationsAsync | async"
             (tooltipRender)="tooltipRender($event)">
    <e-axes>
      <e-axis rowIndex="1"
              name="yAxis2"
              opposedPosition="true">
      </e-axis>
      <e-axis rowIndex="2"
              name="yAxis1"
              labelFormat="{value}°C"
              opposedPosition="true"
              [minimum]="-20"
              [interval]="20"
              [maximum]="58"
              [lineStyle]="lineStyle"
              [labelStyle]="labelStyle">
      </e-axis>
    </e-axes>
    <e-rows>
      <e-row height="70%"></e-row>
      <e-row height="5%"></e-row>
      <e-row height="10%"></e-row>
    </e-rows>
    <!--<e-annotations>
      <e-annotation  content='70 Gold Medals' region='Series' coordinateUnits='Point' x='2020-12-30 10:00:00' y=10>
      </e-annotation>
      <e-annotation  content='70 Gold Medals' region='Series' coordinateUnits='Point' x='2020-12-30 20:00:00' y=10>
      </e-annotation>
  </e-annotations>-->
    <e-series-collection>
      <e-series type='Line'
                [dataSource]='dataSource | async'
                xName='SendDate'
                yName='Temp'
                name='temp'
                fill="orange"
                yAxisName='yAxis1'
                [marker]='marker'></e-series>

      <e-series [dataSource]='dataSource | async'
                type='RangeArea'
                xName='SendDate'
                high='MaxTemp'
                low='MinTemp'
                [name]='getTempMinMaxName()'
                fill='orange'
                opacity=0.2
                tooltipFormat="${series.name}:  (${point.low} ÷ ${point.high})"
                yAxisName='yAxis1'></e-series>

      <e-series type='Line'
                name='rms'
                [dataSource]="dataSource | async"
                xName='SendDate'
                [marker]='marker'
                fill='deepskyblue'
                yName='Value'></e-series>

      <e-series [dataSource]='dataSource | async'
                type='RangeArea'
                xName='SendDate'
                high='Max'
                low='Min'
                tooltipFormat='${series.name}:  (${point.low} ÷ ${point.high})'
                fill='deepskyblue'
                [name]='getMinMaxName()'
                opacity=0.2></e-series>
      <e-series [dataSource]='secondary'
                type='RangeArea'
                xName='SendDate'
                high='high'
                low='low'
                fill='red'
                minRadius='0'
                maxRadius='0'
                size='0'
                enableTooltip='false'
                [marker]='markerHide'
                name='alert'
                opacity=0.3></e-series>

      <e-series [dataSource]='firstAsync | async'
                type='RangeArea'
                xName='SendDate'
                high='high'
                low='low'
                fill='orange'
                minRadius='0'
                maxRadius='0'
                size='0'
                enableTooltip='false'
                [marker]='markerHide'
                name='warning'
                opacity=0.3></e-series>
    </e-series-collection>
  </ejs-chart>
</div>

<!--<ul class="list-unstyled"
    *ngFor="let anno  of annotationsList | async">
  <li>
    <a href="#"
       [class.text-danger]="anno.Genre == 1"
       [class.text-warning]="anno.Genre == 2"
       [class.text-info]="anno.Genre == 3">
      <strong>{{ anno.TimeStamp | date: 'yyyy-MM-dd HH:mm:ss' }}</strong> - {{ anno.Msg }}</a>
  </li>
</ul>-->
