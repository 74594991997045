<div class="card">
  <div class="card-header">
    Device
    <!-- <a  routerLink="points" class="btn btn-primary float-right" >Go to point</a> -->
    <a class="float-right"
       [routerLink]="['/settings', { outlets: { primary: ['points'], details: ['point'] } }]">
      Go to point
    </a>
  </div>
  <div class="card-body">
    <form #f="ngForm"
          *ngIf="item"
          (ngSubmit)="submit(f)">

      <input type="hidden"
             [ngModel]="item.Id"
             #Id="ngModel"
             name="Id"
             id="Id"
             name="Id" />
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="IdArea">Area:</label>
            <select class="form-control"
                    [(ngModel)]="item.IdArea"
                    #IdArea="ngModel"
                    name="IdArea"
                    id="IdArea">
              <option [ngValue]="0"> - Select - </option>
              <option *ngFor="let current of areas"
                      [ngValue]="current.Id">{{ current.Name }}</option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="Name">Name</label>
            <input class="form-control"
                   [(ngModel)]="item.Name"
                   #Name="ngModel"
                   name="Name"
                   id="Name">

          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="IdUnifiedLimit">ISO Limits:</label>

            <select class="form-control"
                    [(ngModel)]="item.IdUnifiedLimit"
                    #IdUnifiedLimit="ngModel"
                    name="IdUnifiedLimit"
                    id="IdUnifiedLimit">
              <option [ngValue]="0"> - Select - </option>
              <option *ngFor="let current of unifiedLimits"
                      [ngValue]="current.Id">{{ current.Name }}</option>
            </select>

          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="IdKindConfig">Kind:</label>
            <div class="input-group">
              <select class="form-control"
                      [(ngModel)]="item.IdKindConfig"
                      #IdKindConfig="ngModel"
                      name="IdKindConfig"
                      id="IdKindConfig">
                <option [ngValue]="0"> - Select - </option>
                <option *ngFor="let current of kindConfigs"
                        [ngValue]="current.Id">{{ current.Name }}</option>
              </select>
              <div class="input-group-append">
                <a class="btn btn-outline-secondary"
                        routerLink="/settings/kinds"
                        >Go to</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="IdMeasurConfig">Critical:</label>
            <div class="input-group">
              <select class="form-control"
                      [(ngModel)]="item.IdMeasurConfig"
                      #IdMeasurConfig="ngModel"
                      name="IdMeasurConfig"
                      id="IdMeasurConfig">
                <option [ngValue]="0"> - Select - </option>
                <option *ngFor="let current of measurConfigs"
                        [ngValue]="current.Id">{{ current.Description }}</option>
              </select>
              <div class="input-group-append">
                <a class="btn btn-outline-secondary" routerLink="/settings/criticalitys"
                        >Go to</a>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div *ngIf="isTester | async"
           class="form-group">
        <label for="detail">Json item (preview)</label>
        <textarea class="form-control"
                  disabled
                  rows="10">
                        {{ f.value | json }}
                    </textarea>
      </div>
      <!-- <button type="submit"
                    class="btn btn-success float-right"
                    [disabled]="!f.valid"
                    *ngIf="!isDetails"><i class="far fa-save"></i> Zapisz</button> -->

      <button type="submit"
              class="btn btn-success float-right"
              [disabled]="!f.valid"
              *ngIf="f.touched">
        <i class="far fa-save"></i> Zapisz
      </button>
    </form>
  </div>
</div>
