import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { Injectable } from '@angular/core';
import { BulletChartAllModule, Point } from '@syncfusion/ej2-angular-charts';
import { BehaviorSubject } from 'rxjs';
import { BooleanLiteral } from 'typescript';
import { AreasComponent } from '../settings/areas/areas.component';
import { Area } from '../shared/area.model';
import { Device } from '../shared/device.model';
import { StateEnume } from '../shared/enum/state-enum';
import { MeasuringPoint } from '../shared/measuring-point.model';
import { Podmiot } from '../shared/podmiot.model';
import { Sensor } from '../shared/sensor.model';

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  //selectedIdPodmiot: BehaviorSubject<number | null> = new BehaviorSubject(null);
  //selectedIdArea: BehaviorSubject<number | null> = new BehaviorSubject(null);
  //selectedIdDevice: BehaviorSubject<number | null> = new BehaviorSubject(null);
  //selectedIdPoint: BehaviorSubject<number | null> = new BehaviorSubject(null);
  //selectedIdSensor: BehaviorSubject<number | null> = new BehaviorSubject(null);
  dataChange: BehaviorSubject<boolean> = new BehaviorSubject(false);

  filterChange: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private $archival: boolean = false;
  public set archival(val: boolean) {
    this.$archival = val;
    this.filterChange.next(true);
  }
  public get archival(){
    return this.$archival;
  }

  selectedPodmiot: BehaviorSubject<Podmiot | null> = new BehaviorSubject(null);
  selectedArea: BehaviorSubject<Area | null> = new BehaviorSubject(null);
  selectedDevice: BehaviorSubject<Device | null> = new BehaviorSubject(null);
  selectedPoint: BehaviorSubject<MeasuringPoint | null> = new BehaviorSubject(null);
  selectedSensor: BehaviorSubject<Sensor | null> = new BehaviorSubject(null);

  private $areas: Area[];
  orderAreas: BehaviorSubject<Area[]> = new BehaviorSubject(null);
  public set areas(value: Area[]) {
    this.$areas = value;
    this.orderAreas.next([...(this.$areas)].sort((a, b) => {
      const res = compare(a["Name"], b["Name"]);
      //return direction === 'asc' ? res : -res;
      return res;
    }));
  }
  public get areas() {
    return this.$areas;
  }

  private $devices: Device[];
  orderDevices: BehaviorSubject<Device[]> = new BehaviorSubject(null);
  public set devices(value: Device[]) {
    this.$devices = value;
    this.orderDevices.next([...(this.getDeviceList())].sort((a, b) => {
      const res = compare(a["Name"], b["Name"]);
      //return direction === 'asc' ? res : -res;
      return res;
    }));
  }
  public get devices() {
    return this.$devices;
  }

  private $points: MeasuringPoint[];
  orderPoints: BehaviorSubject<MeasuringPoint[]> = new BehaviorSubject(null);
  public set points(value: MeasuringPoint[]) {
    this.$points = value;
    this.orderPoints.next([...(this.getPointList())].sort((a, b) => {
      const res = compare(a["Name"], b["Name"]);
      //return direction === 'asc' ? res : -res;
      return res;
    }));
  }
  public get points() {
    return this.$points;
  }
  $sensors: Sensor[];
  orderSensors: BehaviorSubject<Sensor[]> = new BehaviorSubject(null);
  public set sensors(value: Sensor[]) {
    this.$sensors = value;
    this.orderSensors.next([...(this.getSensorList())].sort((a, b) => {
      const res = compare(a["Number"], b["Number"]);
      //return direction === 'asc' ? res : -res;
      return res;
    }));
  }
  public get sensors() {
    return this.$sensors;
  }




  active: BehaviorSubject<string | null> = new BehaviorSubject(null);

  state: StateEnume = StateEnume.Details;

  constructor() {
    this.state = StateEnume.Details;

    // this.selectedIdPodmiot.subscribe(selectedIdPodmiot => {
    //   this.selectedSensor.next(null);
    //   if (this.orderSensors.value) {
    //     this.orderSensors.next([...(selectedIdPodmiot ? this.$sensors.filter(p => p.IdPodmiot == selectedIdPodmiot) : this.$sensors)].sort((a, b) => {
    //       const res = compare(a["Number"], b["Number"]);
    //       //return direction === 'asc' ? res : -res;
    //       return res;
    //     }));
    //   }
    // });
    this.selectedArea.subscribe(selectedArea => {
      this.selectedDevice.next(null);
      this.selectedSensor.next(null);
      if (this.orderDevices.value) {
        this.orderDevices.next([...(this.getDeviceList())].sort((a, b) => {
          const res = compare(a["Name"], b["Name"]);
          //return direction === 'asc' ? res : -res;
          return res;
        }));
      }
      if (this.orderPoints.value) {
        this.orderPoints.next([...(this.getPointList())].sort((a, b) => {
          const res = compare(a["Name"], b["Name"]);
          //return direction === 'asc' ? res : -res;
          return res;
        }));
      }
      if (this.orderSensors.value) {
        this.orderSensors.next([...(this.getSensorList())].sort((a, b) => {
          const res = compare(a["Number"], b["Number"]);
          //return direction === 'asc' ? res : -res;
          return res;
        }));
      }
    });

    this.selectedDevice.subscribe(selectedDevice => {
      this.selectedPoint.next(null);
      if (this.orderPoints.value) {
        this.orderPoints.next([...(this.getPointList())].sort((a, b) => {
          const res = compare(a["Name"], b["Name"]);
          //return direction === 'asc' ? res : -res;
          return res;
        }));
      }
    })
  }
  getPointList() {
    if (this.selectedDevice.value) { return this.points.filter(p => p.IdDevice == this.selectedDevice.value.Id) }
    else if (this.selectedArea.value) {
      let lst = this.devices.filter(p => p.IdArea == this.selectedArea.value.Id);
      return this.points.filter(p => lst.find(dev => dev.Id == p.IdDevice));
    }
    else {
      return this.points;
    }
  }
  getDeviceList() {
    if (this.selectedArea.value) { return this.devices.filter(p => p.IdArea == this.selectedArea.value.Id) }
    else {
      return this.devices;
    }
  }
  getSensorList() {
    if (this.selectedArea.value) { return this.sensors.filter(p => p.IdPodmiot == this.selectedArea.value.PodmiotId) }
    else {
      return this.sensors;
    }
  }


  changeArea(area: Area) {
    this.selectedArea.next(area);
    //this.selectedIdPodmiot.next(area.PodmiotId);
  }
  changeDevice(device: Device) {
    this.selectedDevice.next(device);
  }
  changePoint(point: MeasuringPoint) {
    this.selectedPoint.next(point);
  }
  changeSensor(sensor: Sensor) {
    this.selectedSensor.next(sensor);
  }
}
