import { AxisEnume } from "./enum/axis-enum";
import { NewsEnum } from "./enum/news-enum";

export class CustomNotify {

  Id: number;
  Axis: AxisEnume;
  Genre: NewsEnum;
  Value: number;
  Increase: boolean = true;
  IsRepeated: boolean = false;
  Create: Date;
  Send: Date;
  IdPoint: number;
  VibroUserId: number;

}
