import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ErrorHandler } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { AppComponent } from "./app.component";
import { NavMenuComponent } from "./nav-menu/nav-menu.component";
import { CounterComponent } from "./test/counter/counter.component";
import { FetchDataComponent } from "./test/fetch-data/fetch-data.component";
import { ApiAuthorizationModule } from "src/api-authorization/api-authorization.module";
import { AuthorizeInterceptor } from "src/api-authorization/authorize.interceptor";
import { ObjectCardComponent } from "./test/object-card/object-card.component";
import { ObjectChartComponent } from "./test/object-chart/object-chart.component";

import { DatePickerModule } from "@syncfusion/ej2-angular-calendars";
import { TabModule } from "@syncfusion/ej2-angular-navigations";
import {
  ChartModule,
  ZoomService,
  DateTimeCategoryService,
  SelectionService,
  StripLineService,
  CrosshairService,
  RangeAreaSeriesService,
  ChartAnnotationService,
} from "@syncfusion/ej2-angular-charts";
import {
  CategoryService,
  DateTimeService,
  LineSeriesService,
  LegendService,
} from "@syncfusion/ej2-angular-charts";
import {
  DataLabelService,
  TooltipService,
  StackingStepAreaSeriesService,
  StepAreaSeriesService ,
} from "@syncfusion/ej2-angular-charts";
import { ConfigFormComponent } from "./test/config-form/config-form.component";
import { TemplateDrivenFormComponent } from "./test/template-driven-form/template-driven-form.component";
import { ConfigService } from "./services/config/config.service";
import { AppErrorHandler } from "./common/app-error-handler";
import { AlertModule } from "./shared/_alert";

import { ConfigsComponent } from "./settings/configs/configs.component";
import { ConfigComponent } from "./settings/configs/config/config.component";
import { DevicesComponent } from "./settings/devices/devices.component";
import { DeviceComponent } from "./settings/devices/device/device.component";
import { SensorsComponent } from "./settings/sensors/sensors.component";
import { SensorComponent } from "./settings/sensors/sensor/sensor.component";
import { PointsComponent } from "./settings/points/points.component";
import { PointComponent } from "./settings/points/point/point.component";
import { PointService } from "./services/point/point.service";
import { DeviceService } from "./services/device/device.service";
import { DeviceKindsComponent } from "./settings/device-kinds/device-kinds.component";
import { DeviceKindComponent } from "./settings/device-kinds/device-kind/device-kind.component";
import { DeviceMeasurConfigsComponent } from "./settings/criticals/criticals.component";
import { DeviceMeasurConfigComponent } from "./settings/criticals/critical/critical.component";
import { SensorService } from "./services/sensor/sensor.service";
import { AreasComponent } from "./settings/areas/areas.component";
import { AreaComponent } from "./settings/areas/area/area.component";
import { ConnectionsComponent } from "./settings/connections/connections.component";
import { ConnectionComponent } from "./settings/connections/connection/connection.component";
import { HomeComponent } from "./home/home.component";
import { DeviceHomeComponent } from "./device-home/device-home.component";
import { SensorLogService } from "./services/sensor-log/sensor-log.service";
import { RmsChartComponent } from "./rms-chart/rms-chart.component";
import { ClientComponent } from "./client/client.component";
//import { MultiAlertsComponent } from './multi-alerts';

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SettingsComponent } from "./settings/settings.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TabsModule } from "ngx-bootstrap/tabs";
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { SortableHeader } from "./shared/table/sortable.directive";
import { ParentNameDirective } from "./shared/parent-name/parent-name.directive";
import { DeviceLastComponent } from "./device-last/device-last.component";
import { NavRightComponent } from "./nav-right/nav-right.component";
import { NotificationIconComponent } from "./notification-icon/notification-icon.component";
import { LogoComponent } from "./svg/logo/logo.component";
import { EkgComponent } from "./svg/ekg/ekg.component";
import { CommonModule, DatePipe } from "@angular/common";
import { ClientHeaderComponent } from "./client/client-header/client-header.component";
import { ConditionComponent } from "./controls/condition/condition.component";
import { DefaultComponent } from "./default/default.component";
import { DateSelectorComponent } from "./default/date-selector/date-selector.component";
import { AppRoutingModule } from "./app.routing";
import { AppService } from "./services/app.service";
import { ProfileModule } from "./profile/profile.module";



import {ClipboardModule} from '@angular/cdk/clipboard';
import { SystemComponent } from './settings/system/system.component';
import { ChangeSensorNoComponent } from './settings/change-sensor-no/change-sensor-no.component';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import { BreadcrumbComponent } from './settings/breadcrumb/breadcrumb.component';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    ObjectCardComponent,
    ObjectChartComponent,
    ConfigFormComponent,
    TemplateDrivenFormComponent,
    ConfigsComponent,
    ConfigComponent,
    DevicesComponent,
    DeviceComponent,
    SensorComponent,
    SensorsComponent,
    DeviceMeasurConfigComponent,
    DeviceMeasurConfigsComponent,

    PointsComponent,
    PointComponent,
    DeviceKindsComponent,
    DeviceKindComponent,
    AreasComponent,
    AreaComponent,
    ConnectionsComponent,
    ConnectionComponent,
    DeviceHomeComponent,
    RmsChartComponent,
    ClientComponent,
    SettingsComponent,
    SortableHeader,

    ParentNameDirective,
    DeviceLastComponent,
    NotificationIconComponent,
    NavRightComponent,
    LogoComponent,
    EkgComponent,
    ClientHeaderComponent,
    ConditionComponent,
    DefaultComponent,
    DateSelectorComponent,
    SystemComponent,
    ChangeSensorNoComponent,
    BreadcrumbComponent
  ],
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    HttpClientModule,
    FormsModule, ReactiveFormsModule ,
    ApiAuthorizationModule,
    ChartModule,
    DatePickerModule,
    TabModule,
    AlertModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    //RouterModule.forRoot(),
    NgbModule,
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    CollapseModule.forRoot(),
    ProfileModule,
    ClipboardModule,

    MatAutocompleteModule,  MatFormFieldModule, MatInputModule, MatProgressSpinnerModule, MatTooltipModule,
    MatSlideToggleModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    AppService,
    DateTimeService,
    LineSeriesService,
    RangeAreaSeriesService,
    StackingStepAreaSeriesService,
    StepAreaSeriesService,
    CategoryService,
    LegendService,
    ZoomService,
    SelectionService,
    DataLabelService,
    TooltipService,
    DateTimeCategoryService,
    StripLineService,
    CrosshairService,
    ChartAnnotationService,
    ConfigService,
    PointService,
    DeviceService,
    SensorService,
    SensorLogService,
    DatePipe,
    { provide: ErrorHandler, useClass: AppErrorHandler }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
