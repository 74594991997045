import { Component, OnInit, Inject } from '@angular/core';
import { ConfigService } from "../../services/config/config.service";
import { Config } from '../../shared/config.model';

@Component({
  selector: 'config-form',
  templateUrl: './config-form.component.html',
  styleUrls: ['./config-form.component.css']
})
export class ConfigFormComponent implements OnInit {
  configs: any[];
  

  constructor(private service: ConfigService) {
  }

  ngOnInit() {
    this.service.getAll()
      .subscribe(
        result => {
          console.log(result);
          this.configs = result;
        });
  }

  getConfig(id: number) {
    this.service.get<Config>(id)
      .subscribe(
        result => {
          console.log(result);
          //this.config = result.json();
        });

  }

  addConfig(config: any) {
    this.service.add<Config>(config)
      .subscribe(
        result => {
          console.log(result);
          //this.config = result.json();
        });
  }

  deleteConfig(id: number) {
    this.service.delete<Config>(id)
      .subscribe(
        result => {
          console.log(result);
          //this.config = result.json();
        });
  }

  //updateConfig(config: any) {
  //  this.service.update<Config>(config)
  //    .subscribe(
  //      result => {
  //        console.log(result);
  //        //this.config = result.json();
  //      });
  //}
}
