import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-object-chart',
  templateUrl: './object-chart.component.html',
  styleUrls: ['./object-chart.component.css']
})
export class ObjectChartComponent implements OnInit {
  public data: Fft[];
  public xAxis: Object;
  public yAxis: Object;
  public chartTitle: string;
  public legend: Object;
  public markerSettings: Object;
  public tooltipSettings: Object;



  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    http.get<Fft[]>(baseUrl + 'api/rms/2/23253').subscribe(result => {
      this.data = result;
    }, error => console.error(error));
  }


  ngOnInit() {
    this.chartTitle = 'FFT';
    this.data = [
      { frq: 'Jan', amp: 35 },
      { frq: 'Feb', amp: 28 },
      { frq: 'Mar', amp: 34 },
      { frq: 'Apr', amp: 32 },
      { frq: 'May', amp: 40 },
      { frq: 'Jun', amp: 32 },
      { frq: 'Jul', amp: 35 },
      { frq: 'Aug', amp: 55 },
      { frq: 'Sep', amp: 38 },
      { frq: 'Oct', amp: 30 },
      { frq: 'Nov', amp: 25 },
      { frq: 'Dec', amp: 32 }
    ];
    this.tooltipSettings = {
      enable: true
    };
    //this.markerSettings = {
    //  visible: true,
    //  dataLabel: {
    //    visible: true
    //  }
    //};
    this.legend = {
      visible: true
    };
    this.xAxis = {
      title: 'Frq',
     // valueType: 'Category'
    };
    this.yAxis = {
      title: 'Amp'
    };
  }

}
interface Fft {
  frq: string;
  amp: number;
}

