<div class="card">
    <div class="card-header">
        Area
        <!-- <a  [routerLink]="['devices']" class="btn btn-primary float-right" >Go to Device</a> -->
        <a class="float-right" [routerLink]="['/settings', { outlets: { primary: ['devices'], details: ['device'] } }]">
          Go to Device
      </a>
    </div>
    <div class="card-body">
        <form #f="ngForm" *ngIf="item"
              (ngSubmit)="submit(f)">

            <input type="hidden"
                   [ngModel]="item.Id"
                   #Id="ngModel"
                   name="Id"
                   id="Id"
                   name="Id" />
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label for="PodmiotId">Client</label>
                        <!-- <input class="form-control"
                               [(ngModel)]="item.PodmiotId"
                               #PodmiotId="ngModel"
                               name="PodmiotId"
                               id="PodmiotId"
                               placeholder=""
                               aria-describedby="PodmiotIdHelp"> -->
                        <select class="form-control"
                                [(ngModel)]="item.PodmiotId"
                                #PodmiotId="ngModel"
                                name="PodmiotId"
                                id="PodmiotId">
                            <option [ngValue]="0"> - Select - </option>
                            <option *ngFor="let current of clients"
                                    [ngValue]="current.Id">
                                {{ current.Id }} - {{ current.Name }}
                            </option>
                        </select>

                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="SimplifyLinks">SimplifyLinks</label>
                        <input class="form-control"
                               [(ngModel)]="item.SimplifyLinks"
                               #SimplifyLinks="ngModel"
                               name="SimplifyLinks"
                               id="SimplifyLinks"
                               placeholder=""
                               aria-describedby="SimplifyLinksHelp">
                        <small id="SimplifyLinksHelp" class="form-text text-muted">Link wygenerowany na stronie bitly.com.</small>

                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="Name">Name</label>
                        <input class="form-control"
                               [(ngModel)]="item.Name"
                               #Name="ngModel"
                               name="Name"
                               id="Name"
                               placeholder=""
                               aria-describedby="NameHelp">
                        <!-- <small id="NameHelp" class="form-text text-muted">Numer konfiguracji - przekazywany
                                do
                                czujnika</small> -->

                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <app-connection *ngFor="let conection of item.Connections" [item]="conection"></app-connection>
                </div>
              </div>
            <!-- <div class="row"            >
                <div class="col">
                    <div class="form-group">
                        <label for="SsId">SSID</label>
                        <input class="form-control"
                               [(ngModel)]="conection.SsId"
                               #SsId="ngModel"
                               name="SsId"
                               id="SsId">
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="Code">Code</label>
                        <input class="form-control"
                               [(ngModel)]="conection.Code"
                               #Code="ngModel"
                               name="Code"
                               id="Code">
                    </div>
                </div>
            </div> -->


            <div *ngIf="isTester | async"
                 class="form-group">
                <label for="detail">Json item (preview)</label>
                <textarea class="form-control"
                          disabled
                          rows="10">
                        {{ f.value | json }}
                    </textarea>
            </div>
            <button type="submit"
                    class="btn btn-success float-right"
                    [disabled]="!f.valid"
                    *ngIf="f.touched"><i class="far fa-save"></i> Zapisz</button>
        </form>
    </div>
</div>
