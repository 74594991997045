<div class="row">
  <div class="col-12 col-sm">
    <app-condition [area]="area" [isActive]="isActiveClient" (click)="clientClick()"></app-condition>
  </div>
  <div class="col-6  col-sm-3 col-md-2">
    <a [routerLink]="['warning']"
       class="info-box bg-warning"
       routerLinkActive="elevation-5" (click)="warningClick()">
      <span *ngIf="(warningCounting | async)>0" class="small" style="font-size: 10px; display: block; position: absolute; bottom: 5px; word-wrap: normal; left: 5px; overflow-wrap: normal;">
        <i class="fas fa-spinner fa-pulse"></i>
      </span>
      <span class="small" style="font-size: 10px; display: block; position: absolute; bottom: 5px; word-wrap: normal; right: 5px; overflow-wrap: normal;">{{( app.warningDt | async) | date : '\'since: \'yyyy-MM-dd HH:mm'}}</span>
      <span class="info-box-icon">
        <i class="far fa-bell"></i>
      </span>
      <div class="info-box-content text-right">
        <span class="fa-2x">
          {{ isInfinity(app.warning | async) }}
        </span>
      </div>
    </a>
  </div>
  <div class="col-6  col-sm-3  col-md-2">
    <a class="info-box  bg-danger" [routerLink]="['alert']"
       routerLinkActive="elevation-5" (click)="alertClick()">
      <span *ngIf="(dangerCounting | async)>0" class="small" style="font-size: 10px; display: block; position: absolute; bottom: 5px; word-wrap: normal; left: 5px; overflow-wrap: normal;">
        <i class="fas fa-spinner fa-pulse"></i>
      </span>
      <span class="small" style="font-size: 10px; display: block; position: absolute; bottom: 5px; word-wrap: normal; right: 5px; overflow-wrap: normal;">{{ (app.dangerDt | async) | date : '\'since: \'yyyy-MM-dd HH:mm'}}</span>
      <span class="info-box-icon">
        <i class="far fa-bell"></i>
      </span>
      <div class="info-box-content text-right">
        <span class="fa-2x">
          {{ isInfinity(app.danger | async) }}
        </span>
      </div>

    </a>
  </div>

  <div *ngIf="app.isZmp | async" class="col col-sm-4">
    <a class="info-box" [routerLink]="['supervision']"
       routerLinkActive="elevation-5" (click)="clientClick()">
      <span class="info-box-icon bg-purple elevation-1">
        <i class="far fa-chart-bar"></i>
      </span>
      <div class="info-box-content">
        <span class="info-box-text"> Supervision </span>
        <!--<span class="info-box-number">
          {{ isInfinity(danger | async) }}
        </span>-->
      </div>
    </a>
  </div>
  <!--<div class="col">
    <a [routerLink]="['msg']"
       class="info-box"
       routerLinkActive="active">

      <span class="info-box-icon bg-primary elevation-1">
        <i class="fas fa-comment-dots"></i>
      </span>
      <div class="info-box-content">
        <span class="info-box-text"> Messages </span>
        <span class="info-box-number">
          {{ isInfinity(msg | async) }}
        </span>
      </div>

    </a>
  </div>-->
</div>
