import { GrangeEnum } from "./enum/grange-enum";
import { IdBase } from "./ID.base";

export class KindConfig implements IdBase {
  Id: number;
  Name: string;
  IdGroup: number;
  To: Date;
  From: Date;
  ConfigNumber:number;
  Grange: GrangeEnum;
  // TresUserW: number;
  // TresUserA: number;
  HighPassFilter: number;
  LowPassFilter: number;


  GetEditUrl: string;
  constructor() {
    this.Id = 0;
    this.IdGroup = 0;
    this.From = new Date();
    this.To = null;
    this.GetEditUrl = 'device-kind/edit';
  }


}
