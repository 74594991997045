import { ModuleWithProviders, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthorizeGuard } from "src/api-authorization/authorize.guard";
import { ProfileComponent } from "./profile.component";
import { ProfileModule } from "./profile.module";

export const routes: Routes =[
  {
    path: "",
    component: ProfileComponent,
    canActivate: [AuthorizeGuard],
    // children: [
    //   { path: "", redirectTo: "client", pathMatch: "full" },
    // ]
  },
  {
    path: "area/:id/:wId",
    component: ProfileComponent,
    canActivate: [AuthorizeGuard]
  }
];


//@NgModule({
//  imports: [RouterModule.forChild(routes)],
//  exports: [RouterModule]
//})
//export class ProfileRoutingModule { }
export const routing: ModuleWithProviders<ProfileModule> = RouterModule.forChild(routes);
