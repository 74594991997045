<a [routerLink]="['client']"
   class="info-box border border-white"
   routerLinkActive="elevation-5">
  <span class="info-box-icon bg-light elevation-1">
    <app-logo [state]="(controlState | async)" style="width: 60px; height: 60px;"></app-logo>
    <!-- <app-ekg style="width: 60px;  align-items: center; justify-content: center; text-align: center; " fill="white"></app-ekg> -->
  </span>
  <div class="info-box-content">
    <span class="small text-dark" style="font-size: 10px; display: block; position: absolute; bottom: 5px; word-wrap: normal; left: 88px; overflow-wrap: normal;">Last condition</span>
    <span class="info-box-text" style="overflow: visible;">
      <span class="fa-2x">
        <span class="mr-1 border border-success rounded">
          <span class="p-2 text-success">{{ (controlState | async).Success }}</span>
        </span>
        <span class="mr-1 border border-warning rounded">
          <span class="p-2 text-warning">{{ (controlState | async).Warning }}</span>
        </span>
        <span class="mr-1 border border-danger rounded">
          <span class="p-2 text-danger">{{ (controlState | async).Danger }}</span>
        </span>
      </span>
      <!--<span class="fa-2x text-dark">{{ area.Name }}</span>-->
    </span>
  </div>
</a>
