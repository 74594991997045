<ng-container *ngIf="(warning | async) || (danger | async) || (msg | async)">

  <span *ngIf="!card">
    <span *ngIf="icon">
      <span *ngIf="warning | async" class="pr-1">
        <i class="far fa-bell text-warning"></i>
        <sup class="badge badge-warning" [class.badge-pill]="pill">{{ isInfinity(warning  | async) }} </sup>
      </span>
      <span *ngIf="danger | async" class="pr-1">
        <i class="far fa-bell text-danger"></i>
        <sup class="badge badge-danger" [class.badge-pill]="pill">{{ isInfinity(danger  | async) }} </sup>
      </span>
      <span *ngIf="msg | async" class="pr-1">
        <i class="far fa-bell text-primary"></i>
        <sup class="badge badge-primary" [class.badge-pill]="pill">{{ isInfinity(msg  | async) }} </sup>
      </span>
    </span>
    <span *ngIf="!icon">
      <span *ngIf="warning | async" class="pr-1">
        <span class="badge badge-warning" [class.badge-pill]="pill">{{ isInfinity(warning  | async) }} </span>
      </span>
      <span *ngIf="danger | async" class="pr-1">
        <span class="badge badge-danger" [class.badge-pill]="pill">{{ isInfinity(danger  | async) }} </span>
      </span>
      <span *ngIf="msg | async" class="pr-1">
        <span class="badge badge-primary" [class.badge-pill]="pill">{{ isInfinity(msg  | async) }} </span>
      </span>
    </span>
  </span>
</ng-container>
