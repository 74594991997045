import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { filter, map } from "rxjs/operators";
import { AuthorizeService } from "src/api-authorization/authorize.service";
import { AppService } from "../services/app.service";
import { ProfileService } from "../services/profile.service";
import { Area } from "../shared/area.model";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styles: [],
})
export class ProfileComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
     srv: ProfileService, public app: AppService,
    private authorizeService: AuthorizeService
  ) {
   //app.currentPage.next("preferences/area");

    this.app.isAuthenticated = this.authorizeService.isAuthenticated();

    this.route.paramMap.subscribe((parmMap) => {
      if (parmMap.keys.length == 2) {
        app.selectedPodmiotId.next(+parmMap.get("wId"));
        app.selectedAreaId.next(+parmMap.get("id"));
      }
    });

    app.userRoles = authorizeService.getUser().pipe(
      filter((u) => u !== null),
      map((u) => u.role)
    );
    app.userRoles.subscribe((roles) => {
      if (roles) {
        app.isAdminRot.next(roles.includes("AdminRot"));
        app.isZmp.next(roles.includes("ZMP"));
        app.isTester.next(roles.includes("Tester"));
      } else {
        app.isAdminRot.next(false);
        app.isZmp.next(false);
        app.isTester.next(false);
      }
    });
  }

  ngOnInit() {
    this.app.userName = this.authorizeService
      .getUser()
      .pipe(map((u) => u && u.name));
    this.app.phone = this.authorizeService
      .getUser()
      .pipe(map((u) => u && u.phone));
    this.app.email = this.authorizeService
      .getUser()
      .pipe(map((u) => u && u.email));
    //this.srv.userName = this.authorizeService.getUser().pipe(map((u) => u && u.name));
  }
}
