import { Component, OnInit, Input } from '@angular/core';
import { TypeDevice } from "../../shared/type-device.model";

@Component({
  selector: 'dev-heading',
  templateUrl: './heading.component.html',
  styles: [
  ]
})
export class HeadingComponent implements OnInit {

  @Input()
  type: TypeDevice;
  constructor() { }

  ngOnInit(): void {
  }
  ifDevDefault() {
    return this.type === TypeDevice.Default;
  }

  ifDevBasic() {
    return this.type === TypeDevice.BasicAlert;
  }

  ifDevCustom() {
    return this.type === TypeDevice.CustomAlert;
  }

}
