<div class="modal-header">
  <h4 class="modal-title">
    <strong> {{ title }}</strong>
  </h4>
  <span type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </span>
</div>
<div class="modal-body">
  <form #f="ngForm">
    <div class="row">
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label for="alertInputValue">Enter your customized value</label>
          <input class="form-control"
                 [(ngModel)]="current.Value"
                 #Value="ngModel"
                 name="Value"
                 id="Value"
                 placeholder="Enter your value"
                 step="any"
                 type="number">

          <!-- <label>or select from below ones</label>
        <div>
          <strong class="btn btn-outline-warning"
                  name="btnLimit"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title=""
                  data-original-title="Upper warning">45.0</strong>
          <strong class="btn btn-outline-danger"
                  name="btnLimit"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Upper alert">55.1</strong>
        </div> -->
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="form-group"
             id="notiffyDiv">
          <label>Crossing direction:</label><br>
          <div class="btn-group btn-group-toggle"
               data-toggle="buttons">
            <span name="btnInc"
                   class="btn"
                   [class.btn-default]="!current.Increase"
                   [class.btn-success]="current.Increase"  (click)="isIncrease(true)">

              <i class="fas fa-level-up-alt"></i>
              up
            </span>
            <span name="btnInc"
                   class="btn"
                   [class.btn-default]="current.Increase"
                   [class.btn-success]="!current.Increase"  (click)="isIncrease(false)">
              <i class="fas fa-level-down-alt"></i> down
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="form-group"
             id="repeatedDiv">
          <label>Notify:</label><br>
          <div class="btn-group btn-group-toggle"
               data-toggle="buttons">
            <span name="btnRep"
                  class="btn"
                  [class.btn-default]="current.IsRepeated"
                  [class.btn-success]="!current.IsRepeated" (click)="isRepeated(false)">
              <i class="fas fa-star-of-life"></i> - only once
            </span>
            <span name="btnRep"
                  class="btn "
                  [class.btn-default]="!current.IsRepeated"
                  [class.btn-success]="current.IsRepeated"  (click)="isRepeated(true)">
              <i class="fas fa-infinity"></i> - always
            </span>
          </div>

          <div class="float-right mt-3">
            <button class="btn btn-success "
                    [class.disabled]="!f.touched || !current.Value"
                    (click)="save()">
              Dodaj
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div>
    <hr class="border-2" />
    <label>Zadeklarowane </label>
    <ul class="list-group"
        *ngFor="let n of notify">
      <li class="list-group-item">
        <span class="btn btn-light disabled  ml-1">
          <span>{{ n.Value | number: '1.2-2'}}</span>
        </span>
        <span class="btn btn-light ml-1">
          <i class="fas fa-level-up-alt ml-1"
             *ngIf="(n.Increase)"></i>
          <i class="fas fa-level-down-alt ml-1"
             *ngIf="!(n.Increase)"></i>
        </span>
        <span class="btn btn-light ml-1">
          <span>
            <i *ngIf="(n.IsRepeated)"
               class="fas fa-infinity ml-1"></i>
            <i *ngIf="!(n.IsRepeated)"
               class="fas fa-asterisk"></i>
          </span>
        </span>
        <span class="btn btn-danger float-right text-white" (click)="remove(n)">
          <i class="far fa-trash-alt"></i>
        </span>
      </li>
    </ul>
  </div>
</div>
<!-- <div class="modal-footer">
  <span type="button"
        class="btn btn-outline-dark"
        (click)="activeModal.close('Close click')">Close</span>
</div> -->
