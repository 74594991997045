import { Component, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { AppService } from '../services/app.service';
import { Area } from '../shared/area.model';
import { Podmiot } from "../shared/podmiot.model";
import { Router } from "@angular/router";

@Component({
  selector: '.app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  // @Input()
  // podmiots: Podmiot[];

  // public userRoles: Observable<string[]>;
  // public isAdminRot: BehaviorSubject<boolean | null> = new BehaviorSubject(null);
  // public isZmp: BehaviorSubject<boolean | null> = new BehaviorSubject(null);

  constructor(authorizeService: AuthorizeService, public app: AppService, private router: Router) {

  }

  navClick(id, wId) {
    if (id && wId)
      this.router.navigate([this.app.currentPage.value, id, wId]);
  }
}
