import { MeasuringPoint } from "./measuring-point.model";
import { AxisEnume } from "./enum/axis-enum";
import { ChartNews } from "./chart-news.model";

export class RmsData {
  Id: number;
  SensorNumer: string;
  Axis: AxisEnume;
  Unit: number;
  SendDate: Date;
  TimeStamp: Date;
  Value: number;
  Temp: number;
  ErrorCount: number;
  FftFrequency: number;
  IdPoint: number;
  FftLines: number;
  ListFft: number[];


  TwfDelta: number;
  TwfCount: number;
  ListTwf: number[];


  //MeasuringPoint: MeasuringPoint;

  constructor() {
    this.Id = 0;
    this.SensorNumer = '';

    //this.ListFft = [];
    //this.FftFrequency = -1;
    //this.FftLines = -1;

    //this.TwfCount = -1;
    //this.TwfDelta = -1;
    //this.ListTwf = [];
  }

  get RmsStyle() {
    if (this.Value)
      return "btn btn-outline-success btn-block";
    else
      return "btn btn-outline-secondary btn-block";
  }

  //[JsonIgnore]
  //public string Fft :string;

  Alert: ChartNews[];
  Warning: ChartNews[];
}
