<div class="row mb-2">
  <div class="col-3 col-md-2">
  </div>
  <div class="col-9 col-md-10">
    <div class="row">
      <div class="pl-1  pr-0 pr-sm-2"
           [class.col-3]="ifDevDefault()"
           [class.col-4]="!ifDevDefault()">
        <div class="btn btn-dark btn-block"
             data-toggle="tooltip"
             data-placement="top"
             title="[mm/s]">
          <div>H <!--<small class="small d-block d-sm-inline">[mm/s]</small>--></div>
        </div>
      </div>
      <div class="pl-1  pr-0 pr-sm-2"
           [class.col-3]="ifDevDefault()"
           [class.col-4]="!ifDevDefault()">
        <div class="btn btn-dark btn-block"
             data-toggle="tooltip"
             data-placement="top"
             title="[mm/s]">
          <div>V <!--<small class="small d-block d-sm-inline">[mm/s]</small>--></div>
        </div>
      </div>
      <div class="pl-1  pr-0 pr-sm-2"
           [class.col-3]="ifDevDefault()"
           [class.col-4]="!ifDevDefault()">
        <div class="btn btn-dark btn-block"
             data-toggle="tooltip"
             data-placement="top"
             title="[mm/s]">
          <div>A <!--<small class="small d-block d-sm-inline">[mm/s]</small>--></div>
        </div>
      </div>
      <div *ngIf="ifDevDefault()" class="col-3">
        <div class="btn btn-dark btn-block"
             data-toggle="tooltip"
             data-placement="top"
             title="[&#8451;]">
          <div>T <!--<small class="small d-block d-sm-inline">[&#8451;]</small>--></div>
        </div>
      </div>
    </div>
  </div>
</div>
