
export class ControlState {
  Id: number;
  Unit: string;
  Success: number;
  Warning: number;
  Danger: number;
  IdPoint: number;
  TimeStamp: Date;

  constructor() {
    this.Success = 0;
    this.Warning = 0;
    this.Danger = 0;
    this.TimeStamp = new Date();
  }
}
