import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectionStrategy,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { RmsData } from "../shared/rms-data.model";
import { AxisEnume } from "../shared/enum/axis-enum";
import { Device } from "../shared/device.model";
import { Area } from "../shared/area.model";
import { Router } from "@angular/router";
import { StateEnume } from "../shared/enum/state-enum";
import {
  ChartComponent,
  AxisModel,
  LegendSettingsModel,
  ZoomSettingsModel,
  TooltipSettingsModel,
} from "@syncfusion/ej2-angular-charts";
import { BehaviorSubject } from "rxjs";
import { Limit } from "../shared/limit.model";
import { ControlState } from "../shared/interface/control-state";
import { AppService } from "../services/app.service";
import { TabDirective } from "ngx-bootstrap/tabs";
import { AlertService } from "../shared/_alert";
import { ThrowStmt } from "@angular/compiler";



@Component({
  selector: "app-device-home",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./device-home.component.html",
  styleUrls: ["./device-home.component.css"],
})
export class DeviceHomeComponent implements OnInit {
  //@Input() area: Area;
  @Input() device: Device;
  //@Input() state: StateEnume;

  $area: Area;
  @Input()
  set area(val: Area) {
    if (val && this.$area !== val) {
      this.$area = val;
    }
  }
  get area(): Area {
    return this.$area;
  }
  //$page: string;
  //$state: StateEnume;
  //@Input()
  //set state(val: StateEnume) {
  //  if (val && this.$state !== val) {
  //    this.$state = val;
  //   // let page = "";
  //    if (this.$state === StateEnume.Alert) {
  //      this.$page = "alert";
  //    } else if (this.$state === StateEnume.Warning) {
  //      this.$page = "warning";
  //    }
  //  }
  //}
  //get state(): StateEnume {
  //  return this.$state;
  //}

  @Input() chartShow = true;

  @Output() change = new EventEmitter();

  private crumbs = true;

  public selectedData: BehaviorSubject<RmsData | null> = new BehaviorSubject(
    null
  ); // RmsData;
  public selectedLimits: BehaviorSubject<Limit[] | null> = new BehaviorSubject(
    null
  ); // Limit[];

  public rmsItems: RmsData[];
  public data: Object[];
  public twf: object[];

  public markerSettings: Object;

  skok: number;

  public chartTitle: string;
  public xAxis: AxisModel;
  public yAxis: AxisModel;

  public chartTitleTwf: string;
  public xAxisTwf: AxisModel;
  public yAxisTwf: AxisModel;

  public legend: LegendSettingsModel;
  public zoom: ZoomSettingsModel;
  public tooltipSettings: TooltipSettingsModel;
  public crosshair: any;

  confRaw: Object[];
  //currentUser: any;
  sLog: RmsData[];

  @ViewChild("chart")
  chart: ChartComponent;

  success: BehaviorSubject<boolean | null> = new BehaviorSubject(null);
  warning: BehaviorSubject<boolean | null> = new BehaviorSubject(null);
  danger: BehaviorSubject<boolean | null> = new BehaviorSubject(null);

  velocityState: BehaviorSubject<ControlState | null> = new BehaviorSubject(
    new ControlState());
  accelerationState: BehaviorSubject<ControlState | null> = new BehaviorSubject(
    new ControlState());
  deviceState: BehaviorSubject<ControlState | null> = new BehaviorSubject(new ControlState());

  //public isAdminRot: BehaviorSubject<boolean | null> = new BehaviorSubject(
  //  null
  //);
  //public isZmp: BehaviorSubject<boolean | null> = new BehaviorSubject(null);
  //public isTester: BehaviorSubject<boolean | null> = new BehaviorSubject(null);

  constructor(private http: HttpClient, private router: Router, public app: AppService, private alert: AlertService) {
    //this.authorize.getUser().subscribe((usr) => {
    //  this.currentUser = usr;
    //});

    //this.authorize
    //  .getUser()
    //  .pipe(map((u) => u.role))
    //  .subscribe((roles) => {
    //    if (roles) {
    //      this.isAdminRot.next(roles.includes("AdminRot"));
    //      this.isZmp.next(roles.includes("ZMP"));
    //      this.isTester.next(roles.includes("Tester"));
    //    } else {
    //      this.isAdminRot.next(false);
    //      this.isZmp.next(false);
    //      this.isTester.next(false);
    //    }
    //  });
    this.selectedData.subscribe((rms) => {
      if (rms && rms.Id > 0 && app.state.value === StateEnume.Test) {
        this.http
          .get<any[]>(`rms/sensorlogs/${rms.IdPoint}/${rms.SensorNumer}`)
          .subscribe((sLog) => {
            this.sLog = sLog;
            //console.log(sLog);
          });
        this.http
          .get<any[]>(`rms/log/sConf/${rms.SensorNumer}`)
          .subscribe((confRaw) => {
            this.confRaw = confRaw;
            //console.log(confRaw);
          });

        this.skok = rms.FftFrequency / rms.FftLines;
        this.data = rms.ListFft.map((value, index) => {
          return {
            amp: value,
            frq: this.skok * index,
          };
        });

        this.twf = rms.ListTwf.map((value, index) => {
          return {
            amp: value,
            frq: (index * rms.TwfDelta) / 1000,
          };
        });
      }

    });
  }

  copyFft() {
   // this.alert.info("FFT has been copied to the clipboard");
    return "FFT: " + this.selectedData.value.ListFft.toString();
  }

  copyTwf() {
  //  this.alert.info("TWF has been copied to the clipboard");
    return "TWF: " + this.selectedData.value.ListTwf.toString();
  }
  // copy(data) {
  //   switch (data) {
  //     case "twf":
  //       this.clipboard.copy(this.selectedData.value.ListTwf.toString());
  //       break;
  //     case "fft":
  //       this.clipboard.copy(this.selectedData.value.ListFft.toString());
  //       break;
  //   }
  // }


  onSelect(data: string): void {
    this.app.selectedTab.next(data);
  }


  onChartSelect(event: RmsData) {
    this.selectedData.next(null);
    this.selectedData.next(event);
  }

  onChangeAcceleration(event: ControlState) {
    if (event.Unit === "65") {
      this.change.emit(event);
      //this.accelerationState.next(event);

      this.accelerationState.next(
        this.addState(this.accelerationState.value, event)
      );
      this.deviceState.next(
        this.addState(this.deviceState.value, this.accelerationState.value)
      );
      // if (event.Danger) {
      //   this.danger.next(true);
      //   //this.warning = false;
      //   //this.success = false;
      // } else if (event.Warning) {
      //   if (this.danger.value === null|| this.danger.value === false) {
      //    this.warning .next(true);
      //   }
      // } else if (event.Success) {
      //   if ((this.danger.value === null || this.danger.value === false) && (this.warning.value === null || this.warning.value === false)) {
      //     this.success.next(true);
      //   }
      //}
    }
  }
  onChangeVelocity(event: ControlState) {
    if (event.Unit === "86") {
      this.change.emit(event);

      this.velocityState.next(this.addState(this.velocityState.value, event));
      this.deviceState.next(
        this.addState(this.deviceState.value, this.velocityState.value)
      );

      // if (event.Danger) {
      //   this.danger.next(true);
      // } else if (event.Warning) {
      //   if (this.danger.value === null|| this.danger.value === false) {
      //    this.warning .next(true);
      //   }
      // } else if (event.Success) {
      //   if ((this.danger.value === null || this.danger.value === false) && (this.warning.value === null || this.warning.value === false)) {
      //     this.success.next(true);
      //   }
      // }
    }
  }
  addState(oldState: ControlState, newState: ControlState): ControlState {
    let state = new ControlState();
    if (oldState && newState) {
      state.Success = oldState.Success + newState.Success;
      state.Warning = oldState.Warning + newState.Warning;
      state.Danger = oldState.Danger + newState.Danger;
      return state;
    }
    if (oldState) {
      return oldState;
    }
    if (newState) {
      return newState;
    }
    return null;
  }

  selectedChange(selected) {
    if (selected.rms.Axis) {
      this.selectedLimits.next(
        selected.limit.filter((p) => p.Axis === selected.rms.Axis)
      );
      this.selectedData.next(selected.rms);

      this.chartShow = true;
    } else {
      this.chartShow = false;
      this.selectedData.next(null);
    }

  }

  closeChart() {
    this.chartShow = false;
  }

  get Unit() {
    if (this.selectedData.value) {
      return String.fromCharCode(this.selectedData.value.Unit);
    }
    return "";
  }

  get Axis() {
    if (this.selectedData.value) return AxisEnume[this.selectedData.value.Axis];
    return null;
  }
  get ConfigData() {
    if (this.confRaw) return this.confRaw[0];
    return "nie wybrano czujnika";
  }
  get ConfigDataRaw() {
    if (this.confRaw) return this.confRaw[1];
    return "brak danych";
  }
  get isTested() {
    if (!this.app.state.value) return true;
    if (this.app.state.value === StateEnume.Test) return true;
    return false;
  }
  get isRmsChart() {
    return this.selectedData.value && this.app.state.value === StateEnume.Test;
  }
  goToArea() {
    this.router.navigate([`/`]);
  }

  ngOnInit() {
    // this.route.paramMap.subscribe(prams => {
    //   let id: number = +prams.get("id"); if (id && id != 0) {
    //   } else {
    //     this.crumbs = false;
    //   }

    // });

    //this.markerSettings = {
    //  visible: true,
    //  dataLabel: {
    //    visible: true
    //  }
    //};

    this.tooltipSettings = {
      enable: true,
      shared: true,
      format: "${series.name}: ${point.y}",
    };
    this.crosshair = { enable: true, lineType: "Vertical" };
    this.zoom = {
      enableMouseWheelZooming: false,
      enablePinchZooming: true,
      enableSelectionZooming: true,
    };
    this.legend = {
      visible: true,
    };

    this.chartTitle = "FFT";
    this.xAxis = {
      title: "Frq",
    };
    this.yAxis = {
      title: "Amp",
    };
    this.chartTitleTwf = "TWF";
    this.xAxisTwf = {
      title: "Time [ms]",
    };
    this.yAxisTwf = {
      title: "Amp",
    };
  }

  onSendRequest(id) {
    if (confirm("Support request!\nAre you sure?")) {
      this.http.get(`api/gong/${id}`)
        .subscribe((respons) => {
          console.log("post - " + respons);
        });
    }
  }
}
