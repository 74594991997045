import { Component, OnInit, Input } from '@angular/core';
import { Device } from 'src/app/shared/device.model';
import { StateEnume } from 'src/app/shared/enum/state-enum';
import { DeviceService } from 'src/app/services/device/device.service';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { KindConfig } from 'src/app/shared/kind-config.model';
import { MeasuringConfig } from 'src/app/shared/measuring-config.model';
import { SelectService } from 'src/app/services/select.service';
import { Area } from 'src/app/shared/area.model';
import { BehaviorSubject } from 'rxjs';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { map } from 'rxjs/operators';
import { UnifiedLimit } from 'src/app/shared/unified-limit.model';
import { SettingsService } from 'src/app/services/settings.service';
import { AlertService } from 'src/app/shared/_alert';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styles: []
})
export class DeviceComponent implements OnInit {
  @Input() item: Device;
  //@Input() state: StateEnume;
  kindConfigs: KindConfig[];
  unifiedLimits: UnifiedLimit[];
  measurConfigs: MeasuringConfig[];
  areas: Area[];
  public isTester: BehaviorSubject<boolean | null> = new BehaviorSubject(null);

  constructor(protected dd: SelectService, private service: DeviceService,
    private route: ActivatedRoute,
    auth: AuthorizeService,  private alert: AlertService, public settings: SettingsService ) {
      auth.getUser().pipe(map(u => u.role)).subscribe(roles => {
        if (roles) {
          // this.isAdminRot.next(roles.includes("AdminRot"));
          // this.isZmp.next(roles.includes("ZMP"));
          this.isTester.next(roles.includes("Tester"));
        }else{
          // this.isAdminRot.next(false);
          // this.isZmp.next(false);
          this.isTester.next(false);
        }
      }); }

  ngOnInit() {
    this.settings.selectedDevice.subscribe(device => {
      if (device)
        this.item = device;
    });
    this.dd.active<KindConfig>('kind', true)
      .subscribe(
        result => {
          this.kindConfigs = result;
        });
        this.dd.active<UnifiedLimit>('unified',true)
        .subscribe(result =>{
          this.unifiedLimits  = result;
        });
    this.dd.active<MeasuringConfig>('mconfig', true)
      .subscribe(
        result => {
          this.measurConfigs = result;
        });
    this.dd.active<Area>('area', true)
      .subscribe(
        result => {
          this.areas = result;
        });
    // if (!this.state) {
    //   this.state = StateEnume.Details;
    //   let id: number = +this.route.snapshot.paramMap.get('id');
    //   if (id && id != 0) {
    //     this.service.get<Device>(id)
    //       .subscribe(
    //         result => {
    //           this.item = result;
    //           this.state = StateEnume.Edit;
    //         });
    //   }
    // }
  }
  get isDetails() {
    return this.settings.state == StateEnume.Details;
  }

  submit(f: NgForm) {
    if (this.settings.state === StateEnume.New) {
      this.service.add<Device>(this.item)
        .subscribe(
          result => {
            console.log(result);
            //this.config = result.json();
            this.settings.state = StateEnume.Details;
            this.item = result;
            this.settings.dataChange.next(true);
            this.alert.success("New record saved.");
          });
    }
    if (this.settings.state === StateEnume.Edit || this.settings.state === StateEnume.Details) {
      this.service.update<Device>(this.item, this.item.Id)
        .subscribe(
          result => {
            console.log(result);
            //this.config = result.json();
            this.settings.state = StateEnume.Details;
            this.item = result;
            this.settings.dataChange.next(true);
            this.alert.success("Edited record saved.");
          });
    }
  }

}
