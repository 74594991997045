<button class="btn btn-success" >
  Add
</button>
<br/>
<br/>
<div *ngFor="let config of configs"  class="input-group mb-3">
  <input type="text" class="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2" value="{{ config | json }}">
  <div class="input-group-append">
    <button class="btn btn-warning right" >Edit</button>
    <button class="btn btn-danger" type="button">Delete</button>
  </div>
</div>

