<!-- <header>
  <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-3">
    <div class="container">
      <a class="navbar-brand"
         [routerLink]="['/']"><i class="fa fa-home"></i><strong style="color: darkblue;"> Dr Smart</strong></a>
      <button class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target=".navbar-collapse"
              aria-label="Toggle navigation"
              [attr.aria-expanded]="isExpanded"
              (click)="toggle()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse"
           [ngClass]="{ show: isExpanded }">
        <app-login-menu></app-login-menu>
        <ul class="navbar-nav flex-grow">
          <li class="nav-item"
              [routerLinkActive]="['link-active']"
              [routerLinkActiveOptions]="{ exact: true }">
            <a class="nav-link text-dark"
               [routerLink]="['/']">Home</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']">
            <a class="nav-link text-dark" [routerLink]="['/object-card']">Object card</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header> -->

<!-- Left navbar links -->
<ul class="navbar-nav">
  <li class="nav-item border-right">
    <a class="nav-link"
       data-widget="pushmenu"
       href="#"
       role="button"><i class="fas fa-bars"></i> </a>
  </li>
  <li class="nav-item" *ngIf="(app.selectedArea | async) as area">
    <span class="nav-link" style="cursor: pointer;" (click)="navClick(area.Id, area.PodmiotId)">
      <i class="nav-icon fas fa-warehouse"></i>
      <span class="text-bold text-darkblue">
        {{ area.Name }}
      </span>
    </span>
  </li>

  <!-- <li class="nav-item d-none d-sm-inline-block">
        <a href="index3.html"
           class="nav-link">Home</a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a href="#"
           class="nav-link">Contact</a>
      </li> -->
</ul>

<!-- SEARCH FORM -->
<!-- <form class="form-inline ml-3">
      <div class="input-group input-group-sm">
        <input class="form-control form-control-navbar"
               type="search"
               placeholder="Search"
               aria-label="Search">
        <div class="input-group-append">
          <button class="btn btn-navbar"
                  type="submit">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
    </form> -->

<!-- Right navbar links -->
<ul class="navbar-nav ml-auto" app-nav-right></ul>
