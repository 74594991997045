import { IdBase } from "./ID.base";

export class Connection  implements IdBase {
    Id: number;
    Order: number;
    SsId: string;
    Code: string;

    IdArea: number;
    
    //Config: Config;
    get GetEditUrl(){
        return  `connection/edit/${this.Id}`;
    }
}
