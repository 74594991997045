import { Component, OnInit } from '@angular/core';
import { AuthorizeService } from '../authorize.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppService } from "../../app/services/app.service";
import { Router } from "@angular/router";

@Component({
  selector: '.app-login-menu',
  templateUrl: './login-menu.component.html',
  styleUrls: ['./login-menu.component.css']
})
export class LoginMenuComponent implements OnInit {
  public isAuthenticated: Observable<boolean>;
  public userName: Observable<string>;

  constructor(private authorizeService: AuthorizeService, public app: AppService, private router: Router) { }

  ngOnInit() {
    this.isAuthenticated = this.authorizeService.isAuthenticated();
    this.userName = this.authorizeService.getUser().pipe(map(u => u && u.name));
  }

  clickPreferences() {
    if (this.app.selectedArea.value) {
      this.router.navigate(["preferences/area", this.app.selectedAreaId.value, this.app.selectedPodmiotId.value]);
    } else {
      this.router.navigate(["preferences"]);
    }
  }
}
