<div class="row pt-5">
  <div class="col-sm-12 col-md-6 col-xl-3">
    <h4 class="page-header text-darkred"
        style="margin-bottom: 5px;">
      <i class="fa fa-user-o"></i><strong>&nbsp; User profile</strong>
    </h4>
    <div class="card card-primary card-outline">
      <div class="card-body box-profile">
        <!--<div class="text-center">
          <img class="profile-user-img img-fluid img-circle"
               src="assets/img/user.png"
               alt="User profile picture">
        </div>-->

        <h3 class="profile-username text-center"  >{{ app.userName | async }}</h3>

        <ul class="list-group list-group-unbordered mb-3">
          <li class="list-group-item">
            <b>Phone</b> <a class="float-right">{{ app.phone | async }}</a>
          </li>
          <li class="list-group-item">
            <b>Mail</b> <a class="float-right">{{ app.email | async }}</a>
          </li>
        </ul>

      </div>
    </div>
  </div>
  <div *ngIf="(app.selectedArea | async)"
       class="col-sm-12 col-md-6 col-xl-9">
    <app-report-emission [area]="(app.selectedArea | async)"></app-report-emission>

  </div>
</div>
<div class="row">
  <div *ngIf="(app.selectedArea | async)"
       class="col-12 col-md-6">
    <app-gong-alert> </app-gong-alert>
  </div>
  <div *ngIf="(app.selectedArea | async)"
       class="col-12 col-md-6 ">
    <app-custom-alert></app-custom-alert>
  </div>
</div>
