<div class="mb-3">
  <br/>
    <app-breadcrumb ></app-breadcrumb>
</div>
<div class="row">
  <div class="col col-md-12 col-lg-6">
    <router-outlet>
    </router-outlet>
  </div>
  <div class="col">
    <router-outlet name="details"></router-outlet>
  </div>
</div>

<!-- <br/>
<div class="row">
   <div class="col-4">
      <div *ngIf="area"
           class="input-group">
         <div class="form-control">{{ area.Name }}</div>
         <div class="input-group-append">
            <button class="btn btn-outline-secondary"
                    type="button"><i class="far fa-trash-alt text-red"></i></button>
         </div>
      </div>
   </div>
   <div class="col-4">
      <div *ngIf="device"
           class="input-group">
         <div class="form-control">{{ device.Name }}</div>
         <div class="input-group-append">
            <button class="btn btn-outline-secondary"
                    type="button"><i class="far fa-trash-alt text-red"></i></button>
         </div>
      </div>
   </div>
   <div class="col-4">
      <div *ngIf="point"
           class="input-group">
         <div class="form-control">{{ point.Name }}</div>
         <div class="input-group-append">
            <button class="btn btn-outline-secondary"
                    type="button"><i class="far fa-trash-alt text-red"></i></button>
         </div>
      </div>
   </div>
</div> -->
<!--
<ejs-tab id="element"
         overflowMode='Popup'>
   <e-tabitems>
      <e-tabitem >
         <ng-template #headerText>
            <div> Area </div>
         </ng-template>
         <ng-template #content>
            <app-areas (selectedEvent)="selectedArea($event)"></app-areas>
         </ng-template>
      </e-tabitem>

      <e-tabitem>
         <ng-template #headerText>
            <div> Devices </div>
         </ng-template>
         <ng-template #content>
            <app-devices (selectedEvent)="selectedDevice($event)"></app-devices>
         </ng-template>
      </e-tabitem>
      <e-tabitem>
         <ng-template #headerText>
            <div> Criticality </div>
         </ng-template>
         <ng-template #content>
            <app-criticals></app-criticals>
         </ng-template>
      </e-tabitem>
      <e-tabitem>
         <ng-template #headerText>
            <div> Kinds </div>
         </ng-template>
         <ng-template #content>ks
            <app-device-kinds></app-device-kinds>
         </ng-template>
      </e-tabitem>
      <e-tabitem>
         <ng-template #headerText>
            <div> Points </div>
         </ng-template>
         <ng-template #content>
            <app-points (selectedEvent)="selectedPoint($event)"></app-points>
         </ng-template>
      </e-tabitem>
      <e-tabitem>
         <ng-template #headerText>
            <div> Configs </div>
         </ng-template>
         <ng-template #content>
            <app-configs></app-configs>
         </ng-template>
      </e-tabitem>
      <e-tabitem>
         <ng-template #headerText>
            <div> Sensores </div>
         </ng-template>
         <ng-template #content>
            <app-sensors></app-sensors>
         </ng-template>
      </e-tabitem>
      <e-tabitem>
         <ng-template #headerText>
            <div> Client </div>
         </ng-template>
         <ng-template #content>
            W toku...
         </ng-template>
      </e-tabitem>
   </e-tabitems>
</ejs-tab> -->
