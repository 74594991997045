import { Connection } from "./connection.model";
import { IdBase } from "./ID.base";
import { Podmiot } from "./podmiot.model";
import { Device } from "./device.model";

export class Area implements IdBase {
  Id: number;
  Name: string;
  SimplifyLinks: string;
  PodmiotId: number;


  GetEditUrl: string;
  Devices:Device[]
  Connections: Connection[];

  constructor() {
    this.Id = 0;
    this.GetEditUrl = 'area/edit';
  }
}
